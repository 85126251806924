<template>
  <div class="message__parent-container flex flex-col" :class="listClassContainer">
    <div v-if="!isMe" :class="listClassUsername">{{ username }}</div>
    <div class="message__parent-content">{{ messageParent.content }}</div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useUser } from "@composables/user";

export default {
  props: {
    messageParent: {
      type: Object,
    },
    childUserRole: {
      type: String,
    },
  },

  setup(props) {
    const { userId } = useUser();


    // Manage CSS Classes
    const listClassContainer = computed(() => {
      const color = isMe.value
        ? "message__parent-container--color-me"
        : isAdmin.value && props.childUserRole !== 'superadmin'
        ? "message__parent-container--color-admin"
        : isUser.value && props.childUserRole === 'user'
        ? "message__parent-container--color-user-grey"
        : "message__parent-container--color-user";

      return color;
    });

    const listClassUsername = computed(() => {
      const color = isAdmin.value
        ? "message__parent-username--color-admin"
        : "message__parent-username--color-user";

      return color;
    });


    // Helpers
    const isMe = computed(() => {
      return props.messageParent.user.id === userId.value;
    });

    const isAdmin = computed(() => {
      return props.messageParent.user.role === "superadmin";
    });

    const isUser = computed(() => {
      return props.messageParent.user.role === "user";
    });


    // Concat username and role
    const username = computed(() => {
      const name = props.messageParent.user.name + " " + props.messageParent.user.first_name;
      const role = "Organisateur -";

      return isAdmin.value ? role + " " + name : name;
    });

    return {
      listClassContainer,
      listClassUsername,
      isMe,
      username,
    };
  },
};
</script>

<style scoped>
.message__parent-container {
  @apply rounded-lg text-sm p-2 my-2 relative;
}

.message__parent-container--color-me {
  @apply bg-green-light text-green-dark1;
}

.message__parent-container--color-admin {
  @apply bg-orange-light text-red-dark;
}

.message__parent-container--color-user-grey {
  @apply bg-grey-light text-green-dark1;
}

.message__parent-container--color-user {
  @apply bg-white text-green-dark1;
}

.message__parent-content {
  white-space: pre-line;
}

.message__parent-username--color-admin {
  @apply text-red-dark;
}

.message__parent-username--color-user {
  @apply text-green-primary;
}
</style>
