<template>
  <div class="w-full text-center">
    <a
      class="inline-block rounded-lg text-lg font-bold px-6 py-3 my-4 bg-green-primary text-white"
      target="_blank"
      :href="mediaURL"
      @click="mediaOpened"
    >
      {{ t('button.ouvrir_pdf') }}
    </a>

    <div v-if="!scenarioIsEvaluation && showButton"
         class="flex justify-center mx-auto items-center py-8 "
         :class="canSubmit ? 'text-orange-primary cursor-pointer' : 'opacity-50 text-green-light'">

      <div class="">{{ t('button.valider_video') }}</div>
      <div  class="flex-shrink-0 ml-4 border-2  rounded-full px-2 py-2"
        :class="canSubmit ? 'border-orange-primary' : 'border-green-light'">
        <div class="w-6">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {useScenario} from "@composables/scenario";
import {computed, ref, watchEffect} from "vue";
import {useI18n} from "vue-i18n";

export default {
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const {t} = useI18n()
    const {setCurrentNodeType, setCurrentNodeValidity, scenarioIsEvaluation} = useScenario()
    setCurrentNodeType('file_pdf')
    setCurrentNodeValidity(false)

    const hasOpened = ref(false)

    const canSubmit = computed( () => {
      return hasOpened.value
    })

    const showButton = ref(true)



    watchEffect(() => {
      if (canSubmit.value && scenarioIsEvaluation.value) {
        setCurrentNodeValidity(true)
      }
    })

    const mediaOpened = () => {
      hasOpened.value = true
      showButton.value = false
      setCurrentNodeValidity(true)
    }

    const mediaURL = computed( () => {
      return process.env.VUE_APP_ASSETS_URL + props.node.path
    })

    return {t, scenarioIsEvaluation, canSubmit, mediaOpened, showButton, mediaURL}
  }

}
</script>