import {computed, reactive} from "vue";
import {useModule} from '@composables/module'
import {client as ApolloClient} from "../config/clients";
import {useLogs} from "@composables/logs";

// Parcous module sequence sceario
const state = reactive({
    user: {
        id: null,
        email: null,
        avatar: null,
        last_seen_conversation: null,
    },
    currentPromotionId: null,
    avatar: null,
})

const useAvatarList = () => [
    {color: 'green-light2' , path: 'avatars/avatar_1.svg'},
    {color: 'orange-light' , path: 'avatars/avatar_2.svg'},
    {color: 'red-primary' , path: 'avatars/avatar_3.svg'},
    {color: 'green-light2' , path: 'avatars/avatar_4.svg'},
    {color: 'red-primary' , path: 'avatars/avatar_5.svg'},
    {color: 'green-light2' , path: 'avatars/avatar_6.svg'},
    {color: 'orange-dark' , path: 'avatars/avatar_7.svg'},
    {color: 'green-dark' , path: 'avatars/avatar_8.svg'},
    {color: 'red-dark ' , path: 'avatars/avatar_9.svg'},
    {color: 'orange-light' , path: 'avatars/avatar_10.svg'},
    {color: 'green-dark' , path: 'avatars/avatar_11.svg'},
    {color: 'green-light2' , path: 'avatars/avatar_12.svg'},
    {color: 'red-dark' , path: 'avatars/avatar_13.svg'},
    {color: 'orange-light' , path: 'avatars/avatar_14.svg'},
    {color: 'green-dark' , path: 'avatars/avatar_15.svg'},
]


const useUser = () => {

    /**
     *
     * @type {ComputedRef<*>}
     */
    const user = computed( () => {
        return state.user
    })
    /**
     *
     * @type {ComputedRef<*>}
     */
    const currentPromotionId = computed( () => {
        return state.currentPromotionId
    })

    /**
     *
     */
    const setUser = (user) => {
        state.user = {...user}
    }

    const avatar = computed(() =>{
        if (!user.value.avatar_id)
            return ''
        
        let list = useAvatarList()
        let avatar = list[user.value.avatar_id - 1]
        return avatar.path
    })

    const avatarId = computed( () => {
        return user.value.avatar_id
    })

    const setAvatarId = (avatarId) => {
        return user.value.avatar_id  = avatarId
    }

    const isFirstConnexion = computed( () => {
        return user.value.avatar_id ? false : true
    })

    const userId = computed(() => {
        return user.value.id
    });

    const lastSeenConversation = computed( () => {
        return user.value.last_seen_conversation
    })

    /**
     * get baisc info users and init modules availables
     * @param cognitoId
     * @returns {Promise<boolean|*>}
     */
    const initUser = async (cognitoId) => {

        const {initModules} = useModule()
        // get the user and the main modules associated
        let response = null
        try {
            response = await ApolloClient.query({
                query: require('@gql/user/get_user_info.gql'),
                variables: {cognitoId}
            })
        } catch {
            response = null
        }
        let user = (response && response.data && response.data.users[0]) ?? null
        // console.log("INITUSER")
        state.user = {...user}

        if(user) {
            if(user.promotion_users.length != 0) {
                state.currentPromotionId = user.promotion_users[0].promotion_id
            }

            const { createLog, addUserAgentLog, getLogs } = useLogs()

            // Log user login
            await createLog(user.id, null, 'login', null)

            if (state.currentPromotionId) {
                // init module in state  before render after
                // stokvker les modules dans le state module
                // todo init les modules
                await getLogs(state.currentPromotionId , user.id)
                await initModules(state.currentPromotionId , user.id)
            }

            // Log user agent
            await addUserAgentLog()

            // Retourner l'user pour valider la premiere connexion
            return user

        }else {
            return false
        }
    }
    
    return {
        initUser,
        user,
        avatar,
        avatarId,
        isFirstConnexion,
        setUser,
        currentPromotionId,
        setAvatarId,
        userId,
        lastSeenConversation
    };

}


export {
    useUser,
    useAvatarList,
}