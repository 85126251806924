<template>

  <div class="module-slider  text-center py-6 w-full transition transition-all duration-200"
      :class="[ canAccessCurrentModule ? 'text-white bg-orange-primary' : 'bg-green-light2 text-green-dark inactive']"
  >

      <div class="main-carousel">

        <div v-for="(module, index) in modules" :key="index" class="carousel-cell w-full px-4" :data-id-module="module.id">
          <div>
            <div class="text-normal font-bold" >Module {{index + 1}}</div>
            <div class="text-lg  font-title font-bold uppercase mt-2">{{module.title}}</div>
          </div>
        </div>
      </div>

  </div>

</template>

<script>
import {useModule} from "@composables/module";
import {nextTick} from "vue";

export default {
  setup() {

    const {
      initSelector,
      modules,
      canAccessCurrentModule
    } = useModule()

    nextTick(() => {
      let elem = document.querySelector('.main-carousel');
      if(elem) {
        initSelector(elem)
      }else {
        console.error('Aucuns elements .main-carousel')
      }

    })

    return {modules, canAccessCurrentModule}

  }
}
</script>