<template>
  <div id="conversationMessageList" class="overflow-auto flex flex-col">
    <div v-if="!isEmptyObject(messages)">
      <div
        v-for="(messageGroup, key) in messagesGroups"
        :key="key"
        class="flex flex-col p-3"
      >
        <span class="dateAgo px-2 py-1 bg-orange-dark text-white rounded-xl self-center">
          {{ dateAgo(key) }}
        </span>
        <ConversationMessage
          v-for="(message, index) in messageGroup"
          :key="message.id"
          :message="message"
          :previousMessageUserId="messageGroup[index - 1]?.user.id"
          :nextMessageUserId="messageGroup[index + 1]?.user.id"
        />
      </div>
    </div>
    <div class="text-xs text-green-primary p-3 text-center" v-else>
       {{ t('conversation.aucun_message') }}
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { isEmptyObject } from "@/helpers";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import ConversationMessage from "@components/Conversation/ConversationMessage";

dayjs.extend(isToday);
dayjs.extend(isYesterday);

export default {
  components: {
    ConversationMessage,
  },

  props: {
    messages: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const { t } = useI18n()
    const dateFormatMessage = "HH:mm"

    const messagesGroups = computed(() => {
      // Group messages by days
      return Object.values(props.messages).reduce((acc, currentItem) => {
        const day = dayjs(currentItem["created_at"]).format("YYYY-MM-DD")
        if (!acc[day]) {
          acc[day] = []
        }
        acc[day].push(currentItem)
        return acc
      }, {})
    })

    const dateAgo = (date) => {
      if (dayjs(date).isToday()) {
        return "Aujourd'hui";
      }
      if (dayjs(date).isYesterday()) {
        return "Hier";
      }
      if (dayjs(date).diff(dayjs(date), "year") === 0) {
        return dayjs(date).format("D MMM");
      } // Don't display the year if the year of the date is the current year
      return dayjs(date).format("D MMM YYYY");
    }
    
    onMounted(() => {
      const element = document.querySelector('#conversationMessageList')
      element.scrollTop = element.scrollHeight
    });

    return {
      dateFormatMessage,
      messagesGroups,

      t,
      dateAgo,
      isEmptyObject,
    };
  },
};
</script>

<style scoped>
.dateAgo {
  font-size: 0.7rem;
  height: 21px;
}
</style>
