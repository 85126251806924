<template>
    <div class="bg-green-light2 flex p-2">
      <div class="rounded-full w-5 h-5 mr-2 bg-green-light1 flex-none flex items-center justify-center">
        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.608222 6.1387L0.617556 1.9229C0.634777 1.90367 0.655741 1.88796 0.679233 1.87668C0.702726 1.8654 0.728282 1.85877 0.754422 1.85717C0.780563 1.85557 0.806768 1.85904 0.831525 1.86738C0.85628 1.87571 0.879095 1.88874 0.898647 1.90572L1.9141 2.76363C2.38334 2.12806 3.0033 1.61238 3.72117 1.26049C4.43905 0.908607 5.23358 0.730947 6.03711 0.742637C8.82312 0.742638 10.4039 3.06678 10.4039 3.06678L10.5532 3.24665C10.5532 3.24665 8.7391 1.90471 6.64389 2.61711C6.11669 2.79225 5.63153 3.06995 5.21787 3.43335C4.8042 3.79675 4.47067 4.23826 4.2375 4.73107L5.22288 5.56372C5.26213 5.59843 5.28626 5.64645 5.29031 5.69794C5.29436 5.74943 5.27802 5.80048 5.24466 5.8406L0.944285 6.45296C0.822929 6.47822 0.736839 6.51864 0.648674 6.4469C0.560508 6.37515 0.60511 6.26501 0.608222 6.1387Z" fill="#3D7F7F"/>
        </svg>
      </div>
      
      <div class="mx-2 text-xs min-w-0">
        <div class="text-green-primary">{{ replyTo.user.first_name }} {{ replyTo.user.name }}</div>
        <div class="text-green-dark1 overflow-ellipsis overflow-hidden whitespace-nowrap">{{ replyTo.content }}</div>
      </div>

      <div class="rounded-full w-4 h-4 mr-2 bg-orange-dark flex-none flex items-center justify-center self-center" @click="setReplyTo(null)">
        <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1.61536L1.00028 8.61508" stroke="white" stroke-width="2" stroke-linecap="round"/>
          <path d="M1 1.61536L7.99972 8.61508" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
</template>

<script>
import { useConversation } from "@composables/conversation"

export default {
    props: {
        replyTo: {
            type: Object,
            default: () => {}
        },
    },
    setup() {
        const { setReplyTo } = useConversation()

        return {
            setReplyTo
        }
    }
}
</script>
