export default {
    fr: {
        premiere_connexion: {
                message: "Bienvenue {name} sur votre espace de formation Ediad by SIPAD ! <br> Laissez-vous guider par notre démonstration en vidéo :",
                button: "Continuer",
                video_url: "assets/Tutoriel_EDIAD.mp4",
                choix_avatar: 'Choisissez une image pour votre profil :',
                consignes_questionnaire: 'Merci de compléter ce questionnaire.<br>' +
                    'Les réponses sont confidentielles, elles seront transmises à votre formateur Ediad by SIPAD afin de  mieux vous connaitre et répondre à vos attentes',
                form : {
                    fields : [
                        // { name: 'email', type:"email",  label: '1. Votre adresse e-mail*', required: 'required'},
                        { name: 'gender', type: 'radio', label: 'Etes-vous ?*', required: 'required', responses: [
                                { label: 'Un homme', value: "h"},
                                {label: 'Une femme', value: "f"}
                            ]},
                        { name: 'age', type: 'radio', required: 'required', label: 'Quelle est votre tranche d\'âge ?*', responses: [
                                {label: "18 - 25 ans", value:"18_25"},
                                {label: "26 - 35 ans", value: "26_35"},
                                {label: "36 - 45 ans", value: '36_45'},
                                {label: "46 - 55 ans", value: '46_55'},
                                {label: "56 ans et +", value: '56_et_+'}
                            ]},
                        { name: 'is_disabled_worker', required: 'required', type:"radio" ,label: 'Êtes-vous reconnu(e) en qualité de travailleur(se) handicapé(e) ?*', responses : [{label: 'Oui', value: true}, {label: 'Non', value: false}]},
                        {
                            name: 'level_studies',
                            type:"radio",
                            required: 'required',
                            label: 'Quel est le niveau de votre dernier diplôme obtenu ?*',
                            responses : [
                                {label: "Niveau 3 : BEP, CAP...", value:"niveau_3_bep_cap"},
                                {label: "Niveau 4 : Baccalauréat ou équivalent", value:"niveau_4_baccalaureat_equivalent"},
                                {label: "Niveau 5 : DEUG, BTS, DUT... (Bac + 2)", value:"niveau_5_deug_bts_bac+2"},
                                {label: "Niveau 6 : Licence, licence professionnelle ( Bac + 3)", value:"niveau_6_licence"},
                                {label: "Niveau 6 : Master I, Maitrise (Bac + 4)", value:"niveau_6_master_1_maitrise"},
                                {label: "Niveau 7 : Master II, DEA, DESS, ingénieur (Bac + 5)", value:"niveau_7_master_2_dea_dess"},
                                {label: "Niveau 8 : Doctorat, habilitation à la recherche (Bac + 8)", value:"niveau_8_doctorat"},
                                {label: "Sans diplôme", value: "sans_diplome"}
                            ]
                        },
                        { name: 'actual_position', required: 'required', label: 'Quel poste occupez-vous actuellement ?*'},
                        { name: 'actual_working_time', required: 'required', label: 'Quel est votre temps de travail actuel ?*'},
                        { name: 'rise_working_time', type:"radio", label: 'Si vous êtes à temps partiel, souhaiteriez-vous augmenter votre temps de travail ?',  responses : [{label: 'Oui', value: true}, {label: 'Non', value: false}]},
                        {
                            name: 'contract',
                            type:"radio",
                            required: 'required',
                            label: 'Êtes-vous* :',
                            responses : [
                                {label: "En CDI (Contrat à Durée Indéterminée)", value:"cdi"},
                                {label: "En CDD (Contrat à Durée Déterminée)", value:"cdd"},
                                {label: "Intérimaire", value:"interimaire"},
                                {label: "Indépendant, particulier employeur", value:"independant_particulier"},
                            ]
                        },
                        {
                            name: 'experiences',
                            type:"radio",
                            required: 'required',
                            label: 'Combien d\'années d\'expérience avez-vous pour votre métier actuel ?*',
                            responses : [
                                {label: "Moin d'un an", value:"moin_1_an"},
                                {label: "Entre 1 et 3 ans", value:"entre_1_3_an"},
                                {label: "Entre 3 et 5 ans", value:"entre_3_5_an"},
                                {label: "Entre 5 et 10 ans", value:"entre_5_10_an"},
                                {label: "Plus 10 ans", value:"plus_10_an"},
                            ]
                        },
                        { name: 'has_professional_courses', type:"radio", required: 'required', label: 'Avez-vous déjà suivi des formations professionnelles ?*',  responses : [{label: 'Oui', value: true}, {label: 'Non', value: false}]},
                        { name: 'professional_courses', label: 'Si oui, lesquelles ? (les plus récentes)'},
                        { name: 'has_distance_learning', type:"radio", required: 'required', label: 'Avez-vous déjà suivi des formations à distance, en elearning ?*',  responses : [{label: 'Oui', value: true}, {label: 'Non', value: false}]},
                        { name: 'expectations', label: 'Qu\'attendez-vous de la formation que vous allez suivre ?'},
                        { name: 'additionnal_needs', label: 'Quels seraient vos autres besoins ou souhaits en formation ?'}
                    ],
                    errors: {
                        email: "Les champs marqués d'une étoile* sont obligatoires",
                        required: "L'adresse e-mail est incorrecte",
                        other: "Nous avons rencontré un problème... merci de recommencer plus tard.",
                    },
                    bouton_valider : 'Valider'
                },
        },
        modules: {
            aucuns_modules: 'Vous ne suivez pas encore de formation. <br>Merci de revenir plus tard, ou de contacter votre formateur Ediad by SIPAD.',
            premier_module: 'Bonjour {name} ! <br>Commencez votre formation en cliquant sur la première séquence du module 1 : {sequence_name}',
            index_module: 'Ravi de vous revoir {name} <br>Complétez toutes les séquences du module {index_module} pour progresser dans votre formation !',
            parcours_termine: 'Bravo {name} !<br>Vous avez terminé votre formation avec succès. Vous pouvez repasser les évaluations pour améliorer vos scores. À vous de jouer !',
        },
        menu: {
            changer_avatar: "Changer mon avatar",
            espace_sipad: "Espace SIPAD",
            reprendre_parcours: "Reprendre mon parcours",
            corriges_evaluations: "Corrigés de mes évaluations réussies",
            deconnexion: "Déconnexion",
            selectionner_avatar: "Selectionnez un avatar :",
            valider_avatar: "Valider mon avatar"
        },
        accueil: {
            aucuns_modules: 'Vous ne suivez pas encore de formation. <br>Merci de revenir plus tard, ou de contacter votre formateur Ediad by SIPAD.',
            premier_module: 'Bonjour {name} ! <br>Commencez votre formation en cliquant sur la première séquence du module 1 : {sequence_name}',
            index_module: 'Ravi de vous revoir {name} <br>Complétez toutes les séquences du module {index_module} pour progresser dans votre formation !',
            parcours_termine: 'Bravo {name} !<br>Vous avez terminé votre formation avec succès. Vous pouvez repasser les évaluations pour améliorer vos scores. À vous de jouer !',
        },
        sequence: {
            message: "Consultez les documents et complétez les activités dans l’ordre pour débloquer l’évaluation finale.",
            message_en_cours: "Passez l'évaluation et obtenez un score de 80% pour passer à l'étape suivante.",
            message_termine: "Vous avez complété cette séquence. Vous pouvez consulter les ressources librement, et repasser l’évaluation pour augmenter votre score !",
        },
        scenario: {
            nom_quiz: "Apports théoriques",
            nom_quiz_evaluation: "Evaluation",
            message_video: "Regarder la vidéo pour continuer :",
            message_pdf: "Consulter le document pour continuer :",
            message_genialy: "Regardez la presentation :",
            message_true_false: "Les phrases suivantes sont-elles vraies ou fausses ?",
            message_true_false_unique: "La phrase suivantre est-elle vraie ou fausse ?",
            message_qcm: "Répondez à ces questions :",
            message_qcm_unique: "Répondez à la question:",
            message_qcm_orange: "(Plusieurs réponses possibles)",
            message_reorder: "Déplacez les éléments de la liste afin de les remettre dans l’ordre :",
            message_drag_drop: "Positionnez l'année face à l'événement correspondant :",
            message_drag_drop_orange: "(cliquez sur une date, puis sur son emplacement de destination)",
            message_bonne_reponse: "Bonne réponse !",
            message_bonnes_reponses: "Bonne réponse !",
            message_mauvaise_reponse: "Mauvaise réponse...",
            message_mauvaises_reponses: "Mauvaise réponses...",
        },
        button: {
            voir_score: "Voir mon score",
            etape_suivante: "Etape suivante",
            retour_modules: "Retour aux modules",
            retour_accueil: "Retour à l'accueil",
            valider_reponses: "Valider mes réponses",
            valider_reponses_unqiue: "Valider ma réponse",
            valider_video: "Etape suivante",
            module_suivant: "Passer au module suivant",
            sequence_suivante: "Passer à la séquence suivante",
            ressayer_evaluation: "Réssayer l'évaluation",
            fin_activite: 'Terminer',
            recommencer_scenario: 'Recommencer depuis le début',
            ouvrir_pdf: 'Ouvrir le document',
        },
        score: {
            titre: "Mon score",
            titre_reussite: "Félicitations !",
            titre_echec: "Dommage...",
            message_reussite: "Vous pouvez passer à l’étape suivante, ou consulter le corrigé pour tenter d’améliorer votre score.",
            message_reussite_100: "Vous connaissez cette séquence sur le bout des doigts.",
            message_echec: "Encore un petit effort... Obtenez <span class='text-orange-primary'>80%</span> de bonnes réponses pour débloquer l’étape suivante",
            consulter_correction: "Consulter le corrigé"
        },
        conversation: {
            titre: "Conversation",
            aucun_message: "Il n'y a aucun message dans la conversation. Soyez le premier à poster !",
            textbox_placeholder: "Entrez votre message"
        }
    },
}