<template>
  <svg width="230px" height="230px" viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path data-id="1" class="wheel__segment segment__1" d="M79.463 224.372C102.56 231.876 127.44 231.876 150.537 224.372L115 115L79.463 224.372Z" fill="#E8560E"/>
    <path data-id="2" class="wheel__segment segment__2" d="M150.534 224.372C173.631 216.868 193.76 202.245 208.035 182.598L115 115L150.534 224.372Z" fill="#A74004"/>
    <path data-id="3" class="wheel__segment segment__3" d="M208.033 182.601C222.309 162.955 229.998 139.293 230 115.007L115 115L208.033 182.601Z" fill="#9C3100"/>
    <path data-id="4" class="wheel__segment segment__4" d="M230 115C230 90.7144 222.312 67.0521 208.037 47.4046L115 115L230 115Z" fill="#3C0D06"/>
    <path data-id="5" class="wheel__segment segment__5" d="M208.039 47.4073C193.765 27.7593 173.636 13.1346 150.539 5.62932L115 115L208.039 47.4073Z" fill="#240200"/>
    <path data-id="6" class="wheel__segment segment__6" d="M150.532 5.62689C127.435 -1.87678 102.554 -1.8756 79.4576 5.63027L115 115L150.532 5.62689Z" fill="#183C3B"/>
    <path data-id="7" class="wheel__segment segment__7" d="M79.4574 5.63032C56.3607 13.1362 36.233 27.7615 21.9593 47.4099L115 115L79.4574 5.63032Z" fill="#2A5A59"/>
    <path data-id="8" class="wheel__segment segment__8" d="M21.9602 47.4086C7.68619 67.0569 -0.00114441 90.7196 0 115.005L115 115L21.9602 47.4086Z" fill="#3D7F7F"/>
    <path data-id="9" class="wheel__segment segment__9" d="M0 115C0 139.286 7.68843 162.948 21.9633 182.596L115 115H0Z" fill="#9C3100"/>
    <path data-id="10" class="wheel__segment segment__10" d="M21.9635 182.596C36.2385 202.244 56.3671 216.867 79.4644 224.372L115 115L21.9635 182.596Z" fill="#3C0D06"/>

    <path class="wheel__text text__1" d="M115.359 212H117.087V199.256H115.557L112.011 202.19L113.019 203.396L115.359 201.236V212Z" fill="white"/>
    <path class="wheel__text text__2" d="M169.139 195.928L175.896 191.018L175.007 189.795L169.823 193.562L170.836 187.82C171.108 186.265 171.273 184.655 170.289 183.3C168.913 181.407 166.744 181.515 164.967 182.805C163.191 184.096 162.461 185.939 163.507 187.961L165.116 186.991C164.582 185.889 164.837 184.769 165.842 184.039C166.817 183.33 168.073 183.375 168.814 184.394C169.427 185.239 169.378 186.365 169.212 187.331L168.06 194.442L169.139 195.928Z" fill="white"/>
    <path class="wheel__text text__3" d="M204.032 148.154C206.042 148.201 207.336 146.956 207.937 145.107C208.632 142.967 208.068 140.683 205.74 139.927C204.251 139.443 202.671 139.876 201.92 141.316L201.885 141.305C201.926 139.956 201.086 138.755 199.819 138.344C197.577 137.615 195.954 138.999 195.308 140.985C194.786 142.594 195.06 144.254 196.395 145.388L197.505 143.894C196.783 143.262 196.468 142.308 196.757 141.418C197.136 140.254 198.104 139.603 199.388 140.02C200.586 140.41 201.094 141.237 200.282 143.736L201.72 144.204C202.22 142.663 203.066 140.875 205.086 141.531C206.387 141.954 206.927 143.322 206.515 144.588C206.098 145.872 205.355 146.237 204.063 146.252L204.032 148.154Z" fill="white"/>
    <path class="wheel__text text__4" d="M207.429 84.0295L206.895 82.3861L204.224 83.2538L203.668 81.5419L202.23 82.0091L202.786 83.721L194.775 86.3242L195.42 88.31L204.761 90.6123L206.438 90.0672L204.758 84.8972L207.429 84.0295ZM203.32 85.3645L204.533 89.0964L197.271 87.3678L197.26 87.3335L203.32 85.3645Z" fill="white"/>
    <path class="wheel__text text__5" d="M162.212 44.7916L167.745 48.8121L171.808 43.465C170.855 43.3066 169.815 42.84 169.291 42.4591C167.82 41.3905 167.351 39.8926 168.44 38.3927C169.392 37.0821 170.877 36.9372 171.984 37.7413C172.901 38.4078 173.242 39.2559 172.918 40.467L174.68 41.0127C175.173 39.3238 174.489 37.6924 173.091 36.6767C171.082 35.2167 168.481 35.3966 166.989 37.4499C165.529 39.4594 165.781 41.7779 167.717 43.1851C168.052 43.4284 168.873 43.8912 169.259 43.9717L167.343 46.5175L163.164 43.481L162.212 44.7916Z" fill="white"/>
    <path class="wheel__text text__6" d="M113.337 30.7439L115.371 30.7439L118.593 25.6499C119.367 24.4259 119.871 23.4539 119.871 21.8879C119.871 19.4039 117.891 17.7839 115.497 17.7839C113.103 17.7839 111.123 19.2599 111.123 22.0679C111.123 24.8579 113.229 26.0999 115.065 26.0999C115.587 26.0999 116.055 26.0099 116.469 25.8119L116.541 25.8119L113.337 30.7439ZM118.143 21.9419C118.143 23.4899 117.081 24.5879 115.497 24.5879C113.913 24.5879 112.851 23.4899 112.851 21.9419C112.851 20.3939 113.913 19.2959 115.497 19.2959C117.081 19.2959 118.143 20.3939 118.143 21.9419Z" fill="white"/>
    <path class="wheel__text text__7" d="M61.1182 34.6119L59.5454 35.7546L62.062 47.8542L62.9296 49.0483L69.4098 44.3402L68.5211 43.1169L63.5408 46.7353L61.1182 34.6119Z" fill="white"/>
    <path class="wheel__text text__8" d="M23.0411 84.9614C22.3236 87.1697 23.1429 89.3664 25.4368 90.1118C26.9433 90.6013 28.3615 89.9643 29.1493 88.763L29.2007 88.7797C29.1455 89.7649 29.7302 91.0526 31.4079 91.5977C33.2396 92.1928 34.9548 90.9332 35.5722 89.033C36.1896 87.1328 35.5424 85.1056 33.7107 84.5104C32.033 83.9653 30.8031 84.6634 30.2686 85.4928L30.2173 85.4761C30.2861 84.0412 29.5131 82.6923 28.0066 82.2028C25.7126 81.4574 23.7586 82.753 23.0411 84.9614ZM32.937 86.0759C34.0669 86.4431 34.5762 87.5549 34.2369 88.5991C33.8698 89.729 32.8149 90.2379 31.7022 89.8764C30.7093 89.5538 29.9907 88.62 30.4023 87.3532C30.7972 86.1377 31.9955 85.77 32.937 86.0759ZM24.4791 85.4286C24.8852 84.1789 26.0517 83.3845 27.4726 83.8462C28.7565 84.2634 29.381 85.5451 28.9643 86.8859C28.53 88.1643 27.443 88.9467 25.9708 88.4684C24.6526 88.0401 24.0341 86.7981 24.4791 85.4286Z" fill="white"/>
    <path class="wheel__text text__9" d="M22.581 142.923L23.2095 144.858L29.0499 146.348C30.4531 146.706 31.5333 146.885 33.0227 146.401C35.3851 145.633 36.3139 143.249 35.5742 140.973C34.8344 138.696 32.8188 137.269 30.1482 138.137C27.4947 138.999 26.9643 141.385 27.5317 143.132C27.693 143.628 27.9232 144.045 28.2394 144.378L28.2617 144.446L22.581 142.923ZM32.4373 144.774C30.9651 145.252 29.5926 144.582 29.1032 143.075C28.6137 141.569 29.3298 140.219 30.802 139.741C32.2742 139.263 33.6467 139.933 34.1362 141.44C34.6256 142.946 33.9096 144.296 32.4373 144.774Z" fill="white"/>
    <path class="wheel__text text__10" d="M54.3248 190.452L55.7228 191.468L63.2136 181.158L61.9758 180.259L57.3824 180.548L57.4891 182.116L60.6518 181.744L54.3248 190.452ZM62.7253 188.679C61.1488 190.849 59.4791 194.464 62.4061 196.591C65.3332 198.717 68.2552 196.012 69.8317 193.842C71.4716 191.585 73.0778 188.058 70.1508 185.931C67.2238 183.804 64.3652 186.422 62.7253 188.679ZM64.1232 189.695C65.1601 188.268 67.3107 185.737 69.2621 187.154C71.2134 188.572 69.4705 191.4 68.4337 192.827C67.4603 194.166 65.2462 196.785 63.2949 195.367C61.3435 193.95 63.1499 191.035 64.1232 189.695Z" fill="white"/>
  </svg>
</template>