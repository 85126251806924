<template>
  <div style="width: 100%;">
      <div style="position: relative; padding-bottom: 155%; padding-top: 0; height: 0;">
        <iframe frameborder="0"
                width="1200px"
                height="675px"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                :src="src"
                type="text/html"
                allowscriptaccess="always"
                allowfullscreen="true"
                scrolling="yes" allownetworking="all"
        ></iframe>
      </div>
  </div>
</template>

<script>
export default {

  props: {
      src: {
        type: [String],
        required: true
      }
  },

  setup() {

  }
}
</script>