import { computed, reactive } from "vue"
import { client as ApolloClient } from "../config/clients"
import { useUser } from "./user"
import dayjs from "dayjs"


const state = reactive({
  conversationId: null,
  messages: {},
  loading: false,
  error: null,
  replyTo: null,
});


const useConversation = () => {

    /**
     *
     * @type {ComputedRef<*>}
     */
    const messages = computed(() => {
      return state.messages
    })

    /**
     *
     * @type {ComputedRef<*>}
     */
    const conversationId = computed(() => {
      return state.conversationId
    });

    /**
     *
     * @type {ComputedRef<*>}
     */
    const loading = computed(() => {
      return state.loading
    })
    
    /**
     *
     * @type {ComputedRef<*>}
     */
    const error = computed(() => {
      return state.error
    })

    /**
     *
     * @type {ComputedRef<*>}
     */
    const newMessagesCount = computed(() => {
      const { lastSeenConversation } = useUser()

      return state.messages ? Object.values(state.messages).filter( message => dayjs(message.created_at) > dayjs(lastSeenConversation.value)).length : 0
    })
 
    /**
     *
     * @type {ComputedRef<*>}
     */
    const replyTo = computed(() => {
      return state.replyTo
    })
    

    const setReplyTo = (replyTo) => {
      state.replyTo = replyTo
    }

    /**
     * get conversation datas
     * @param promotion_id
     * @returns {Promise<boolean|*>}
     */
    const initConversation = (promotion_id, status) => {
      ApolloClient
        .query({
          query: require("@gql/conversation/get_conversation_message.gql"),
          variables: {
            promotionId: promotion_id,
            status: status,
          },
        })
        .then(response => {
          state.conversationId = response.data.conversation[0]?.id
          state.messages = response.data.conversation[0]?.messages;

          ApolloClient
            .subscribe({
              query: require("@gql/conversation/subscription_conversation.gql"),
              variables: {
                promotionId: promotion_id,
                status: status,
              },
            })
            .subscribe({
              next(response) {
                state.messages = response.data.conversation[0]?.messages
                
                // Scroll messages list to bottom
                setTimeout(function() {
                  const element = document.querySelector('#conversationMessageList')
                  // Prevent no conversation (just in case)
                  if (element) {
                    element.scrollTop = element.scrollHeight
                  }
                }, 800)
              },
            })
        })
    }

    const insertMessage = async (content) => {
      const { userId } = useUser()
      state.error = null
      state.loading = true

      let response = await ApolloClient.mutate({
        mutation: require("@gql/conversation/insert_conversation_message.gql"),
        variables: {
          message: {
            conversation_id: state.conversationId,
            user_id: userId.value,
            status_id: "d3cce19b-0fab-4215-85fe-d5c2f6678b7a", // approved
            content: content,
            parent_id: state.replyTo ? state.replyTo.id : null
          },
        },
      }).catch((error) => {
        state.error = error;
        return false;
      });

      state.loading = false

      if (response.data.insert_conversation_message.returning[0].id) {
        state.error = null
        state.replyTo = null
        return { success: true }
      }

      return { success: false }
    }

    return {
      conversationId,
      messages,
      loading,
      error,
      newMessagesCount,
      replyTo,

      initConversation,
      insertMessage,
      setReplyTo,
    };
}


export {
  useConversation,
}