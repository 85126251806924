<template>
  <div class="w-full">
    <transition name="fade" mode="out-in">

      <div v-if="loading" class="flex justify-center py-20">
        <Loader />
      </div>
      <ScenarioContent v-else :scenarioId="scenario_id" :sequenceId="sequence.id"/>

    </transition>

  </div>
</template>

<script>
import ScenarioContent from "@components/Scenario/ScenarioContent"
import Loader from "@components/Loader"
import {useRoute} from "vue-router";
import {computed, ref} from "vue";
import {useModule} from "@composables/module";
import {useScenario} from "../composables/scenario";

export default {

  components: {ScenarioContent, Loader},

  setup() {
    const route = useRoute()
    const loading = ref(true)

    const {getModuleBySequenceId} = useModule()
    const {getScenario, results} = useScenario()

    const module = getModuleBySequenceId(route.params.sequence_id)
    const sequence = computed( () => module.sequences.find(sequence => sequence.id == route.params.sequence_id))


    const initScenario = async (scenario_id) => {
      await getScenario(scenario_id)
      loading.value = false
    }

    const scenario_id = route.params.scenario_id
    initScenario(scenario_id)

    return {loading, module, sequence, scenario_id, results}

  }
}
</script>