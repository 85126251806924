<template>
  <svg  width="230px" height="230px" viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path data-id="1" class="wheel__segment segment__1" d="M70.9914 221.246C84.9439 227.025 99.898 230 115 230C130.102 230 145.056 227.025 159.009 221.246L115 115L70.9914 221.246Z" fill="#E8560E"/>
    <path data-id="2" class="wheel__segment segment__2" d="M159.01 221.245C172.963 215.466 185.64 206.995 196.319 196.316C206.997 185.637 215.468 172.959 221.247 159.007L115 115L159.01 221.245Z" fill="#A74004"/>
    <path data-id="3" class="wheel__segment segment__3" d="M221.245 159.01C227.025 145.058 230 130.104 230 115.002C230 99.8999 227.026 84.9457 221.247 70.9931L115 115L221.245 159.01Z" fill="#9C3100"/>
    <path data-id="4" class="wheel__segment segment__4" d="M221.248 70.996C209.578 42.8174 187.191 20.4289 159.013 8.75583L115 115L221.248 70.996Z" fill="#3C0D06"/>
    <path data-id="5" class="wheel__segment segment__5" d="M159.015 8.75665C130.838 -2.91692 99.1773 -2.91896 70.9983 8.75099L115 115L159.015 8.75665Z" fill="#240200"/>
    <path data-id="6" class="wheel__segment segment__6" d="M70.9954 8.75218C42.8168 20.4229 20.4285 42.8095 8.7556 70.9872L115 115L70.9954 8.75218Z" fill="#183C3B"/>
    <path data-id="7" class="wheel__segment segment__7" d="M8.75696 70.9839C-2.91681 99.1613 -2.91907 130.822 8.75069 159.001L115 115L8.75696 70.9839Z" fill="#2A5A59"/>
    <path data-id="8" class="wheel__segment segment__8" d="M8.75134 159.003C14.5298 172.955 23 185.633 33.6781 196.313C44.3562 206.992 57.0332 215.464 70.9854 221.244L115 115L8.75134 159.003Z" fill="#3D7F7F"/>

    <path class="wheel__text text__1" d="M115.359 212H117.087V199.256H115.557L112.011 202.19L113.019 203.396L115.359 201.236V212Z" fill="white"/>
    <path class="wheel__text text__2" d="M181.139 186.54L187.044 180.634L185.975 179.565L181.444 184.096L181.546 178.267C181.571 176.689 181.482 175.072 180.299 173.889C178.644 172.234 176.518 172.679 174.966 174.232C173.413 175.785 172.98 177.72 174.329 179.552L175.767 178.343C175.067 177.338 175.144 176.192 176.022 175.314C176.875 174.461 178.122 174.309 179.013 175.2C179.751 175.938 179.879 177.058 179.866 178.038L179.84 185.242L181.139 186.54Z" fill="white"/>
    <path class="wheel__text text__3" d="M209.944 119.173C211.87 118.597 212.716 117.013 212.716 115.069C212.716 112.819 211.474 110.821 209.026 110.821C207.46 110.821 206.092 111.721 205.822 113.323H205.786C205.408 112.027 204.238 111.145 202.906 111.145C200.548 111.145 199.432 112.963 199.432 115.051C199.432 116.743 200.206 118.237 201.826 118.903L202.42 117.139C201.538 116.761 200.944 115.951 200.944 115.015C200.944 113.791 201.664 112.873 203.014 112.873C204.274 112.873 205.012 113.503 205.012 116.131H206.524C206.524 114.511 206.776 112.549 208.9 112.549C210.268 112.549 211.204 113.683 211.204 115.015C211.204 116.365 210.61 116.941 209.386 117.355L209.944 119.173Z" fill="white"/>
    <path class="wheel__text text__4" d="M183.349 45.6703L182.127 44.4484L180.142 46.4339L178.869 45.1611L177.8 46.2303L179.072 47.5031L173.116 53.4597L174.592 54.9362L183.96 52.747L185.207 51.4996L181.363 47.6558L183.349 45.6703ZM180.294 48.725L183.069 51.4996L175.814 53.2561L175.789 53.2306L180.294 48.725Z" fill="white"/>
    <path class="wheel__text text__5" d="M112.023 30.744H118.863L119.007 24.03C118.143 24.462 117.027 24.696 116.379 24.696C114.561 24.696 113.301 23.76 113.301 21.906C113.301 20.286 114.417 19.296 115.785 19.296C116.919 19.296 117.693 19.782 118.143 20.952L119.889 20.358C119.295 18.702 117.783 17.784 116.055 17.784C113.571 17.784 111.573 19.458 111.573 21.996C111.573 24.48 113.139 26.208 115.533 26.208C115.947 26.208 116.883 26.1 117.243 25.938L117.189 29.124H112.023V30.744Z" fill="white"/>
    <path class="wheel__text text__6" d="M54.3926 56.9515L55.8308 55.5132L54.5071 49.6329C54.1889 48.2201 53.858 47.1765 52.7507 46.0691C50.9942 44.3127 48.4486 44.5672 46.7558 46.26C45.063 47.9529 44.7066 50.3966 46.6922 52.3822C48.665 54.355 51.0324 53.7441 52.3306 52.4458C52.6997 52.0767 52.967 51.6821 53.1198 51.2494L53.1707 51.1985L54.3926 56.9515ZM51.567 47.3292C52.6616 48.4238 52.687 49.9511 51.567 51.0712C50.4469 52.1913 48.9196 52.1658 47.825 51.0712C46.7303 49.9766 46.7049 48.4492 47.825 47.3292C48.945 46.2091 50.4724 46.2346 51.567 47.3292Z" fill="white"/>
    <path class="wheel__text text__7" d="M18.5 111.745V113.689L29.768 118.765H31.244V110.755H29.732V116.911L18.5 111.745Z" fill="white"/>
    <path class="wheel__text text__8" d="M46.7601 183.744C48.402 185.386 50.7312 185.653 52.4368 183.948C53.5568 182.828 53.6332 181.275 52.9204 180.028L52.9586 179.99C53.8114 180.486 55.2242 180.55 56.4715 179.302C57.8334 177.94 57.4897 175.84 56.0769 174.427C54.6641 173.015 52.564 172.671 51.2021 174.033C49.9548 175.28 50.0185 176.693 50.5148 177.546L50.4767 177.584C49.2293 176.871 47.6765 176.948 46.5565 178.068C44.8509 179.773 45.1182 182.102 46.7601 183.744ZM52.2458 175.433C53.0859 174.593 54.3078 174.644 55.0842 175.42C55.9242 176.26 55.8988 177.431 55.0714 178.259C54.3332 178.997 53.175 179.213 52.2331 178.271C51.3294 177.368 51.5458 176.133 52.2458 175.433ZM47.8292 182.675C46.9001 181.746 46.7219 180.346 47.7783 179.289C48.7329 178.335 50.1585 178.36 51.164 179.34C52.1058 180.308 52.3095 181.631 51.2149 182.726C50.2348 183.706 48.8475 183.693 47.8292 182.675Z" fill="white"/>
  </svg>
</template>