<template>
   <div class="w-full bg-green-light2">

       <QuizTrueFalse
           v-for="(item, index) in quiz"
           :key="index"
           :item="item"
           :showResponse="showResponse"
           @input="item.value =  ! showResponse ? $event.target.value : false" />

       <div v-if="! scenarioIsEvaluation && ! showResponse" @click="submitQuiz" class="flex justify-center mx-auto items-center py-8 "
            :class="canSubmit ? 'text-orange-primary cursor-pointer' : 'opacity-50 text-green-light'">

         <div>{{ t('button.valider_reponses') }}</div>
         <div  class="flex-shrink-0 ml-4 border-2  rounded-full px-2 py-2" :class="canSubmit ? 'border-orange-primary' : 'opacity-50 border-green-light'">
           <div class="w-6">
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
             </svg>
           </div>
         </div>

       </div>

   </div>

</template>


<script>
import QuizTrueFalse from "@components/Quiz/QuizTrueFalse";
import {useQuizTrueFalse} from "@composables/quizTrueFalse";
import {useScenario} from "@composables/scenario";
import {scrollTo} from "@/helpers";
import {watchEffect} from "vue";
import {useI18n} from "vue-i18n";

export default {

  props: {
    node: {
      type: Object,
      required: true
    }
  },

  components: {QuizTrueFalse},

  setup(props) {
    const {t} = useI18n()
    const {formatTrueFalseContent, setCurrentNodeValidity, setCurrentNodeType, saveNodeResults, scenarioIsEvaluation, setHasSubmitNode, setCurrentQuiz} = useScenario()
    const {setQuizTrueFalse, quiz, showResponse, submit: validateQuiz, canSubmit} = useQuizTrueFalse()


    setHasSubmitNode(false)
    let formatedQuiz = formatTrueFalseContent.value
    setQuizTrueFalse(formatedQuiz)
    setCurrentQuiz(formatedQuiz)
    setCurrentNodeValidity(false)
    setCurrentNodeType('true_false')



    watchEffect( () => {
      if( canSubmit.value  && scenarioIsEvaluation.value) {
        saveNodeResults(props.node.id, 'true_false', quiz.value)
        setCurrentNodeValidity(true)
      }
    })

    const submitQuiz = () => {
      if( ! canSubmit.value ) return
      validateQuiz()
      saveNodeResults(props.node.id, 'true_false', quiz.value)
      setCurrentNodeValidity(true)
      scrollTo(document.documentElement, 0)
    }

    return {t, quiz, showResponse, canSubmit, submitQuiz, scenarioIsEvaluation}

  }
}
</script>