<template>

  <Header></Header>
  <div>
    <transition name="loader" mode="out-in">
      <div v-if="!appLoading" >
        <AppTransition />
      </div>
      <div v-else>
        <div class="flex justify-center py-20">
          <Loader />
        </div>
      </div>
    </transition>
  </div>


</template>

<script>
import Header from "@components/Header";
import {ref} from "vue";
import { useUser } from "./composables/user";
import { useAuth } from "./composables/auth";
import {useRouter} from "vue-router";
import AppTransition  from "@components/AppTransition";
import Loader from "@components/Loader"

export default {

  name: 'App',

  components: { Header, Loader, AppTransition },

  setup () {

    const appLoading = ref(true)
    const initApp = async() => {
    const router = useRouter()

      let {initUser, isFirstConnexion, currentPromotionId} = await useUser()
      const {cognitoIdFromToken} = useAuth()

      // reset history and force user to start by the homepage
      await router.replace('')

      let user = await initUser(cognitoIdFromToken())
      if(user) {
        // Stop here and display an error when no promotion is available
        if (!currentPromotionId.value) {
          return appLoading.value = false
        }

        if (isFirstConnexion.value) {
            // console.log('is first connexion', isFirstConnexion.value)
            router.push({name: 'FirstConnexion', params: {transition: 'fade'}})
        } else if(router.currentRoute.value.name == "FirstConnexion") {
            // console.log(router.currentRoute.value.name)
            router.push({name: 'Home', params: {transition: 'fade'}})
        }
      }
      
      return appLoading.value = false
    }

    let {cognitoIdFromToken} = useAuth()
    initApp(cognitoIdFromToken())
    return { appLoading}

  },
}
</script>

<style>
/* Loader Transition */

.loader-enter-from,
.loader-leave-to {
 opacity: 0
}

.loader-enter-active,
.loader-leave-active {
  transition: all .8s ease;
}
.loader-enter-to,
.loader-leave-from {
  opacity: 1
}
</style>
