<template>
  <svg width="230px" height="230px" viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path data-id="1" class="wheel__segment segment__1" d="M64.9484 218.537C80.4965 226.053 97.5378 229.971 114.807 230C132.077 230.029 149.131 226.168 164.704 218.704L115 115L64.9484 218.537Z" fill="#E8560E"/>
    <path data-id="2" class="wheel__segment segment__2" d="M164.707 218.702C180.28 211.238 193.972 200.362 204.767 186.882C215.561 173.401 223.181 157.663 227.06 140.835L115 115L164.707 218.702Z" fill="#A74004"/>
    <path data-id="3" class="wheel__segment segment__3" d="M227.06 140.836C230.94 124.008 230.98 106.522 227.177 89.6764C223.374 72.8307 215.826 57.0578 205.093 43.5285L115 115L227.06 140.836Z" fill="#9C3100"/>
    <path data-id="4" class="wheel__segment segment__4" d="M205.095 43.5303C194.362 30.0008 180.72 19.0621 165.182 11.5263C149.643 3.99053 132.607 0.0511213 115.337 0.000494165L115 115L205.095 43.5303Z" fill="#3C0D06"/>
    <path data-id="5" class="wheel__segment segment__5" d="M115.341 0.000506783C98.0719 -0.0507626 81.0126 3.78806 65.4297 11.232C49.8469 18.676 36.1407 29.534 25.3284 43L115 115L115.341 0.000506783Z" fill="#240200"/>
    <path data-id="6" class="wheel__segment segment__6" d="M25.3278 43.0007C14.5157 56.4668 6.87517 72.1951 2.97346 89.0181C-0.928223 105.841 -0.990952 123.327 2.78998 140.178L115 115L25.3278 43.0007Z" fill="#183C3B"/>
    <path data-id="7" class="wheel__segment segment__7" d="M2.78975 140.177C6.58128 157.075 14.1406 172.899 24.9035 186.468C35.6663 200.036 49.3547 210.998 64.947 218.536L115 115L2.78975 140.177Z" fill="#2A5A59"/>

    <path  class="wheel__text text__1" d="M115.359 212H117.087V199.256H115.557L112.011 202.19L113.019 203.396L115.359 201.236V212Z" fill="white"/>
    <path  class="wheel__text text__2" d="M188.726 178.752L193.935 172.223L192.753 171.28L188.757 176.289L188.206 170.485C188.055 168.914 187.786 167.318 186.477 166.274C184.648 164.814 182.585 165.495 181.216 167.212C179.846 168.928 179.633 170.899 181.178 172.57L182.472 171.207C181.664 170.286 181.612 169.139 182.387 168.169C183.139 167.226 184.361 166.934 185.346 167.72C186.162 168.371 186.414 169.47 186.511 170.445L187.291 177.607L188.726 178.752Z" fill="white"/>
    <path  class="wheel__text text__3" d="M208.51 98.0804C210.26 97.0908 210.732 95.3584 210.3 93.463C209.8 91.2693 208.145 89.5973 205.759 90.1413C204.232 90.4893 203.098 91.6708 203.191 93.2928L203.156 93.3008C202.499 92.1212 201.162 91.5212 199.864 91.8172C197.565 92.3413 196.881 94.3618 197.345 96.3976C197.721 98.0473 198.807 99.3319 200.535 99.6213L200.722 97.7694C199.778 97.5968 199.019 96.9391 198.811 96.0265C198.539 94.8331 199.037 93.778 200.353 93.478C201.581 93.198 202.441 93.6483 203.025 96.2105L204.499 95.8745C204.139 94.295 203.949 92.3261 206.02 91.8541C207.354 91.5501 208.518 92.4477 208.814 93.7464C209.114 95.0626 208.663 95.7562 207.562 96.4319L208.51 98.0804Z" fill="white"/>
    <path  class="wheel__text text__4" d="M156.683 27.1702L155.126 26.4198L153.907 28.9491L152.285 28.1674L151.629 29.5294L153.25 30.3111L149.592 37.8992L151.473 38.806L159.594 33.6493L160.36 32.0604L155.463 29.6996L156.683 27.1702ZM154.807 31.0615L158.341 32.7657L152.072 36.8169L152.039 36.8013L154.807 31.0615Z" fill="white"/>
    <path  class="wheel__text text__5" d="M75.7766 40.6144L81.9379 37.6439L79.1518 31.5336C78.5611 32.2979 77.6575 32.9934 77.0738 33.2748C75.4362 34.0643 73.8947 33.7684 73.0895 32.0984C72.386 30.6391 72.9613 29.2627 74.1936 28.6686C75.215 28.1761 76.1233 28.2777 77.0368 29.1362L78.3515 27.8429C77.0973 26.6092 75.3367 26.4389 73.7801 27.1893C71.5426 28.2681 70.4698 30.6437 71.5721 32.9299C72.6508 35.1674 74.8119 36.0438 76.9684 35.0042C77.3413 34.8244 78.1375 34.3206 78.3914 34.0183L79.7264 36.9116L75.073 39.1552L75.7766 40.6144Z" fill="white"/>
    <path  class="wheel__text text__6" d="M32.8701 98.3848L33.3221 96.4017L29.0715 92.1282C28.0501 91.1015 27.2144 90.3941 25.6876 90.0461C23.2657 89.4941 21.2462 91.0646 20.7142 93.3987C20.1822 95.7329 21.1813 97.9914 23.919 98.6154C26.6393 99.2354 28.3182 97.4581 28.7262 95.668C28.8423 95.159 28.8585 94.6827 28.7575 94.2351L28.7735 94.1649L32.8701 98.3848ZM25.3562 91.7429C26.8655 92.0869 27.7001 93.3664 27.3481 94.9108C26.996 96.4552 25.6895 97.2466 24.1802 96.9026C22.6709 96.5586 21.8364 95.2791 22.1884 93.7347C22.5404 92.1903 23.847 91.3989 25.3562 91.7429Z" fill="white"/>
    <path  class="wheel__text text__7" d="M37.6415 172.945L38.8537 174.465L50.8278 171.406L51.9816 170.486L46.9866 164.224L45.8046 165.167L49.6435 169.979L37.6415 172.945Z" fill="white"/>
  </svg>
</template>