import {computed} from "vue";
import {client as ApolloClient} from "../config/clients";
import {useUser} from "./user";
import {useModule} from "./module";

const {reactive} = require("vue");
const state = reactive({
    logs: [],
    modules: [],
    sequences: []
})

const logsType = [
    {id: "17dcc59f-54d0-491b-ad51-8196dbb7a46e", slug: "login"},
    {id: "6d43197a-b72d-460a-a816-1d77cb838801", slug: "video_end"},
    {id: "45172f4e-00a7-4b05-b74d-9aca4e6e1283", slug: "scenario_nodes"},
    {id: "f76cf9ba-1bf2-4f01-9b54-d6fa2b6d196a", slug: "scenario_end"},
    {id: "f2bc19d8-4797-46a0-9ec4-b54f4778ab75", slug: "pdf_view"},
    {id: "6c499447-1a69-4b8d-8ffd-0fb5eff147d0", slug: "sequence_end"},
    {id: "8183270d-d565-42d2-9ba2-bd73f55866b2", slug: "module_end"},
    {id: "327b85b7-e238-4dfa-8d86-482d6588272f", slug: "user_agent"},
]

const useLogs = () => {

    const logs = computed( () => {
        return state.logs
    })

    const getLogs = async (promotion_id, user_id) => {
        let response =  await ApolloClient.query({
            query: require('@gql/logs/get_user_logs.gql'),
            variables: {
                promotionId: promotion_id,
                userId: user_id,
                slugs : ["scenario_nodes", "scenario_end", "sequence_end", "pdf_view", "video_end", "module_end", "user_agent"],
                contain: {
                    promotion_id: promotion_id
                }
            }
        })
        state.logs = response.data.logs
        return
    }


    const initLogs = (logs) => {
        state.modules = logs
    }


    const getLogsSequence = (sequence_id) => {
       return logs.value.filter(l =>  l.data.sequence_id == sequence_id)
    }

    const getScenarioProgressLog = (sequenceId, scenarioId) => {
        return logs.value.find((log) => log.data_key === scenarioId && log.logType.slug === 'scenario_nodes' && log.data.sequence_id === sequenceId)
    }

    const getScenarioLastVisitedNode = (sequenceId, scenarioId) => {
        const scenarioProgressLog = getScenarioProgressLog(sequenceId, scenarioId)
        const nodes = scenarioProgressLog?.data?.results

        if (!nodes || nodes.length <= 0) {
            return null
        }

        return nodes[0]
    }

    // uuid module
    const hasFinishedModule = (module_id) => {
        if( logs.value.length == 0) {
            return false
        }
        return logs.value.find(l => l.data_key == module_id && l.logType.slug == 'module_end')
    }

    // uuid module
    const hasFinishedSequence = (sequence_id) => {
        if( logs.value.length == 0 ) {
            return false
        }
        return !!logs.value.find(l => l.data_key == sequence_id && l.logType.slug == "sequence_end")
    }

    // uuid module
    const getHightestForSequence = (sequence_id) => {
        if( logs.value.length == 0 ) {
            return false
        }

        let sequenceModule = getLogsSequence(sequence_id)

        let evaluationResults = sequenceModule.find(s => s.data.results.length != 0 )
        if(evaluationResults) {

            let maxScore = 0
            evaluationResults.data.results.filter(e => e.type == 'evaluation').forEach(r => {
                if( r.score > maxScore) maxScore = r.score
            })

            if(maxScore != 0) {
                return maxScore
            }
        }

        return null
    }

    const currentModuleUser = computed(() => {
       const {modules} = useModule()
       if(modules.value.length != 0) {
            let moduleAvailable = modules.value.filter(module => {
                if(module.available) return module
            })

           let lastModuleAvailable = moduleAvailable[moduleAvailable.length - 1]
           if(lastModuleAvailable) return lastModuleAvailable
           return  modules.value[modules.value.length - 1]

        }
        return true
    })

    const oldModulesCorrection = computed( () => {

        const {modules} = useModule()
        if(modules.value.length != 0) {

            let moduleAvailable = modules.value.filter(m => {
                if(m.available ) return m
            })

            let correction = []

            moduleAvailable.forEach((m) => {
                m.sequences.forEach( s => {
                    if(s.available && s.score >= 80) {
                        correction.push({
                            module: m,
                            sequence: s
                        })
                    }
                })
            })
            return correction

        }
        return true
    })

    const updateLogCache = (cache, { data: { insert_logs } }) => {
        const { currentPromotionId, user } = useUser()

        let query = {
            query: require('@gql/logs/get_user_logs.gql'),
            variables: {
                promotionId: currentPromotionId.value,
                userId: user.value.id,
                slugs : ["scenario_nodes", "scenario_end", "sequence_end", "pdf_view", "video_end", "module_end", "user_agent"],
                contain: {
                    promotion_id: currentPromotionId.value
                }
            }
        }

        const data = cache.readQuery(query)

        if (!data) return

        let newLogs = [...data.logs]

        // si on a déja un logs pour ce media, on update juste le log en question
        const newLog = insert_logs.returning[0]

        let oldLog = newLogs.find(l => l.data_key == newLog.data_key && l.logType.slug == newLog.logType.slug && (l.data === newLog.data || (l.data.sequence_id && l.data.sequence_id == newLog.data.sequence_id)))

        if (oldLog) {
            newLogs[newLogs.indexOf(oldLog)] = insert_logs.returning[0]
        } else {
            newLogs.push(insert_logs.returning[0])
        }
        
        cache.writeQuery({
            query: query.query,
            variables: query.variables,
            data: {
                logs: newLogs
            }
        })

        state.logs = newLogs
    }

    /*
    * Save log when a media is open/watched
     */
    const createLog = async (data_key_id, sequence_id, mutation_type, results, replaceResults = false) => {

        const {currentPromotionId, user} = useUser()
        // console.log("results",results)
        let logData = {
            user_id: user.value.id,
            log_type_id: logsType.find( l => l.slug == mutation_type).id,
            data_key: data_key_id,
            data: {
                sequence_id: sequence_id,
                promotion_id: (currentPromotionId ? currentPromotionId.value : null),
                results: results ? results : []
            }
        }

        // recuperer le log video_end si il existe déja
        let sequencedLog = (sequence_id ? getLogsSequence(sequence_id) : [])
        if(sequencedLog.length != 0) {
            let log = sequencedLog.find(l => l.data_key == data_key_id && l.logType.slug == mutation_type)
            if(log) {
                logData.id = log.id
                if(results && !replaceResults) {
                    logData.data.results = [
                        ...log.data.results,
                        ...results
                    ]
                }
            }
        }

        const response = await ApolloClient.mutate({
            mutation: require('@gql/logs/update_logs.gql'),
            variables: {log: logData},
            update: updateLogCache,
        })

        return response
    }

    /*
     * Save user agent log
     */
    const addUserAgentLog = async () => {
        const userAgent = navigator.userAgent

        // Check if this user agent has already been registered for this user
        const existingLog = logs.value.find((log) => log.data === userAgent)

        if (existingLog) {
            return
        }

        const { user } = useUser()

        const logData = {
            user_id: user.value.id,
            log_type_id: logsType.find( l => l.slug == 'user_agent').id,
            data: userAgent
        }

        const response = await ApolloClient.mutate({
            mutation: require('@gql/logs/update_logs.gql'),
            variables: { log: logData },
            update: updateLogCache,
        })

        return response
    }

    return {
        logs,
        getLogs,
        currentModuleUser,
        oldModulesCorrection,
        getLogsSequence,
        getScenarioProgressLog,
        getScenarioLastVisitedNode,
        getHightestForSequence,
        initLogs,
        hasFinishedModule,
        hasFinishedSequence,
        createLog,
        addUserAgentLog,
    }

}

export {useLogs}