import {computed, reactive } from "vue";

const state = reactive({
    quiz: null,
    showResponse: false
})

const useQuizDragDrop = () => {

    const quiz = computed( () => state.quiz )
    const showResponse = computed( () => state.showResponse)
    const setQuizDragDrop = (quiz) => {
        state.showResponse = false
        state.quiz = quiz
    }

    /**
     * Selectionner une option
     * @param option
     */
    const selectOption = (option) => {

        if(showResponse.value) return

        // je remet tout à 0
        quiz.value.options.map(op => op.isSelected = false)

        // Si la l'option à déjà une réponse associé
        let response = quiz.value.response.find(re => re.option_id == option.id)
        if(response) {
            removeResponse( quiz.value.sentences.find(se => se.id == response.sentence_id))
        }

        // j'active le bon
        let selected = quiz.value.options.find(op => op.id == option.id)
        if(selected) selected.isSelected  = true
    }

    /**
     * If item is actually selected
     * @type {ComputedRef<*>}
     */
    const selectedItem = computed( () => {
        return quiz.value.options.find(option => option.isSelected)
    })

    /**
     * If item is already in repsonse
     * @param option
     * @return boolean
     */
    const optionInResponse = (option) => {
         return quiz.value.response.find(r => r.option_id == option.id)
    }

    /**
     * Retirer la response pour une sentence donnée
     * @param sentence
     */
    const removeResponse = (sentence) => {
        let response =  quiz.value.response.find(re => re.sentence_id == sentence.id)
        const index = quiz.value.response.indexOf(response);
        if (index > -1) {
            quiz.value.response.splice(index, 1);
        }
    }

    /**
     * Assigner le selected item à une sentence
     * @param sentence
     */
    const assignSeletectedItemToSentence = (sentence) => {
        quiz.value.response.push({sentence_id: sentence.id, option_id: selectedItem.value.id})
    }

    /**
     * Verification pour assigner une option à une sentence
     * Créer la réponse
     * @param sentence
     */
    const assignSelectedItem = (sentence) => {
        if(showResponse.value) return

        // au click, si il n'y a pas de selected item et une reponse sur cette sentence, on remove la réponse
        if( ! selectedItem.value  && selectedResponse(sentence) ) {
            removeResponse(sentence)
        }
        // si pas de selected item, et pas de réponse sur cette sentence on fait rien
        else if (! selectedItem.value ) {
            quiz.value.options.map(op => op.isSelected = false)
        }
        // si on a une selected item et déjà une réponse sur la sentence
        else if ( selectedItem.value  && selectedResponse(sentence)) {
            // on enléve la réponse précedente
            removeResponse(sentence)
            assignSeletectedItemToSentence(sentence)
        }
        // enfin si une selected value et pas d'option, on assign la réponse simplement
        else {
            assignSeletectedItemToSentence(sentence)
        }

        // et à la fin, a chaque fois on enléve la selection sur les options
        quiz.value.options.map(op => op.isSelected = false)
    }

    /**
     * Récuprer la réponse pour une sentence donné
     * @param sentence
     * @returns response
     */
    const selectedResponse = (sentence) => {

        // recuperer la réponse de la sentence
        let response = quiz.value.response.find(re => re.sentence_id == sentence.id)
        if(response) {
            return quiz.value.options.find(op => op.id == response.option_id)
        }

    }

    const validateResponse = () => {
        quiz.value.sentences.forEach(sen => {
            // get option repsonse for actual sentence
            let optionResponse = quiz.value.response.find(re => re.sentence_id == sen.id)

            let responseValue = quiz.value.options.find(opt => opt.id == optionResponse.option_id)

            if(responseValue.value != sen.answer) {
                sen.error = true
            }
        })
    }

    /**
     *
     * @type {ComputedRef<boolean>}
     */
    const canSubmit = computed( () => {
        if(quiz.value.response.length == quiz.value.sentences.length) return true
        return false
    })

    const submit = () => {
        if(canSubmit.value) {
            state.showResponse = true
           // const {saveResult} = useScenario(quiz)
            validateResponse()
        }
    }

    return {
        quiz,
        setQuizDragDrop,
        selectOption,
        selectedItem,
        assignSelectedItem,
        selectedResponse,
        optionInResponse,
        canSubmit,
        submit,
        showResponse
    }
}

export {useQuizDragDrop}