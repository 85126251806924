<template>
  <svg  width="230px" height="230px"  viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path  data-id="1" class="wheel__segment segment__1" d="M47.4047 208.037C67.0522 222.312 90.7144 230 115 230C139.286 230 162.948 222.312 182.595 208.037L115 115L47.4047 208.037Z" fill="#E8560E"/>
    <path  data-id="2" class="wheel__segment segment__2" d="M182.594 208.038C202.242 193.764 216.866 173.636 224.371 150.539C231.876 127.442 231.876 102.562 224.372 79.4648L115 115L182.594 208.038Z" fill="#A74004"/>
    <path  data-id="3" class="wheel__segment segment__3" d="M224.372 79.466C216.868 56.3688 202.245 36.2401 182.598 21.9649C162.951 7.68964 139.289 0.00067141 115.003 4.3943e-08L115 115L224.372 79.466Z" fill="#9C3100"/>
    <path  data-id="4" class="wheel__segment segment__4" d="M115 0C90.7144 2.89603e-07 67.0522 7.68831 47.4047 21.963C27.7572 36.2377 13.1332 56.366 5.62851 79.463L115 115V0Z" fill="#3C0D06"/>
    <path  data-id="5" class="wheel__segment segment__5" d="M5.62766 79.4657C-1.87646 102.563 -1.87587 127.443 5.62935 150.54C13.1346 173.636 27.7591 193.764 47.4069 208.039L115 115L5.62766 79.4657Z" fill="#240200"/>

    <path class="wheel__text text__1" d="M115.359 212H117.087V199.256H115.557L112.011 202.19L113.019 203.396L115.359 201.236V212Z" fill="white"/>
    <path class="wheel__text text__2" d="M206.463 148.943L209.044 141L207.606 140.533L205.626 146.627L203.07 141.387C202.376 139.969 201.563 138.569 199.971 138.052C197.745 137.329 196.054 138.691 195.375 140.779C194.697 142.868 195.189 144.788 197.223 145.809L197.956 144.078C196.876 143.5 196.424 142.445 196.808 141.264C197.18 140.117 198.222 139.414 199.421 139.804C200.414 140.126 201.035 141.067 201.469 141.946L204.717 148.376L206.463 148.943Z" fill="white"/>
    <path class="wheel__text text__3" d="M174.389 41.046C175.055 39.1493 174.271 37.5338 172.698 36.3911C170.878 35.0686 168.531 34.899 167.092 36.8795C166.172 38.1464 166.096 39.7822 167.233 40.9422L167.212 40.9714C165.941 40.5154 164.54 40.9435 163.757 42.0211C162.371 43.9288 163.186 45.9003 164.875 47.1275C166.244 48.1221 167.908 48.3741 169.399 47.4549L168.321 45.9375C167.497 46.4289 166.492 46.4333 165.735 45.8832C164.745 45.1637 164.425 44.0416 165.219 42.9495C165.959 41.9301 166.903 41.7033 169.029 43.248L169.918 42.0248C168.607 41.0726 167.168 39.7155 168.416 37.9971C169.22 36.8904 170.688 36.7997 171.766 37.5826C172.858 38.3761 172.975 39.1953 172.59 40.4288L174.389 41.046Z" fill="white"/>
    <path class="wheel__text text__4" d="M57.6379 37.1408L56.2399 38.1565L57.8904 40.4282L56.4342 41.4862L57.3229 42.7094L58.7792 41.6514L63.7307 48.4666L65.4199 47.2393L64.7231 37.6444L63.6862 36.2173L59.2884 39.4125L57.6379 37.1408ZM60.1771 40.6357L63.3517 38.3293L63.9516 45.7696L63.9225 45.7908L60.1771 40.6357Z" fill="white"/>
    <path class="wheel__text text__5" d="M36.4424 144.343L34.3287 137.838L27.8988 139.776C28.5767 140.464 29.1441 141.453 29.3443 142.07C29.9061 143.799 29.4053 145.286 27.642 145.859C26.1013 146.36 24.8149 145.604 24.3922 144.303C24.0417 143.225 24.2648 142.338 25.2384 141.549L24.134 140.072C22.7426 141.148 22.3367 142.87 22.8707 144.514C23.6383 146.876 25.8478 148.259 28.2616 147.475C30.624 146.707 31.7835 144.684 31.0437 142.407C30.9158 142.013 30.5238 141.156 30.2585 140.864L33.3053 139.931L34.9017 144.844L36.4424 144.343Z" fill="white"/>
  </svg>
</template>

<script>
export default {}
</script>