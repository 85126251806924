import Flickity from "flickity";

import {computed, reactive}  from "vue"
import {client as ApolloClient} from "../config/clients";
import {useLogs} from "@composables/logs"
import {useUser} from "@composables/user";
import {getTransitionEndEventName} from "../helpers";

const state = reactive({
    currentModuleId: null,
    currentModule: null,
    currentSequenceId: null,
    slider: null,
    chart: null,
    modules: []
})


const useModule = () => {

    /**
     * get currentmodule object
     * @type {ComputedRef<null|string|string|*>}
     * @return Object
     */
    const currentModuleId = computed( () => state.currentModuleId )

    const setCurrentModuleId = (module_id) => state.currentModuleId = module_id

    const currentModule = computed( () => {
        if(currentModuleId.value) {
            return state.modules.find(m => m.id == currentModuleId.value)
        }
        return null
    })

    const canAccessCurrentModule = computed( () => {
        if(currentModule.value){
            return currentModule.value.available
        }
        return false
    })

    const hasModules = computed ( () => {
        return modules.value.length && modules.value.length > 0
    })

    /**
     * get all modules in state
     * @type {ComputedRef<[UnwrapRefSimple<{name: string, index: number, id: string, sequences: [{score: string, name: string, id: string}, {score: string, name: string, id: string}]}>, UnwrapRefSimple<{name: string, index: number, id: string, sequences: [{score: string, name: string, id: string}, {score: string, name: string, id: string}, {score: string, name: string, id: string}, {score: string, name: string, id: string}]}>]>}
     * @return Array
     */
    const modules = computed( () => state.modules)

    const currentSequenceId = computed( () => {
        return state.currentSequenceId
    })

    const setCurrentSequenceId = (sequence_id)=> {
        state.currentSequenceId = sequence_id
    }

    const currentSequence = computed( () => {
        if(currentModule.value) {
            return currentModule.value.sequences.find(s => s.id == currentSequenceId.value)
        }
        return "dddd'"
    })
    /**
     * Get modules by promotion of the ciurrent user
     * @param $promotion_id
     * @returns Boolean
     */
    const initModules = async (promotion_id, user_id) => {
        // console.log("MODULE INITEDD")
        let response =  await ApolloClient.query({
            query: require('@gql/modules/get_user_modules.gql'),
            variables: {
                promotionId: promotion_id,
                userId: user_id
            }
        })

        // Si on a des modules alors on les format
        if(response.data.module.length != 0) {
            state.modules = prepareModules(response.data.module)
        }

        return true
    }

    const prepareModules = (responseModules) => {
        let modules = []
        responseModules.forEach( (module, indexModule) => {

            modules.push({
                id: module.id,
                title: module.title,
                order: module.order,
                available: false,
                sequences: []
            })

            module.sequences.forEach( sequence => {
                modules[indexModule].sequences.push({
                    id: sequence.id,
                    title: sequence.title,
                    available: false,
                })
            })

        })

        // On init les logs
        const { hasFinishedModule, hasFinishedSequence, getHightestForSequence} = useLogs()


        // et on unlock les modules auquels on a accés
        modules.forEach( (module, index) => {
            // Si on est pas sur le premier module
            // et qu'on a terminé le precedent
            if(hasFinishedModule(module.id)) {
                // on active le module
                module.available = true
                // console.log('hdshdishdhsidh')
                if(modules[index + 1]) {
                    modules[index + 1].available = true
                }
            }

        })

        // Si aucuns module d'accessible, alors on active le premier pcq c'est logique
        if( ! modules.find(m => m.available)) {
            modules[0].available = true
        }

        // next, piyr chaques modules dispo, on regarde les séquences dispo
        modules.filter(m => m.available).forEach( (module) => {

            module.sequences.forEach( (sequence, index) => {

                let score = getHightestForSequence(sequence.id)

                if (score) {
                    sequence.available = true
                    sequence.score = score
                }

                sequence.finished = hasFinishedSequence(sequence.id)

                if (sequence.finished) {
                    sequence.available = true
                    
                    if(module.sequences[index + 1]) {
                        module.sequences[index + 1].available = true
                    }
                }
            })

            // idem, si aucun de dispo, on active le premier
            if( ! module.sequences.find(s => s.available)) {
                module.sequences[0].available = true
            }
        })

        return modules
    }

    /**
     *
     * @param sequence_id
     * @returns {Promise<unknown>}
     */
    const getSequence = async (sequence_id) => {
        let response = await ApolloClient.query( {
            query: require('@gql/sequences/get_sequence.gql'),
            variables: {
                sequenceId: sequence_id,
            }
        })

        if(! response.data.sequence[0]) {
            return false
        }


        let module =  modules.value.find(module => module.id == response.data.sequence[0].module_id)
        let fetchedSequence = JSON.parse(JSON.stringify(response.data.sequence[0]));


        const {getLogsSequence} = useLogs()
        let logsSequence = getLogsSequence(sequence_id)

        fetchedSequence.medias.filter(m =>  m.media.mediaType != "file_image")

        // render tous les medias dispo les medias
        fetchedSequence.medias.forEach( (media) => {
            media.media.available = true
            media.media.current = true

            if(media.media.mediaType.slug == "file_image") {
                media.media.current = false
            }
            // regarder dans les logs si j'ai le media id sur la sequence
            if(logsSequence.find( l => l.data_key == media.media.id) ) {
                // et dans ce cas enlever le current
                media.media.current = false
            }
        })


        // si on a tous les medias dispo, et pas en current on init les scenario
        if(
            // aucuns media non dispo
            // ! fetchedSequence.medias.find( m =>  ! m.media.available) &&
            // et aucun media en current
            ! fetchedSequence.medias.find( m =>  m.media.current)
        ){
            // si on a des scenar de dispo
            fetchedSequence.scenarios.forEach( (scenario, index) => {
                const scenarioLogs = logsSequence.filter( l => l.data_key === scenario.scenario.id)

                const scenarioProgressLog = scenarioLogs.find( l => l.logType.slug === "scenario_nodes")
                
                if (scenarioProgressLog) {
                    const nodes = scenarioProgressLog.data?.results

                    if (nodes && nodes.length > 0) {
                        scenario.scenario.lastVisitedNode = nodes[0]
                    }
                }

                const scenarioScoreLog = scenarioLogs.find( l => l.logType.slug === "scenario_end")

                if(scenarioScoreLog) {
                    scenario.scenario.finished = true
                    scenario.scenario.available = true
                    scenario.scenario.current = false

                    let data = scenarioScoreLog.data

                    if( data && data.results.length != 0 ) {
                       let maxScore = 0
                        data.results.forEach(r => {
                            if( r.score > maxScore) maxScore = r.score
                        })
                        if(maxScore != 0) {
                            scenario.scenario.score = maxScore
                        }
                    }

                    // et on active le prochain
                    if(fetchedSequence.scenarios[index + 1]) {
                        fetchedSequence.scenarios[index + 1].scenario.available = true
                        fetchedSequence.scenarios[index + 1].scenario.current = true
                    }
                }
            })

            // si on a pas de scenar dans les logs, on démare avec le premier
            if(  ! fetchedSequence.scenarios.find( s => s.scenario.available) ){
                fetchedSequence.scenarios[0].scenario.available = true
                fetchedSequence.scenarios[0].scenario.current = true
            }

        }


        // push the sequene into the sequences module array
        let sequence = module.sequences.find( s => s.id == sequence_id)
        if(sequence) {
            let moduleIndex = state.modules.indexOf(state.modules.find(m => m.id == module.id))
            let sequenceIndex = state.modules[moduleIndex].sequences.indexOf(sequence)
            state.modules[moduleIndex].sequences[sequenceIndex] = {...sequence, ...fetchedSequence}
        }else {
            module.sequences.push(fetchedSequence)
        }
        return sequence
    }

    /**
     * Return flickity instance
     * @type {ComputedRef<*>}
     * @return Flickity
     */
    const slider = computed( () => state.slider)

    /**
     * get all sequence inside current module
     * @type {ComputedRef<null|*>}
     * @return Array
     */
    const modulesSequences = computed( () => {
        if( ! currentModuleId.value) return null
        return currentModule.value.sequences
    })

    /**
     * Retreive a module by one of his sequenceID
     * @param <uuid> id_sequence
     * @returns Object
     */
    const getModuleBySequenceId = (id_sequence) => {

        let module = modules.value.find( module => {
            if(module.sequences.find(s => s.id == id_sequence)) {
                return module
            }
        })
        //console.log(module)
        return module
    }



    /**
     * Init the slider selector on the homepage
     * @param elem
     */
    const initSelector = (elem) => {
        if( ! hasModules.value ) return null

        let flickity = new Flickity( elem, {
            // options
            adaptiveHeight: true,
            prevNextButtons: false,
            cellAlign: 'left',
            contain: true,
            wrapAround: true
        });

        state.slider = flickity



        // when the slider change, change the state to update all places
        flickity.on( 'change', function() {
            state.currentModuleId = null
            let id = flickity.selectedElement.dataset.idModule

            state.currentModuleId = id
            rotateWheel(currentModule.value.order + 1)

        });

        if( !  state.currentModuleId ) {
            state.currentModuleId = state.modules[0].id
        }else {
            slider.value.select( currentModule.value.order, false, true)
        }
    }

    const wheel = computed( () => {
        if(modules.value) {
            let countModule = modules.value.length
            return 'Wheel' + countModule
        }
        return "Wheel0"
    })

    const rotateWheel = (indexModule) => {

        let countModule = modules.value.length
        let wheel = document.querySelector('#wheel')
        let rotate = 0

        let middleIndexWheel = Math.round(countModule / 2)
        const transitionName = getTransitionEndEventName()

        const resetRotateValue = () => {
            wheel.classList.remove('duration-300', 'transition')
            wheel.removeEventListener(transitionName, resetRotateValue);
            // get the correct rotate
            rotate = (360 / countModule) * (indexModule - 1)
            wheel.style.transform = "rotate("+rotate+"deg)";
            setTimeout(function(){   wheel.classList.add('duration-300', 'transition');  wheel.dataset.currentRotate = rotate}, 50);
        }

        // si on est sur la derniere frame
        if(wheel.dataset.currentRotate == (360 / countModule) * (countModule - 1) ) {
            // et si on va sur la premiere moitié de la roue
            if(indexModule <= middleIndexWheel -1) {
                rotate = (360 / countModule) * (countModule + (indexModule - 1))
                wheel.addEventListener(transitionName, resetRotateValue);
            }else {
                rotate = (360 / countModule) * (indexModule - 1)
            }
        }

        // si on est sur la premiere
        else if(wheel.dataset.currentRotate == 0 ) {

            // et si on va sur la deuxiéme moitié de la roue
            if(indexModule <= middleIndexWheel) {
                rotate = (360 / countModule) * (indexModule - 1)
            }else {
                rotate = (-360) + ((360 / countModule) * (indexModule - 1))
                wheel.addEventListener(transitionName, resetRotateValue);
            }
        }

        // sinon c'est normal
        else {
            rotate = (360 / countModule) * (indexModule - 1)
        }

        wheel.style.transform = "rotate("+rotate+"deg)";
        wheel.dataset.currentRotate = rotate

    }

    const segmentClicked = (e) => {
        //console.log('segment cliked')
        let indexModule = e.target.dataset.id
        // rotateWheel(indexModule)
        slider.value.select(indexModule - 1)
    }

    const initWheel = () => {

        // SI on a pas de modules on fait rien
        if(! hasModules.value) return false

        let wheel = document.querySelector('#wheel')
        modules.value.forEach((module, index) => {

            let svg  = wheel.querySelector(".segment__"+ (index+1) )

            if( ! svg ) return

            //  todo: check if module is availbale
            //console.log(svg)
            if( ! module.available ) {
                svg.setAttribute("fill", "gray");
            }

            svg.addEventListener('click', segmentClicked)

        })

        let touchstartX, touchendX
        const handleGesture = () => {
            let currentIndex = currentModule.value.order + 1
            // console.log('currentindex', currentIndex)
            // console.log('lastINdex', state.modules[state.modules.length - 1].order + 1)
            let goIndex

            if (touchendX < touchstartX) {
                // console.log('Swiped Left');
                goIndex = currentIndex + 1
            }

            if (touchendX > touchstartX) {
                // console.log('Swiped Right');
                goIndex = currentIndex -1
            }

            if(goIndex == 0) goIndex = state.modules[state.modules.length - 1].order + 1
            if(goIndex > state.modules.length) goIndex = 1

            slider.value.select(goIndex - 1)
        }

        // add touch event
        let touchWheel = document.querySelector('#wheelTouch')
        touchWheel.addEventListener('touchstart', function (event) {
            touchstartX = event.changedTouches[0].screenX;
        }, false);

        touchWheel.addEventListener('touchend', function (event) {
            touchendX = event.changedTouches[0].screenX;
            handleGesture();
        }, false);
    }

    const markSequenceAsFinished = async (sequenceId) => {
      const {createLog, logs} = useLogs()
      const {currentPromotionId, user} = useUser()

      await createLog(sequenceId, sequenceId, 'sequence_end')

      // on recupére les id de toutes les sequences terminé
      let sequencesFinished = logs.value.map(l => {
        if (l.data_key && l.logType.slug == "sequence_end") {
          return l.data_key
        }
      }).filter(x => x)

      // if toutes les sequences sont terminé
      let hasFinishedModule = true

      // si on a pour chaque sequence de module, un seuqence_end provenant des logs, alors on a tout terminé
      if (!currentModule.value) return

      currentModule.value.sequences.forEach(s => {
        let sequenceId = s.id
        if (!sequencesFinished.includes(sequenceId)) {
          hasFinishedModule = false
        }
      })

      // et dans ce cas on passe le module en terminé
      if (hasFinishedModule) {
        await createLog(currentModule.value.id, null, 'module_end')
      }

      await initModules( currentPromotionId.value, user.value.id)
    }


    return {
        initModules,
        hasModules,
        currentModuleId,
        currentModule,
        currentSequence,
        setCurrentModuleId,
        setCurrentSequenceId,
        canAccessCurrentModule,
        getSequence,
        getModuleBySequenceId,
        modules,
        initSelector,
        slider,
        modulesSequences,
        wheel,
        initWheel,
        markSequenceAsFinished,
    }
}

export {useModule}