<template>
  <div class="w-full">

    <div class="bg-green-light2">

      <QuizQCM
          v-for="(item, index) in quiz" :key="index"
          :item="item"
          :showResponse="showResponse"
          @updated="(response) => item.responses = response"
      ></QuizQCM>

    </div>

    <div v-if="! scenarioIsEvaluation && ! showResponse" @click="submitQuiz" class="flex justify-center mx-auto items-center py-8 "
         :class="canSubmit ? 'text-orange-primary cursor-pointer' : 'opacity-50 text-green-light'">

      <div class="">{{ t('button.valider_reponses') }}</div>
      <div  class="flex-shrink-0 ml-4 border-2  rounded-full px-2 py-2" :class="canSubmit ? 'border-orange-primary' : 'opacity-50 border-green-light'">
        <div class="w-6">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>
      </div>
    </div>

  </div>


</template>


<script>

import QuizQCM from "@components/Quiz/QuizQCM";
import {useQuizQCM} from "@composables/quizQCM";
import {useScenario} from "@composables/scenario";
import {scrollTo} from "@/helpers";
import {watchEffect} from "vue";
import {useI18n} from "vue-i18n";
// import {scrollTo} from "@/helpers";

export default {

  props: {
    node: {
      type: Object,
      required: true
    }
  },

  components: {QuizQCM},

  setup(props) {
    const {t} = useI18n()
    const {formatQCMContent, setCurrentNodeValidity, setCurrentNodeType, saveNodeResults, scenarioIsEvaluation, setHasSubmitNode, setCurrentQuiz} = useScenario()
    const {setQuizQCM, quiz, showResponse, submit: validateQuiz, canSubmit} = useQuizQCM()

    setHasSubmitNode(false)
    let formatedQuiz = formatQCMContent.value
    setQuizQCM(formatedQuiz)
    setCurrentQuiz(formatedQuiz)
    setCurrentNodeValidity(false)
    setCurrentNodeType('quiz_simple')

    watchEffect( () => {
      if( canSubmit.value == true  && scenarioIsEvaluation.value) {
        saveNodeResults(props.node.id, 'qcm', quiz.value)
        setCurrentNodeValidity(true)
      }
    })

    const submitQuiz = () => {
      if( ! canSubmit.value ) return
      saveNodeResults(props.node.id, 'qcm', quiz.value)
      validateQuiz()
      setCurrentNodeValidity(true)
      scrollTo(document.documentElement, 0)
    }

    return {t, quiz, showResponse, canSubmit, submitQuiz, scenarioIsEvaluation}

  }
}
</script>