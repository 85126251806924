<template>
  <div class="w-full">

    <div class="bg-green-light2">
      <QuizOrder :item="quiz"></QuizOrder>
    </div>

    <div v-if="! showResponse" @click="submitQuiz"
         class="flex justify-center mx-auto items-center py-8 text-orange-primary cursor-pointer">

      <div class="" v-if="scenarioIsEvaluation && isLastNode">{{ t('button.voir_score') }}</div>
      <div class="" v-else-if="scenarioIsEvaluation ">{{ t('button.etape_suivante') }}</div>
      <div class="" v-else>{{ t('button.valider_reponses') }}</div>
      <div  class="flex-shrink-0 ml-4 border-2  rounded-full px-2 py-2 border-orange-primary">
        <div class="w-6">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>
      </div>
    </div>

  </div>

</template>


<script>
import {useQuizOrder} from "@composables/quizOrder";
import {useScenario} from "@composables/scenario";
import QuizOrder from "@components/Quiz/QuizOrder"
import {scrollTo} from "@/helpers";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";

export default {

  props: {
    node: {
      type: Object,
      required: true
    },
    scenarioId: {
      type: String,
      required: true
    },
    sequenceId: {
      type: String,
      required: true
    }
  },

  components : {QuizOrder},


  setup(props) {
    const {t} = useI18n()
    const {formatOrderContent, setCurrentNodeValidity, setCurrentNodeType, saveNodeResults, isLastNode, scenarioIsEvaluation, setHasSubmitNode, nextNode} = useScenario()
    const {setQuizOrder, quiz, showResponse, submit: validateQuiz} = useQuizOrder()

    setHasSubmitNode(false)
    setQuizOrder(formatOrderContent.value)
    setCurrentNodeValidity(false)
    setCurrentNodeType('reorder')

    const router = useRouter()
    const submitQuiz = () => {
      if(scenarioIsEvaluation.value) {
        validateQuiz(scenarioIsEvaluation.value)
        saveNodeResults(props.node.id, 'reorder',quiz.value)
        nextNode(props.sequenceId, props.scenarioId , router)
      }else {
        validateQuiz()
        saveNodeResults(props.node.id, 'reorder',quiz.value)
        setCurrentNodeValidity(true)
        scrollTo(document.documentElement, 0)
      }
    }

    return {t, quiz, showResponse, submitQuiz, scenarioIsEvaluation, isLastNode}

  }
}
</script>