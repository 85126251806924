<template>
  <svg width="230px" height="230px" viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path data-id="1" class="wheel__segment segment__1" d="M147.373 225.349C126.221 231.555 103.731 231.55 82.5808 225.336L115 115L147.373 225.349Z" fill="#E8560E"/>
    <path data-id="2" class="wheel__segment segment__2" d="M147.371 225.35C168.524 219.145 187.447 206.991 201.888 190.336L115 115L147.371 225.35Z" fill="#A74004"/>
    <path data-id="3" class="wheel__segment segment__3" d="M201.885 190.339C216.327 173.684 225.677 153.23 228.823 131.411L115 115L201.885 190.339Z" fill="#9C3100"/>
    <path data-id="4" class="wheel__segment segment__4" d="M228.825 131.4C231.968 109.581 228.775 87.3187 219.624 67.2637L115 115L228.825 131.4Z" fill="#3C0D06"/>
    <path data-id="5" class="wheel__segment segment__5" d="M219.623 67.2608C210.472 47.2061 195.75 30.204 177.21 18.2792L115 115L219.623 67.2608Z" fill="#240200"/>
    <path data-id="6" class="wheel__segment segment__6" d="M177.209 18.2784C158.669 6.35383 137.092 0.0091861 115.048 9.96349e-06L115 115L177.209 18.2784Z" fill="#183C3B"/>
    <path data-id="7" class="wheel__segment segment__7" d="M115.047 9.53451e-06C93.0029 -0.00896689 71.4209 6.3179 52.8709 18.2272L115 115L115.047 9.53451e-06Z" fill="#2A5A59"/>
    <path data-id="8" class="wheel__segment segment__8" d="M52.8717 18.2267C34.3216 30.1358 19.5852 47.1255 10.4173 67.1725L115 115L52.8717 18.2267Z" fill="#3D7F7F"/>
    <path data-id="9" class="wheel__segment segment__9" d="M10.4167 67.1738C1.24915 87.221 -1.96353 109.481 1.1613 131.302L115 115L10.4167 67.1738Z" fill="#9C3100"/>
    <path data-id="10" class="wheel__segment segment__10" d="M1.16226 131.309C4.28839 153.13 13.6203 173.593 28.0466 190.26L115 115L1.16226 131.309Z" fill="#3C0D06"/>
    <path data-id="11" class="wheel__segment segment__11" d="M28.0459 190.259C42.4842 206.941 61.4164 219.118 82.5843 225.337L115 115L28.0459 190.259Z" fill="#240200"/>

    <path class="wheel__text text__1" d="M115.359 212H117.087V199.256H115.557L112.011 202.19L113.019 203.396L115.359 201.236V212Z" fill="white"/>
    <path class="wheel__text text__2" d="M164.421 198.864L171.448 194.349L170.63 193.077L165.239 196.541L166.579 190.867C166.939 189.33 167.196 187.732 166.291 186.323C165.026 184.354 162.855 184.338 161.007 185.525C159.159 186.712 158.325 188.51 159.254 190.588L160.916 189.712C160.446 188.581 160.764 187.478 161.809 186.807C162.824 186.155 164.075 186.271 164.756 187.331C165.32 188.209 165.207 189.331 164.986 190.286L163.429 197.319L164.421 198.864Z" fill="white"/>
    <path class="wheel__text text__3" d="M199.665 158.051C201.656 158.327 203.084 157.238 203.892 155.47C204.827 153.424 204.528 151.09 202.301 150.073C200.877 149.422 199.259 149.672 198.347 151.017L198.315 151.002C198.509 149.666 197.812 148.377 196.6 147.824C194.456 146.844 192.685 148.033 191.817 149.932C191.114 151.471 191.197 153.152 192.393 154.431L193.667 153.073C193.022 152.363 192.818 151.379 193.207 150.528C193.716 149.415 194.752 148.879 195.98 149.44C197.126 149.964 197.536 150.844 196.443 153.234L197.819 153.862C198.492 152.389 199.537 150.709 201.468 151.592C202.713 152.161 203.093 153.581 202.539 154.793C201.978 156.02 201.198 156.297 199.913 156.165L199.665 158.051Z" fill="white"/>
    <path class="wheel__text text__4" d="M211.369 100.196L211.124 98.4851L208.345 98.8837L208.089 97.1019L206.592 97.3165L206.848 99.0983L198.509 100.294L198.806 102.361L207.615 106.226L209.361 105.975L208.59 100.594L211.369 100.196ZM207.093 100.809L207.65 104.693L200.791 101.749L200.786 101.713L207.093 100.809Z" fill="white"/>
    <path class="wheel__text text__5" d="M176.929 57.2275L181.406 62.3991L186.576 58.1138C185.684 57.7433 184.777 57.0527 184.353 56.5627C183.163 55.1882 183.046 53.6229 184.448 52.4095C185.672 51.3493 187.151 51.5451 188.047 52.5794C188.789 53.4368 188.928 54.3401 188.338 55.4461L189.93 56.3775C190.793 54.8445 190.498 53.1005 189.367 51.794C187.741 49.9159 185.168 49.5008 183.249 51.1619C181.371 52.7876 181.089 55.1026 182.656 56.9126C182.927 57.2257 183.621 57.8627 183.979 58.0288L181.535 60.0732L178.154 56.1673L176.929 57.2275Z" fill="white"/>
    <path class="wheel__text text__6" d="M137.214 33.5613L139.165 34.1356L143.694 30.1585C144.783 29.2029 145.54 28.4127 145.983 26.9104C146.684 24.5275 145.242 22.4144 142.945 21.7384C140.649 21.0625 138.333 21.9194 137.54 24.6132C136.752 27.2897 138.422 29.0757 140.183 29.5941C140.684 29.7415 141.158 29.7873 141.611 29.7143L141.68 29.7346L137.214 33.5613ZM144.31 26.4743C143.873 27.9594 142.544 28.7128 141.024 28.2656C139.505 27.8184 138.796 26.4652 139.233 24.9802C139.67 23.4952 140.999 22.7417 142.518 23.1889C144.038 23.6362 144.747 24.9893 144.31 26.4743Z" fill="white"/>
    <path class="wheel__text text__7" d="M91.2356 21.0274L89.3707 21.5763L87.6826 33.819L88.0994 35.2349L95.7835 32.9734L95.3566 31.5229L89.4511 33.261L91.2356 21.0274Z" fill="white"/>
    <path class="wheel__text text__8" d="M41.9948 51.3766C40.4751 53.1323 40.3758 55.4746 42.1995 57.0532C43.3971 58.0899 44.9514 58.0545 46.1443 57.254L46.1852 57.2893C45.7513 58.1756 45.7893 59.5893 47.1231 60.7438C48.5793 62.0043 50.6493 61.5107 51.9569 60C53.2646 58.4894 53.4564 56.37 52.0002 55.1095C50.6665 53.955 49.2619 54.12 48.447 54.6763L48.4062 54.641C49.0275 53.3457 48.8398 51.8024 47.6421 50.7657C45.8185 49.1871 43.5145 49.621 41.9948 51.3766ZM50.6788 56.2511C51.577 57.0286 51.614 58.251 50.8954 59.0811C50.1179 59.9794 48.9481 60.0381 48.0635 59.2724C47.2741 58.5891 46.9751 57.4494 47.8469 56.4423C48.6833 55.476 49.9302 55.6031 50.6788 56.2511ZM43.138 52.3662C43.998 51.3727 45.3816 51.0944 46.5112 52.0722C47.5319 52.9557 47.6089 54.3794 46.7037 55.4527C45.8065 56.4616 44.5009 56.7599 43.3304 55.7467C42.2825 54.8396 42.1955 53.455 43.138 52.3662Z" fill="white"/>
    <path class="wheel__text text__9" d="M19.7884 99.0969L19.4997 101.11L24.0848 105.023C25.1865 105.963 26.0771 106.599 27.6273 106.822C30.0861 107.174 31.9708 105.444 32.3106 103.075C32.6504 100.705 31.4703 98.5353 28.6908 98.1368C25.929 97.7408 24.4007 99.6492 24.1401 101.467C24.066 101.983 24.0886 102.459 24.2259 102.897L24.2157 102.968L19.7884 99.0969ZM27.8191 105.104C26.2868 104.884 25.3506 103.677 25.5754 102.109C25.8003 100.541 27.0379 99.6454 28.5702 99.8652C30.1026 100.085 31.0387 101.292 30.8139 102.86C30.589 104.428 29.3514 105.323 27.8191 105.104Z" fill="white"/>
    <path class="wheel__text text__10" d="M25.1388 150.641L25.857 152.213L37.448 146.916L36.8121 145.524L32.6696 143.518L31.9917 144.936L34.9289 146.167L25.1388 150.641ZM33.3259 153.226C30.8865 154.341 27.6556 156.668 29.1594 159.959C30.6632 163.249 34.5376 162.33 36.9769 161.215C39.5145 160.055 42.6472 157.773 41.1433 154.482C39.6395 151.192 35.8634 152.066 33.3259 153.226ZM34.0441 154.797C35.6485 154.064 38.7656 152.917 39.7681 155.111C40.7707 157.304 37.8631 158.91 36.2587 159.643C34.7525 160.332 31.5372 161.524 30.5346 159.33C29.5321 157.137 32.5379 155.486 34.0441 154.797Z" fill="white"/>
    <path class="wheel__text text__11" d="M58.7423 193.829L60.1961 194.763L67.0847 184.041L65.7974 183.214L61.2282 183.766L61.4243 185.325L64.5606 184.773L58.7423 193.829ZM67.1572 199.235L68.611 200.169L75.4995 189.447L74.2123 188.62L69.643 189.172L69.8392 190.732L72.9755 190.179L67.1572 199.235Z" fill="white"/>
  </svg>
</template>