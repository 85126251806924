import { createWebHistory, createRouter } from 'vue-router';
import {useApp} from "@composables/app"
import Home from '@pages/Home.vue';
import FirstConnexion from '@pages/FirstConnexion.vue';
import Sequence from '@pages/Sequence.vue';
import Score from '@pages/Score.vue';

import Scenario from '@pages/Scenario.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/first-connexion',
        name: 'FirstConnexion',
        component: FirstConnexion
    },
    {
        path: '/:id',
        name: 'Sequence',
        component: Sequence,
    },
    {
        path: '/:sequence_id/:scenario_id',
        name: 'Scenario',
        component: Scenario,
    },
    {
        path: '/:sequence_id/:scenario_id/evaluation',
        name: 'Evaluation',
        component: Scenario,
    },
    {
        path: '/:sequence_id/:scenario_id/correction',
        name: 'Correction',
        component: Scenario,
    },
    {
        path: '/:sequence_id/:scenario_id/score',
        name: 'Score',
        component: Score,
    },

];


const router = createRouter({
    history: createWebHistory(),
    routes,
});


const {setTransition } = useApp()


router.beforeEach((to, from, next, ) => {
    if(to.params.transition) {
        setTransition(to.params.transition)
    }else {
        // Pas ouf, mais on defini les scenario possible étant le peu de routes
        if(
            from.name == "Sequence" && to.name == "Home"
            ||  from.name == "Scenario" && to.name == "Sequence"
            ||  from.name == "Evaluation" && to.name == "Sequence"
            ||  from.name == "Scenario" && to.name == "Home"
            ||  from.name == "Score" && to.name == "Home"
            ||  from.name == "Score" && to.name == "Scenario"
            ||  from.name == "Score" && to.name == "Sequence"
        ) {
            setTransition('left-right')
        } else {
            setTransition('right-left')
        }

    }
   next()
})

export default router;