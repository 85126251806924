<template>
  <div class="w-full bg-green-primary top-header overflow-hidden z-50 relative" :class="{'headerUser':headerUser}">
    <transition name="fade" mode="out-in">
        <div v-if=" ! headerUser" class="py-10 flex justify-center">
          <div class="w-full max-w-sm mx-auto flex justify-center">
            <LogoSVG />
          </div>
        </div>
        <div v-else class="flex justify-center">
          <div class="flex fixed top-0 w-full bg-green-primary py-4 px-4">
            <div @click="goHome" class="w-full text-white font-bold">
              EDIAD
            </div>
            <div @click="toggleMenu" class="ml-auto text-white">
              <button  class="hamburger hamburger--boring"  :class="{'is-active': menuIsOpen }" type="button">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
              </button>
            </div>
          </div>
        </div>
    </transition>
    <transition name="menu">
        <UserMenu v-if="menuIsOpen" />
    </transition>


  </div>
</template>

<script>

import {computed} from "vue";

import {useApp} from "@composables/app"
import {useUser} from "@composables/user"

import LogoSVG from "@components/LogoSVG"
import UserMenu from "@components/UserMenu";
import {useRouter} from "vue-router";

export default {

  components: {UserMenu, LogoSVG},

  setup() {

    const {
      toggleMenu,
      menuIsOpen
    } = useApp()

    const router = useRouter()


    const {user, isFirstConnexion} = useUser()

    const headerUser = computed( () => {
      return  ! isFirstConnexion.value
    })

    const goHome = () => {
      return router.push({name: 'Home', params: {transition: 'fade'}})
    }

    return {
      headerUser,
      user,
      toggleMenu,
      menuIsOpen,
      goHome
    }
  }
}
</script>


<style lang="scss" scoped>
.top-header {
  min-height: 56px;
  max-height: 180px;
  transition: max-height .8s;
  &.headerUser {
    max-height: 56px;
  }
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .8s ease;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}


.menu-enter-from,
.menu-leave-to {
  @apply translate-x-0;
}
.menu-enter-active,
.menu-leave-active {
  transition: all 0.5s ease;
}

.menu-enter-from,
.menu-leave-to {

  transform: translatex(100%);
}

</style>