<template>
  <div @click="openDocument" class="rounded-lg text-sm font-bold px-5 py-4 my-4 flex items-center" style="min-height: 65px"
      :class="[
          current
            ? 'text-white font-bold bg-orange-primary '
            : disabled ? 'text-green-light bg-green-light1 ' : 'text-green-dark border border-green-light bg-white',
          {'cursor-pointer' : canOpenDocument}
      ]"
  >
<!--  // 'text-green-dark border border-green-light bg-white',-->
    <div class="flex items-center w-full">

      <div  v-if="!disabled && !current" class="w-6 flex-shrink-0 text-green-light" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
      </div>

      <div class="" :class="[current ? 'font-bold' : 'font-light', {'mx-4' : !disabled && !current}]">
        <slot/>
      </div>

      <div  v-if="!disabled && !current" class="flex-shrink-0 ml-auto bg-green-light2 rounded px-3 py-1 text-white">
        <div class="w-6">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </div>

      <div v-else-if="current" class="flex-shrink-0 ml-auto bg-orange-light rounded px-3 py-1 text-white">
        <div class="w-6">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import {computed} from "vue";

export default {
  emits: ['mediaUpdated'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'document'
    },
    path: {
      type: String,
      required: true
    },
    mediaId: {
      type: String,
      required: true
    }
  },
  setup(props, context) {

      const openDocument = () => {

        if(canOpenDocument.value) {
            window.open(process.env.VUE_APP_ASSETS_URL + props.path)
            context.emit('mediaUpdated', props.mediaId, 'pdf_view')
        }
      }

      const canOpenDocument = computed( () => {
        if(props.disabled) return false
        return true
      })

      return {openDocument,canOpenDocument}
  }
}
</script>