<template>
  <div class="sk-chase" :style="cssVars">
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  props: {
    color: {
      type: String,
      default: "orange"
    }
  },

  setup(props) {

    let cssVars = computed( () => {
        return {
          '--bg-color': props.color,
        }
    })

    return {cssVars}
  }
}

</script>

<style lang="scss" scoped>
  .sk-chase {
    .sk-chase-dot:before {
      background-color: var(--bg-color);
    }
  }

</style>