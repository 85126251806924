<template>
  <div class="mt-10 " >
   <transition name="fade" mode="out-in">


      <div v-if="loading">
        <div class="flex justify-center py-20">
          <Loader />
        </div>
      </div>

     <div v-else>

       <!-- <Step1  v-if="currentStep == 1"></Step1> -->
       <Step2  v-if="currentStep == 2"></Step2>
       <Step3  v-else-if="currentStep == 3 && ! hasInfoUser"></Step3>


       <div v-if="!loading" class="my-10 flex justify-center" :class="{'opacity-50': ! canGoNext}">
         <a  href="#"  @click.prevent="nextStep" type="submit" class="btn btn-icon flex justify-center items-center">
           <div v-if="currentStep == 3">{{ t('premiere_connexion.form.bouton_valider') }}</div>
           <div v-else-if="currentStep == 2 && hasInfoUser">{{ t('premiere_connexion.form.bouton_valider') }}</div>
           <div v-else>{{ t('premiere_connexion.button') }}</div>
           <svg  class="icon ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
           </svg>
         </a>
       </div>

     </div>


   </transition>
  </div>


</template>

<script>
// import Step1 from "@components/FirstConnexion/Step1"
import Step2 from "@components/FirstConnexion/Step2"
import Step3 from "@components/FirstConnexion/Step3"
import Loader from "@components/Loader"
import {useI18n} from "vue-i18n";
import {useFirstConnexion} from "@composables/firstConnexion"
import {watchEffect} from "vue";
import {useRouter} from "vue-router";
import {sleep} from "../helpers";

export default {

  components: {Step2, Step3, Loader},

  setup() {
    const router = useRouter()

    const {t} = useI18n()
    const {
      canGoNext,
      currentStep,
      nextStep,
      loading,
      onResult,
      hasInfoUser
    } = useFirstConnexion()

    // console.log('hasInfo', hasInfoUser.value);
    watchEffect(() => {
      if(onResult.value) {
          sleep(1200).then( () => {
            router.push({name: 'Home' })
          })
      }
    })

    return {loading, nextStep, currentStep, canGoNext, hasInfoUser, t}
  }

}
</script>