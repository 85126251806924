<template>
  <teleport to="body">

    <div v-if="avatarModalIsOpen" style="z-index: 100" id="avatarModal" class="fixed top-0 bottom-0 left-0 right-0 w-full h-full bg-white overflow-y-scroll">
      <div class="px-6">
        <div @click="toggleAvatarModal" class="absolute top-0 right-0 text-green-light w-8 h-8 mt-2 mr-2 cursor-pointer hover:text-green-primary">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="max-w-sm mx-auto text-lg text-green-dark text-center leading-relaxed mt-10">
         <div>{{ t('menu.selectionner_avatar') }}</div>
        </div>

        <div class="max-w-sm mx-auto text-green-dark text-center flex flex-wrap justify-between my-10">

          <div v-for="(avatar, index) in avatarList" :key="index"
               @click.prevent="setAvatar(index+1)" class="w-1/2 xs:w-1/3 rounded-full overflow-hidden flex items-center justify-center mb-6">
            <div class="h-24 w-24 rounded-full overflow-hidden flex-shrink-0"
                 :class="[{'border-4 border-orange-primary': selectedAvatar == index + 1}, 'bg-' + avatar.color ]">
              <img :src="require(`@assets/${avatar.path}`)">
            </div>
          </div>

        </div>

        <div  @click="saveAvatar"
             class="flex justify-center mx-auto items-center py-8 "
             :class="canSaveAvatar ? 'text-orange-primary cursor-pointer' : 'opacity-50 text-green-light'">

          <div  class="">{{ t('menu.valider_avatar') }}</div>

          <div  class="flex-shrink-0 ml-4 border-2  rounded-full px-2 py-2" :class="canSaveAvatar ? 'border-orange-primary' : 'opacity-50 border-green-light'">
            <div class="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </div>
          </div>

        </div>

      </div>

    </div>
  </teleport>
</template>

<script>
import {useUser, useAvatarList} from "@composables/user";
import {useApp} from "@composables/app";
import {computed, ref} from "vue";
import {scrollTo} from "../../helpers";
import {client as ApolloClient} from "@/config/clients";
import {useI18n} from "vue-i18n";

export default {
    setup() {
      const {t} = useI18n()
      const avatarList = useAvatarList()
      const {avatarId} = useUser()
      const {toggleAvatarModal, avatarModalIsOpen} = useApp()

      const avatarSelected = ref(null)


      const setAvatar = (avatar_index) => {
        avatarSelected.value = avatar_index
        scrollTo(document.querySelector('#avatarModal'), 2000)
      }

      const selectedAvatar = computed(() => {
        if(avatarSelected.value) return avatarSelected.value
        return avatarId.value
      })

      const canSaveAvatar = computed ( () => {
        return avatarSelected.value
      })

      const saveAvatar = async () => {
          if(!canSaveAvatar.value) return
          const {user, setAvatarId} = useUser()
          let response = await  ApolloClient.mutate({
            mutation: require('@gql/user/update_avatar_user.gql'),
            variables: {
              userId: user.value.id,
              avatarId: avatarSelected.value
            }
          })
          if(response) {
            setAvatarId( avatarSelected.value)
            toggleAvatarModal()
          }
      }

      return {
        t,
        avatarId,
        setAvatar,
        avatarList,
        toggleAvatarModal,
        selectedAvatar,
        avatarModalIsOpen,
        canSaveAvatar,
        saveAvatar
      }
    }
}
</script>