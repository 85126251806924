<template>
  <div class="w-full">

    <VideoContainer :path="node.path" :videoId="node.id" @mediaUpdated="mediaWatched"/>

    <div v-if=" ! scenarioIsEvaluation && showButton"
         class="flex justify-center mx-auto items-center py-8 "
         :class="canSubmit ? 'text-orange-primary cursor-pointer' : 'opacity-50 text-green-light'">

      <div class="">{{ t('button.valider_video') }}</div>
      <div  class="flex-shrink-0 ml-4 border-2  rounded-full px-2 py-2"
        :class="canSubmit ? 'border-orange-primary' : 'border-green-light'">
        <div class="w-6">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import VideoContainer from "../Commons/VideoContainer";
import {useScenario} from "@composables/scenario";
import {computed, ref, watchEffect} from "vue";
import {useI18n} from "vue-i18n";

export default {
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  components: {VideoContainer},

  setup() {
    const {t} = useI18n()
      const {setCurrentNodeType, setCurrentNodeValidity, scenarioIsEvaluation} = useScenario()
      setCurrentNodeType('file_video')
      setCurrentNodeValidity(false)

      const hasWatched = ref(false)

      const canSubmit = computed( () => {
        return hasWatched.value
      })

    const showButton = ref(true)



    watchEffect( () => {
      if( canSubmit.value  && scenarioIsEvaluation.value) {
        setCurrentNodeValidity(true)
      }
    })

      const mediaWatched = () => {
          hasWatched.value = true
          showButton.value = false
          setCurrentNodeValidity(true)
      }

      return {t, scenarioIsEvaluation, canSubmit, mediaWatched, showButton}
  }

}
</script>