<template>
  <svg width="230px" height="230px" viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path data-id="1" class="wheel__segment segment__1" d="M57.5 214.593C74.9751 224.682 94.7974 229.996 114.976 230C135.154 230.004 154.979 224.699 172.458 214.617L115 115L57.5 214.593Z" fill="#E8560E"/>
    <path data-id="2" class="wheel__segment segment__2" d="M172.457 214.618C189.937 204.536 204.456 190.032 214.556 172.564C224.657 155.095 229.983 135.276 230 115.098L115 115L172.457 214.618Z" fill="#A74004"/>
    <path data-id="3" class="wheel__segment segment__3" d="M230 115.094C230.016 94.9151 224.723 75.0875 214.652 57.602C204.58 40.1165 190.085 25.5889 172.623 15.4781L115 115L230 115.094Z" fill="#9C3100"/>
    <path data-id="4" class="wheel__segment segment__4" d="M172.624 15.4789C155.162 5.36782 135.346 0.0295118 115.167 0.000121978C94.9889 -0.0292678 75.1579 5.25129 57.6659 15.3115L115 115L172.624 15.4789Z" fill="#3C0D06"/>
    <path data-id="5" class="wheel__segment segment__5" d="M57.6684 15.31C40.1762 25.3698 25.6389 39.855 15.5164 57.311C5.39398 74.767 0.0427856 94.5791 0.000259399 114.758L115 115L57.6684 15.31Z" fill="#240200"/>
    <path data-id="6" class="wheel__segment segment__6" d="M0.000259399 114.758C-0.0423431 134.986 5.25128 154.867 15.3469 172.396C25.4426 189.924 39.9831 204.48 57.5012 214.594L115 115L0.000259399 114.758Z" fill="#183C3B"/>

    <path class="wheel__text text__1" d="M115.359 212H117.087V199.256H115.557L112.011 202.19L113.019 203.396L115.359 201.236V212Z" fill="white"/>
    <path class="wheel__text text__2" d="M197.418 167.114L201.594 159.881L200.285 159.125L197.081 164.674L195.67 159.017C195.286 157.486 194.782 155.948 193.332 155.111C191.306 153.941 189.368 154.921 188.27 156.823C187.172 158.725 187.254 160.706 189.032 162.127L190.108 160.587C189.172 159.797 188.949 158.67 189.57 157.595C190.173 156.55 191.338 156.08 192.43 156.71C193.334 157.232 193.747 158.281 193.988 159.231L195.828 166.196L197.418 167.114Z" fill="white"/>
    <path class="wheel__text text__3" d="M199.377 71.3919C200.757 69.9301 200.698 68.1353 199.726 66.4517C198.601 64.5032 196.526 63.3939 194.406 64.6179C193.05 65.4009 192.315 66.8643 192.883 68.3866L192.851 68.4046C191.876 67.4713 190.422 67.2924 189.268 67.9584C187.226 69.1374 187.169 71.2699 188.213 73.0781C189.059 74.5435 190.476 75.4503 192.212 75.2171L191.844 73.3924C190.892 73.506 189.972 73.1016 189.504 72.291C188.892 71.2309 189.057 70.0759 190.226 69.4009C191.317 68.7709 192.271 68.9475 193.585 71.2234L194.895 70.4674C194.085 69.0645 193.322 67.2393 195.161 66.1773C196.346 65.4933 197.724 66.0074 198.39 67.161C199.065 68.3301 198.838 69.1259 197.985 70.0965L199.377 71.3919Z" fill="white"/>
    <path class="wheel__text text__4" d="M114.453 18L112.725 18L112.725 20.808L110.925 20.808L110.925 22.32L112.725 22.32L112.725 30.744L114.813 30.744L119.889 22.572L119.889 20.808L114.453 20.808L114.453 18ZM114.453 22.32L118.377 22.32L114.489 28.692L114.453 28.692L114.453 22.32Z" fill="white"/>
    <path class="wheel__text text__5" d="M40.7936 75.8832L44.2136 69.9596L38.4711 66.4778C38.4133 67.4421 38.0579 68.5256 37.7339 69.0868C36.8249 70.6612 35.3843 71.2844 33.7787 70.3574C32.3757 69.5474 32.0764 68.0859 32.7604 66.9012C33.3274 65.9191 34.1353 65.4918 35.3735 65.6871L35.7321 63.878C34.001 63.5644 32.45 64.4149 31.586 65.9114C30.344 68.0626 30.7947 70.6299 32.9927 71.8989C35.1439 73.1409 37.4234 72.6487 38.6204 70.5754C38.8274 70.2169 39.2018 69.3523 39.2415 68.9595L41.9737 70.5993L39.3907 75.0732L40.7936 75.8832Z" fill="white"/>
    <path class="wheel__text text__6" d="M43.6137 159.001L42.5967 157.24L36.5741 156.996C35.1271 156.938 34.0333 156.988 32.6771 157.771C30.5259 159.013 30.113 161.537 31.31 163.611C32.507 165.684 34.7752 166.661 37.207 165.257C39.6232 163.862 39.6458 161.417 38.7278 159.827C38.4668 159.375 38.1549 159.014 37.7764 158.755L37.7404 158.692L43.6137 159.001ZM33.5879 159.24C34.9285 158.466 36.4104 158.837 37.2024 160.209C37.9944 161.58 37.5745 163.049 36.2339 163.823C34.8933 164.597 33.4114 164.226 32.6194 162.855C31.8274 161.483 32.2473 160.014 33.5879 159.24Z" fill="white"/>
  </svg>
</template>