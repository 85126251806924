<template>
    <textarea 
        :placeholder="placeholder" 
        @input="resize(); $emit('update:modelValue', $event.target.value);" 
        :value="modelValue" 
        ref="textarea"
    ></textarea>
</template>

<script>
import {ref} from 'vue';

export default ({
    props: {
        modelValue: {
            type: String,
        },
        placeholder: {
            type: String,
            default: "",
        },
        maxSize: {
            type: Number,
            default: 86,
        },
    },

    setup(props) {
        const textarea = ref(null)
        
        const resize = () => {
            textarea.value.style.height = "38px";
            textarea.value.style.height = textarea.value.scrollHeight <= props.maxSize ? (textarea.value.scrollHeight + 2) + "px" : props.maxSize + "px";
        }

        const resetSize = () => {
            textarea.value.style.height = "38px";
        }

        return {
            textarea,
            resize,
            resetSize
        }
    },
})
</script>

<style scoped>
    textarea {
        @apply mx-2 p-2 border border-green-light rounded-xl text-green-primary text-sm;
        resize: none;
        height: 38px;
    }

    textarea:focus {
        outline: none;
    }
</style>