<template>
  <svg width="230px" height="230px" viewBox="0 0 230 230" xmlns="http://www.w3.org/2000/svg">
    <path data-id="1" class="wheel__segment segment__1" d="m214.59 172.5c-10.092 17.481-24.608 31.997-42.088 42.09s-37.308 15.408-57.493 15.41c-20.185 2e-3 -40.014-5.308-57.496-15.398-17.482-10.089-32-24.603-42.096-42.081l99.581-57.521 99.593 57.5z" fill="#E8560E"/>
    <path data-id="2" class="wheel__segment segment__2" d="m115 2.4414e-4c20.187 2.4e-7 40.018 5.3138 57.5 15.407s32 24.611 42.093 42.093c10.093 17.482 15.407 37.313 15.407 57.5s-5.314 40.018-15.407 57.5l-99.593-57.501v-115z" fill="#A74004"/>
    <path data-id="3" class="wheel__segment segment__3" d="m15.418 172.52c-10.098-17.482-15.415-37.314-15.418-57.503-0.0028971-20.189 5.309-40.023 15.402-57.508 10.093-17.485 24.611-32.005 42.094-42.1 17.484-10.095 37.317-15.41 57.506-15.409l-2e-3 115-99.582 57.52z" fill="#9C3100"/>

    <path class="wheel__text text__1" d="m115.36 212h1.728v-12.744h-1.53l-3.546 2.934 1.008 1.206 2.34-2.16v10.764z" fill="#fff"/>
    <path class="wheel__text text__2" d="m201.59 70.114-4.176-7.233-1.309 0.756 3.204 5.5495-5.605-1.6071c-1.518-0.4331-3.102-0.7654-4.552 0.0716-2.026 1.17-2.146 3.3384-1.048 5.2402s2.854 2.8206 4.974 1.9917l-0.796-1.7022c-1.152 0.416-2.239 0.0457-2.86-1.0299-0.603-1.0444-0.428-2.2888 0.664-2.9188 0.904-0.522 2.019-0.355 2.962-0.0891l6.952 1.8891 1.59-0.918z" fill="#fff"/>
    <path class="wheel__text text__3" d="m35.796 64.164c-1.956-0.4642-3.4806 0.4846-4.4526 2.1681-1.125 1.9486-1.0484 4.2999 1.0716 5.5239 1.3562 0.783 2.9909 0.6876 4.0257-0.5648l0.0312 0.018c-0.3206 1.3114 0.2516 2.6602 1.4052 3.3262 2.0421 1.179 3.9175 0.1626 4.9615-1.6457 0.846-1.4653 0.9227-3.1461-0.1472-4.5329l-1.3964 1.2306c0.5748 0.7684 0.6842 1.7669 0.2162 2.5775-0.612 1.06-1.6945 1.495-2.8637 0.82-1.0912-0.63-1.4153-1.5446-0.1013-3.8205l-1.3094-0.756c-0.81 1.403-2.0093 2.9761-3.8487 1.9141-1.1847-0.684-1.4283-2.1341-0.7623-3.2876 0.675-1.1692 1.4774-1.371 2.7444-1.1175l0.4258-1.8534z" fill="#fff"/>
  </svg>

</template>