<template>
  <div class="py-6 ">
    <div class="text-green-dark text-lg px-4">{{item.label}}</div>

    <div v-if="item.media" class="my-4">
      <VideoContainer :path="item.media.path" :videoId="item.id" />
    </div>
    
    <div v-if="item.correctAnswers > 1" class="input-field true-false my-2 px-4">
      <Checkbox v-for="(field, index) in item.options" :key="index"
          :name="field.label"
          v-model:names="names"
          :value="field.value"
          :disabled="showResponse"
          :hasError="field.error" />
    </div>

    <div v-else class="input-field true-false my-2 px-4">
      <Radio v-for="(field, index) in item.options" :key="index"
          :group="field.group"
          :name="field.label"
          v-model:names="names"
          :value="field.value"
          :disabled="showResponse"
          :hasError="field.error" />
    </div>

    <div v-if="showResponse" class="px-4" :class="hasError ? 'text-red-primary': 'text-green-primary ' ">
      <div class="flex">
        <div v-if="hasError" class="w-5 h-5  rounded flex-grow-0 text-white  bg-red-primary">
          <div class="transform rotate-45">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          </div>
        </div>
        <div v-else class="w-5 h-5  rounded flex-grow-0 text-green-primary  bg-transparent">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <div v-if="hasError" class="ml-2">{{ item.answerDetail ?? t('scenario.message_mauvaise_reponse') }}</div>
        <div v-else class="ml-2">{{ item.answerDetail ?? t('scenario.message_bonne_reponse') }}</div>
      </div>
    </div>

  </div>

</template>

<script>
import {computed, ref, watchEffect} from "vue";
import Checkbox from "../Commons/Checkbox";
import Radio from "../Commons/Radio";
import {useI18n} from "vue-i18n";
import VideoContainer from "../Commons/VideoContainer";

export default {
  components: {Checkbox, Radio, VideoContainer},
  props: ['item', 'showResponse'],
  emits: ["updated"],

  setup(props, context) {
    const names = ref([]);
    const {t} = useI18n()
    watchEffect(()=> {
      context.emit("updated", names);
    })

    const hasError = computed( () => {
      return props.item.options.some( s => s.error)
    })

    return { t, hasError, names }
  }
}
</script>
