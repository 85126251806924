
import {client as ApolloClient} from "@/config/clients";
import {parseJwt} from "../helpers";
import {createClient} from "../config/clients";

const useAuth = () => {

    /**
     *
     * @returns <string> user cognito id
     */
    const cognitoIdFromToken = () => {
        let token = getToken()
        return token['cognito:username']
    }


    const refreshToken = async () => {

        const response = await fetch(process.env.VUE_APP_AUTH_URL + "/auth/authenticate", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })

        try {
            const json = await response.json()
            if(json.id.token) {
                localStorage.setItem(process.env.VUE_APP_AUTH_TOKEN_NAME, `Bearer ${json.id.token}`)
                createClient()
                return true
            }
        } catch {
            return false
        }
    }

    const logout = async () => {
        await deleteToken()
        return window.location =   window.location = process.env.VUE_APP_AUTH_URL+'/leave'
    }



    /**
    * Retreive the token
    * @return <url>location|<string> token
    */
    const getToken =  (raw) => {
        let token = null
        let urlToken = getTokenFromUrl()

        //document.location.reload();
        if( ! urlToken ) {
            token = localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
        }else {
            localStorage.setItem(process.env.VUE_APP_AUTH_TOKEN_NAME, urlToken)
            token = urlToken
        }

        if(token) {
            return raw ? token : parseJwt(token)
        }

        return null
    }

    /**
    * Retreive the token from url hash
    * @return <url>location|<string> token
    */
    const getTokenFromUrl = () => {
        let tokenObject = {};
        let token = null;
        let hash = window.location.hash
        if (hash && hash.includes('token')) {
            hash
                .substring(1)
                .split("&")
                .forEach(function (x) {
                    let arr = x.split("=");
                    arr[1] && (tokenObject[arr[0]] = arr[1]);
                });
            token = tokenObject.token ?? null;
        }
        return token
    }


    const deleteToken = async  () => {
        await localStorage.removeItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
        return
    }

    /**
     * Determine if the use can connect with his token
     * @return boolean|<string>location
     */
    const hasValidToken = async () => {
        //await sleep(2000)
        let token = await getToken()
        createClient()

        if(!token) return null
        // verifier que le token n'a pas expiré
        let current_time = Date.now().valueOf() / 1000;
        if (token.exp < current_time || ! token['cognito:username']) {
            // alert('expired or invalid token')
            /* expired */
            await deleteToken()
            return null
        }

        let user = null

        try {
            user = await me(token['cognito:username'])
        } catch {
            user = null
        }

        if(user && user[0]) {
           return true
        }

        // Token is valid but no user in db
        return true
        // On fait une query juste pour voir si tout passe bien
    }

    /**
     *
     * @param <string> cognitoId
     * @returns {Promise<*>}
     */
    const me = async (cognitoId) =>  {
        let user = await ApolloClient.query({
            query: require('@gql/auth/me.gql'),
            variables: {cognitoId}
        })

        return user.data.users
    }


    return {
        hasValidToken,
        cognitoIdFromToken,
        logout,
        me,
        deleteToken,
        refreshToken
    }
}

export {
    useAuth,

}