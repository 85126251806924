<template>
  <svg width="230px" height="230px" viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path data-id="1" class="wheel__segment segment__1" d="M75.6003 223.04C100.998 232.302 128.847 232.32 154.257 223.092L115 115L75.6003 223.04Z" fill="#E8560E"/>
    <path data-id="2" class="wheel__segment segment__2" d="M154.257 223.092C179.667 213.864 201.014 195.978 214.549 172.576L115 115L154.257 223.092Z" fill="#A74004"/>
    <path data-id="3" class="wheel__segment segment__3" d="M214.55 172.575C228.084 149.173 232.942 121.751 228.269 95.1235L115 115L214.55 172.575Z" fill="#9C3100"/>
    <path data-id="4" class="wheel__segment segment__4" d="M228.27 95.1262C223.598 68.4988 209.694 44.3686 189.001 26.9725L115 115L228.27 95.1262Z" fill="#3C0D06"/>
    <path data-id="5" class="wheel__segment segment__5" d="M189.003 26.9745C168.311 9.57786 142.15 0.027387 115.116 5.87583e-05L115 115L189.003 26.9745Z" fill="#240200"/>
    <path data-id="6" class="wheel__segment segment__6" d="M115.116 5.86475e-05C88.0821 -0.0272438 61.9026 9.47035 41.1746 26.8251L115 115L115.116 5.86475e-05Z" fill="#183C3B"/>
    <path data-id="7" class="wheel__segment segment__7" d="M41.1749 26.8248C20.4468 44.1796 6.49533 68.282 1.77022 94.8999L115 115L41.1749 26.8248Z" fill="#2A5A59"/>
    <path data-id="8" class="wheel__segment segment__8" d="M1.76985 94.9019C-2.95479 121.52 1.84904 148.951 15.3378 172.38L115 115L1.76985 94.9019Z" fill="#3D7F7F"/>
    <path data-id="9" class="wheel__segment segment__9" d="M15.3394 172.383C28.8414 195.833 50.18 213.77 75.6017 223.041L115 115L15.3394 172.383Z" fill="#9C3100"/>

    <path class="wheel__text text__1" d="M115.359 212H117.087V199.256H115.557L112.011 202.19L113.019 203.396L115.359 201.236V212Z" fill="white"/>
    <path class="wheel__text text__2" d="M174.654 191.989L181.052 186.62L180.08 185.462L175.171 189.581L175.78 183.782C175.943 182.212 175.996 180.594 174.919 179.312C173.415 177.519 171.259 177.778 169.577 179.19C167.895 180.601 167.295 182.491 168.479 184.434L170.017 183.355C169.408 182.292 169.584 181.158 170.535 180.359C171.459 179.584 172.715 179.541 173.525 180.506C174.196 181.306 174.225 182.433 174.127 183.408L173.474 190.582L174.654 191.989Z" fill="white"/>
    <path class="wheel__text text__3" d="M207.785 135.51C209.781 135.277 210.89 133.864 211.227 131.949C211.618 129.733 210.742 127.55 208.331 127.125C206.789 126.853 205.285 127.502 204.741 129.033L204.706 129.026C204.558 127.685 203.559 126.613 202.248 126.381C199.925 125.972 198.511 127.569 198.148 129.625C197.854 131.291 198.357 132.897 199.837 133.834L200.728 132.2C199.925 131.675 199.481 130.774 199.643 129.852C199.856 128.647 200.724 127.868 202.054 128.102C203.295 128.321 203.912 129.069 203.456 131.657L204.945 131.92C205.226 130.325 205.815 128.436 207.907 128.805C209.254 129.043 209.979 130.322 209.747 131.634C209.513 132.963 208.828 133.427 207.551 133.622L207.785 135.51Z" fill="white"/>
    <path class="wheel__text text__4" d="M198.981 65.5933L198.117 64.0968L195.685 65.5008L194.785 63.9419L193.476 64.6979L194.376 66.2568L187.08 70.4688L188.124 72.277L197.739 72.587L199.267 71.705L196.549 66.9973L198.981 65.5933ZM195.24 67.7533L197.202 71.1515L189.739 70.9704L189.721 70.9393L195.24 67.7533Z" fill="white"/>
    <path class="wheel__text text__5" d="M141.05 34.6361L147.477 36.9755L149.909 30.7156C148.949 30.8261 147.821 30.6643 147.212 30.4426C145.503 29.8208 144.64 28.5104 145.274 26.7682C145.828 25.2459 147.215 24.6973 148.5 25.1651C149.566 25.553 150.127 26.2744 150.15 27.5278L151.994 27.5667C152.002 25.8075 150.895 24.4277 149.271 23.8367C146.937 22.9871 144.487 23.8768 143.619 26.2617C142.769 28.5959 143.65 30.7553 145.9 31.5741C146.289 31.7157 147.205 31.9343 147.599 31.9052L146.458 34.8806L141.604 33.1138L141.05 34.6361Z" fill="white"/>
    <path class="wheel__text text__6" d="M84.6502 36.5651L86.5615 35.8694L87.847 29.9806C88.1556 28.5657 88.2968 27.48 87.7612 26.0084C86.9116 23.6742 84.497 22.8291 82.2473 23.6479C79.9977 24.4667 78.6419 26.5309 79.6023 29.1696C80.5566 31.7913 82.9604 32.2381 84.6856 31.6102C85.1761 31.4316 85.5851 31.187 85.9065 30.8593L85.9741 30.8347L84.6502 36.5651ZM86.1559 26.6502C86.6853 28.1048 86.0629 29.4998 84.5744 30.0416C83.086 30.5833 81.7125 29.9148 81.183 28.4601C80.6536 27.0055 81.276 25.6105 82.7645 25.0687C84.2529 24.527 85.6264 25.1955 86.1559 26.6502Z" fill="white"/>
    <path class="wheel__text text__7" d="M33.123 63.6812L32.151 65.3647L39.3714 75.3947L40.6496 76.1327L44.6546 69.1958L43.3452 68.4398L40.2672 73.771L33.123 63.6812Z" fill="white"/>
    <path class="wheel__text text__8" d="M19.7615 131.884C20.1647 134.171 21.9194 135.726 24.2947 135.307C25.8547 135.032 26.8079 133.804 26.9394 132.373L26.9926 132.364C27.4065 133.26 28.5272 134.122 30.2645 133.816C32.1612 133.481 33.0843 131.564 32.7373 129.596C32.3904 127.629 30.8672 126.143 28.9704 126.477C27.2332 126.783 26.475 127.977 26.3925 128.961L26.3393 128.97C25.7264 127.671 24.4106 126.843 22.8507 127.118C20.4753 127.536 19.3583 129.598 19.7615 131.884ZM29.0223 128.223C30.1923 128.016 31.164 128.759 31.3546 129.84C31.5609 131.01 30.8684 131.955 29.7162 132.158C28.6881 132.339 27.6152 131.852 27.3839 130.54C27.162 129.282 28.0474 128.395 29.0223 128.223ZM21.2505 131.622C21.0223 130.328 21.6794 129.079 23.1507 128.819C24.4802 128.585 25.6333 129.423 25.8949 130.803C26.1116 132.136 25.5191 133.337 23.9947 133.605C22.6297 133.846 21.5006 133.04 21.2505 131.622Z" fill="white"/>
    <path class="wheel__text text__9" d="M51.4972 187.92L53.0554 189.227L58.7979 187.396C60.1776 186.956 61.1885 186.535 62.1951 185.336C63.7918 183.433 63.3163 180.919 61.4824 179.38C59.6485 177.842 57.183 177.7 55.378 179.851C53.5847 181.988 54.3996 184.293 55.8061 185.473C56.2059 185.809 56.6223 186.041 57.0667 186.155L57.1219 186.201L51.4972 187.92ZM60.8367 184.266C59.8416 185.452 58.3223 185.611 57.1089 184.593C55.8955 183.574 55.7877 182.051 56.7828 180.865C57.7778 179.679 59.2971 179.52 60.5105 180.539C61.7239 181.557 61.8317 183.081 60.8367 184.266Z" fill="white"/>
  </svg>
</template>