<template>
  <svg width="230px" height="230px" viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path data-id="1" class="wheel__segment segment__1" d="M85.2147 226.076C104.705 231.302 125.227 231.308 144.72 226.093L115 115L85.2147 226.076Z" fill="#E8560E"/>
    <path data-id="2" class="wheel__segment segment__2" d="M144.718 226.094C164.211 220.879 181.989 210.628 196.266 196.368L115 115L144.718 226.094Z" fill="#A74004"/>
    <path data-id="3" class="wheel__segment segment__3" d="M196.266 196.368C210.544 182.109 220.818 164.343 226.057 144.857L115 115L196.266 196.368Z" fill="#9C3100"/>
    <path data-id="4" class="wheel__segment segment__4" d="M226.057 144.854C231.296 125.367 231.314 104.845 226.112 85.3484L115 115L226.057 144.854Z" fill="#3C0D06"/>
    <path data-id="5" class="wheel__segment segment__5" d="M226.111 85.3455C220.907 65.8494 210.666 48.0655 196.415 33.7801L115 115L226.111 85.3455Z" fill="#240200"/>
    <path data-id="6" class="wheel__segment segment__6" d="M196.415 33.7805C182.164 19.495 164.404 9.21072 144.921 3.96064L115 115L196.415 33.7805Z" fill="#183C3B"/>
    <path data-id="7" class="wheel__segment segment__7" d="M144.921 3.96074C125.438 -1.28942 104.916 -1.32059 85.4162 3.87035L115 115L144.921 3.96074Z" fill="#2A5A59"/>
    <path data-id="8" class="wheel__segment segment__8" d="M85.4161 3.87039C65.9166 9.06136 48.1263 19.2917 33.8318 33.5339L115 115L85.4161 3.87039Z" fill="#3D7F7F"/>
    <path data-id="9" class="wheel__segment segment__9" d="M33.8313 33.5344C19.5369 47.7767 9.24157 65.5295 3.97933 85.0098L115 115L33.8313 33.5344Z" fill="#9C3100"/>
    <path data-id="10" class="wheel__segment segment__10" d="M3.97911 85.0107C-1.28299 104.491 -1.32675 125.013 3.85223 144.516L115 115L3.97911 85.0107Z" fill="#3C0D06"/>
    <path data-id="11" class="wheel__segment segment__11" d="M3.85327 144.52C9.03293 164.022 19.2529 181.818 33.4869 196.121L115 115L3.85327 144.52Z" fill="#240200"/>
    <path data-id="12" class="wheel__segment segment__12" d="M33.4876 196.122C47.7883 210.491 65.6335 220.825 85.2147 226.076L115 115L33.4876 196.122Z" fill="#183C3B"/>

    <path class="wheel__text text__1" d="M115.359 212H117.087V199.256H115.557L112.011 202.19L113.019 203.396L115.359 201.236V212Z" fill="white"/>
    <path class="wheel__text text__2" d="M160.386 201.091L167.619 196.915L166.863 195.606L161.314 198.81L162.921 193.205C163.354 191.687 163.686 190.103 162.849 188.653C161.679 186.627 159.511 186.507 157.609 187.605C155.707 188.703 154.788 190.459 155.617 192.579L157.319 191.783C156.903 190.631 157.274 189.544 158.349 188.923C159.394 188.32 160.638 188.495 161.268 189.587C161.79 190.491 161.623 191.606 161.357 192.549L159.468 199.501L160.386 201.091Z" fill="white"/>
    <path class="wheel__text text__3" d="M195.204 165.836C197.16 166.3 198.685 165.351 199.657 163.668C200.782 161.719 200.705 159.368 198.585 158.144C197.229 157.361 195.594 157.456 194.56 158.709L194.528 158.691C194.849 157.379 194.277 156.031 193.123 155.365C191.081 154.186 189.206 155.202 188.162 157.01C187.316 158.476 187.239 160.156 188.309 161.543L189.705 160.312C189.131 159.544 189.021 158.546 189.489 157.735C190.101 156.675 191.184 156.24 192.353 156.915C193.444 157.545 193.768 158.46 192.454 160.736L193.764 161.492C194.574 160.089 195.773 158.515 197.612 159.577C198.797 160.261 199.041 161.711 198.375 162.865C197.7 164.034 196.897 164.236 195.63 163.983L195.204 165.836Z" fill="white"/>
    <path class="wheel__text text__4" d="M212.5 113.953V112.225H209.692V110.425H208.18V112.225H199.756V114.313L207.928 119.389H209.692V113.953H212.5ZM208.18 113.953V117.877L201.808 113.989V113.953H208.18Z" fill="white"/>
    <path class="wheel__text text__5" d="M186.729 69.8609L190.149 75.7845L196.036 72.5522C195.23 72.02 194.469 71.1705 194.145 70.6093C193.236 69.0349 193.417 67.4757 195.022 66.5487C196.425 65.7387 197.841 66.2101 198.525 67.3949C199.092 68.3769 199.058 69.2902 198.269 70.265L199.657 71.48C200.794 70.1376 200.833 68.3692 199.969 66.8727C198.727 64.7215 196.278 63.8282 194.08 65.0972C191.929 66.3392 191.216 68.5594 192.413 70.6326C192.62 70.9912 193.181 71.7478 193.501 71.9785L190.715 73.5248L188.132 69.0509L186.729 69.8609Z" fill="white"/>
    <path class="wheel__text text__6" d="M155.755 40.9506L157.516 41.9676L162.854 39.1671C164.136 38.4941 165.058 37.9043 165.841 36.5481C167.083 34.3969 166.179 32.004 164.105 30.807C162.032 29.61 159.579 29.8982 158.175 32.33C156.78 34.7462 157.983 36.8748 159.573 37.7928C160.025 38.0538 160.476 38.2099 160.933 38.2454L160.996 38.2814L155.755 40.9506ZM164.318 35.7309C163.544 37.0715 162.075 37.4914 160.703 36.6994C159.332 35.9074 158.961 34.4255 159.735 33.0849C160.509 31.7443 161.978 31.3244 163.349 32.1164C164.721 32.9084 165.092 34.3903 164.318 35.7309Z" fill="white"/>
    <path class="wheel__text text__7" d="M118.755 18H116.811L111.735 29.268V30.744H119.745V29.232H113.589L118.755 18Z" fill="white"/>
    <path class="wheel__text text__8" d="M66.8894 30.81C64.8785 31.971 64.0175 34.1516 65.2235 36.2405C66.0155 37.6123 67.4956 38.0879 68.885 37.7223L68.912 37.7691C68.2118 38.4643 67.7846 39.8125 68.6666 41.3402C69.6296 43.0081 71.7471 43.2197 73.4774 42.2207C75.2078 41.2217 76.0833 39.2821 75.1203 37.6142C74.2383 36.0865 72.8571 35.7823 71.9049 36.0411L71.8779 35.9943C72.8893 34.9739 73.2174 33.4543 72.4254 32.0825C71.2194 29.9936 68.9004 29.649 66.8894 30.81ZM73.4978 38.2599C74.0918 39.2888 73.7263 40.4558 72.7754 41.0048C71.7466 41.5988 70.6221 41.2712 70.0371 40.2579C69.5151 39.3538 69.6059 38.179 70.7594 37.513C71.8662 36.874 73.0028 37.4026 73.4978 38.2599ZM67.6454 32.1194C68.7834 31.4624 70.1819 31.6527 70.9289 32.9465C71.6039 34.1156 71.2103 35.486 70.0034 36.2036C68.8253 36.863 67.494 36.7171 66.72 35.3765C66.027 34.1762 66.3984 32.8394 67.6454 32.1194Z" fill="white"/>
    <path class="wheel__text text__9" d="M32.574 64.6319L31.557 66.3934L34.3575 71.7308C35.0306 73.0131 35.6203 73.9355 36.9765 74.7185C39.1277 75.9605 41.5207 75.0558 42.7177 72.9826C43.9147 70.9093 43.6265 68.4566 41.1947 67.0526C38.7784 65.6576 36.6498 66.8604 35.7318 68.4504C35.4708 68.9025 35.3148 69.3528 35.2793 69.8103L35.2433 69.8727L32.574 64.6319ZM37.7938 73.1951C36.4532 72.4211 36.0333 70.9523 36.8253 69.5806C37.6173 68.2088 39.0992 67.838 40.4398 68.612C41.7804 69.386 42.2003 70.8548 41.4083 72.2266C40.6163 73.5983 39.1344 73.9691 37.7938 73.1951Z" fill="white"/>
    <path class="wheel__text text__10" d="M18.5 109.858L18.5 111.586L31.244 111.586V110.056L28.31 106.51L27.104 107.518L29.264 109.858L18.5 109.858ZM24.872 115.612C22.19 115.612 18.284 116.386 18.284 120.004C18.284 123.622 22.19 124.396 24.872 124.396C27.662 124.396 31.46 123.622 31.46 120.004C31.46 116.386 27.662 115.612 24.872 115.612ZM24.872 117.34C26.636 117.34 29.948 117.592 29.948 120.004C29.948 122.416 26.636 122.668 24.872 122.668C23.216 122.668 19.796 122.416 19.796 120.004C19.796 117.592 23.216 117.34 24.872 117.34Z" fill="white"/>
    <path class="wheel__text text__11" d="M28.9246 159.047L29.7886 160.543L40.8252 154.171L40.0602 152.846L35.7463 151.242L35.2058 152.718L38.2464 153.665L28.9246 159.047ZM33.9255 167.709L34.7895 169.205L45.8262 162.833L45.0612 161.508L40.7472 159.904L40.2068 161.38L43.2474 162.327L33.9255 167.709Z" fill="white"/>
    <path class="wheel__text text__12" d="M62.5469 196.433L64.0434 197.297L70.4154 186.261L69.0903 185.496L64.5524 186.264L64.8224 187.812L67.9289 187.111L62.5469 196.433ZM67.717 199.418L74.95 203.594L75.706 202.285L70.1566 199.081L75.8137 197.671C77.3447 197.287 78.883 196.782 79.72 195.332C80.89 193.306 79.9096 191.368 78.0078 190.27C76.106 189.172 74.1253 189.255 72.7041 191.032L74.2443 192.109C75.0344 191.172 76.1606 190.95 77.2362 191.571C78.2807 192.174 78.751 193.339 78.121 194.43C77.599 195.334 76.5501 195.747 75.6001 195.988L68.635 197.828L67.717 199.418Z" fill="white"/>
  </svg>
</template>