<template>
    <div @click="openScenario"
        class="rounded-lg text-lg font-bold  px-5 py-4 my-4 min-h-20 flex w-full items-center" style="min-height: 75px"
      :class="[
          disabled ? ' text-green-light bg-green-light1' :
            current ? 'bg-orange-primary text-white cursor-pointer' : 'bg-green-primary text-white cursor-pointer'
      ]"
    >
      <div class="flex items-center w-full">

        <div v-if="!disabled && !current" class="w-6 flex-shrink-0 text-white mr-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>

        <div class="mr-4 ">
          <slot/>
        </div>

        <div v-if="!disabled" class="flex-shrink-0 ml-auto border-2 border-white  rounded-full px-2 py-2 text-white">

          <div class="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </div>

        </div>

      </div>
    </div>
</template>

<script>

import {useRouter} from "vue-router";

export default {

  props: {
    type: {
      type: String,
      default: 'document'
    },
    url: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    //console.log(props.url)
    const router = useRouter()
    const openScenario = () => {
      // console.log(props.disabled.value)

      if( ! props.disabled ) {
        router.push(props.url)
      }
    }

    return {openScenario}


  }
}
</script>