<template>

    <div v-if="wheel && avatar" id="wheelTouch" class="pt-10 flex flex-col justify-center items-center px-2">

      <div class="flex  justify-center items-center ">
          <div class="h-32 w-32 bg-green-dark z-10 rounded-full overflow-hidden pointer-events-none"
            :class="{'absolute' : hasModules}"
          >
            <img v-if="avatar" class="w-full" :src="require(`@assets/${avatar}`)">
          </div>
          <div id="wheel" v-if="hasModules" class="transition duration-300 ease" style="max-width: 300px; transform: rotate(0deg);" data-current-rotate="0">
            <component :is="wheel"></component >
          </div>
      </div>

      <div  v-if="hasModules" class="my-6 flex justify-center">
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.1571 16.5H2.84291C1.45847 16.5 0.492767 15.1273 0.960519 13.8243L5.11761 2.2438C5.75145 0.478105 8.24855 0.47811 8.88239 2.2438L13.0395 13.8243C13.5072 15.1273 12.5415 16.5 11.1571 16.5Z" fill="#CEDFDF"/>
        </svg>
      </div>

    </div>

</template>

<script>

import {useModule} from "@composables/module";
import Wheel1 from './Wheel1'
import Wheel2 from './Wheel2'
import Wheel3 from './Wheel3'
import Wheel4 from './Wheel4'
import Wheel5 from './Wheel5'
import Wheel6 from './Wheel6'
import Wheel7 from './Wheel7'
import Wheel8 from './Wheel8'
import Wheel9 from './Wheel9'
import Wheel10 from './Wheel10'
import Wheel11 from './Wheel11'
import Wheel12 from './Wheel12'
import {nextTick} from "vue";
import {useUser} from "@composables/user"

export default {

  components : {
    Wheel1, Wheel2, Wheel3, Wheel4, Wheel5, Wheel6, Wheel7, Wheel8, Wheel9, Wheel10, Wheel11, Wheel12
  },

  setup() {
    const {wheel, initWheel, hasModules} = useModule()
    const {avatar} = useUser()

    nextTick( () => {
      initWheel()
    })

    // const test = (e) => {
    //   console.log(e.target.dataset.id)
    // }

    return { wheel, avatar, hasModules}
  }
}
</script>