<template>

  <div @click="go" :class="[{'cursor-pointer' : canGo}]">
      <div class="text-white py-5 flex border-b items-center px-4"
        :class="[
            canAccessCurrentModule && sequence.available == true ? '  border-orange-primary' : ' bg-green-light1 border-white',
          ]"
      >
        <div class="relative"
             :class="sequence.available == true && !sequence.score && !sequence.finished ? 'text-orange-primary' :  'text-white' "
        >

          <div class="text-sm text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pt-1">
            <span v-if="sequence.score">{{sequence.score}}%</span>
            <span v-else-if="sequence.finished">100%</span>
            <span v-else>-</span>
          </div>
          <div :class="[sequence.score || sequence.finished ? 'text-orange-primary' : 'text-white-primary']">
            <ProgressRing radius="32" stroke="3" :progress="progressRingValue" :fill="sequence.available == true && !sequence.score && !sequence.finished"></ProgressRing>
          </div>

        </div>

        <div class="text-lg ml-4">{{ sequence.title }}</div>

      </div>
    </div>
</template>

<script>

import {useModule} from '@composables/module'
import ProgressRing from "@components/Commons/ProgressRing";
import {computed} from "vue";
import {useRouter} from "vue-router";

export default {
  props: ['sequence'],
  components: {ProgressRing },

  setup (props) {
    const {modulesSequences, canAccessCurrentModule} = useModule()
    //const limit = ref(props.sequence.score)


    const progressRingValue = computed( () => {
        return props.sequence.score ? props.sequence.score : 100
    })



    // const i = setInterval(function(){
    //   currentProgress.value ++
    // }, 10);

    // watchEffect( () => {
    //   if(currentProgress.value >= limit.value) {
    //     clearInterval(i)
    //   }
    // })

    const router = useRouter()

    const go = () => {
      if( canGo.value) {
        router.push({name: "Sequence", params: {id: props.sequence.id }})
      }
    }

    const canGo = computed( () => {
        return canAccessCurrentModule.value && props.sequence.available
    })

    return {modulesSequences, go, canGo, canAccessCurrentModule, progressRingValue}
  }

}
</script>