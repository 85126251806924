<template>
    <div class="w-full">
      <div v-for="(node, index) in scenarioNodes" :key="index">

        <transition :name="transitionName" @after-enter="afterEnter">
          <div v-if="currentNode == index + 1" class="w-full">

            <ScenarioHeader></ScenarioHeader>

            <NodeVideo v-if="node.type == 'media' && node.mediaType.slug == 'file_video'" :node="node"></NodeVideo>
            <NodePDF v-else-if="node.type == 'media' && node.mediaType.slug == 'file_pdf'" :node="node"></NodePDF>
            <NodeTrueFalse v-else-if="node.type == 'game' && node.gameType.slug == 'true_false'" :node="node"></NodeTrueFalse>
            <NodeQCM v-else-if="node.type == 'game' && node.gameType.slug == 'quiz_simple'" :node="node"></NodeQCM>
            <NodeDragDrop v-else-if="node.type == 'game' && node.gameType.slug == 'drag_drop'" :node="node"></NodeDragDrop>
            <NodeReorder v-else-if="node.type == 'game' && node.gameType.slug == 'reorder'" :node="node" :scenarioId="scenarioId" :sequenceId="sequenceId"></NodeReorder>
            <NodeEmbed v-else-if="node.type == 'embed'" :node="node"></NodeEmbed>

            <div v-if="canGoNext || scenarioIsEvaluation  ">
              <div v-if=" ! (scenarioIsEvaluation && currentNodeType == 'reorder')" @click="goNextNode"
                   class="flex justify-center mx-auto items-center py-8 "
                   :class="canGoNext ? 'text-orange-primary cursor-pointer' : 'opacity-50 text-green-light'">


                <div v-if="!isLastNode" class="">{{  t('button.etape_suivante') }}</div>
                <div v-else-if="scenarioIsEvaluation" class="">{{  t('button.voir_score') }}</div>
                <div v-else class="">{{  t('button.fin_activite') }}</div>

                <div  class="flex-shrink-0 ml-4 border-2  rounded-full px-2 py-2" :class="canGoNext ? 'border-orange-primary' : 'opacity-50 border-green-light'">
                  <div class="w-6">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </div>
                </div>

              </div>
            </div>

            <!-- Restart button -->
            <div
              v-if="!scenarioIsEvaluation && currentNode > 1"
              @click="onRestartScenarioClick"
              class="flex justify-center mx-auto items-center py-8 text-orange-primary cursor-pointer"
            >
              <div>{{  t('button.recommencer_scenario') }}</div>
            </div>


          </div>
        </transition>

      </div>

    </div>

</template>

<script>
import {useScenario} from '@composables/scenario'
import NodeTrueFalse from "@components/Node/NodeTrueFalse"
import NodeVideo from "@components/Node/NodeVideo"
import NodeQCM from "@components/Node/NodeQCM"
import NodeDragDrop from "@components/Node/NodeDragDrop"
import NodeReorder from "@components/Node/NodeReorder"
import NodeEmbed from "@components/Node/NodeEmbed"
import NodePDF from "@components/Node/NodePDF"
import ScenarioHeader from "@components/Scenario/ScenarioHeader";
import {ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {useLogs} from "@composables/logs"

export default {
  components: {NodeVideo, NodeTrueFalse, NodeQCM, NodeDragDrop, NodeReorder, ScenarioHeader, NodeEmbed, NodePDF},
  props: {
    scenarioId: {
      type: String,
      required: true
    },
    sequenceId: {
      type: String,
      required: true
    },
  },

  setup(props) {
    const {t} = useI18n()
      const {
          resetResults,
          setIsEvaluation,
          scenarioIsEvaluation,
          scenarioNodes,
          currentNode,
          nextNode,
          canGoNext,
          currentNodeType,
          isLastNode,
          setNode,
      } = useScenario()

      // console.log("SCENARIO ID", props.scenarioId)


      const router = useRouter()
      const route = useRoute()

      // reset the result when we start a new scenario
      // console.log('RESET RESULTS')
      resetResults()


     //set if is an evaluation

      if(route.name == "Evaluation") {
        setIsEvaluation(true)
      }else {
        setIsEvaluation(false)
      }

      // Check if we need to restore previous node index
      let initialNodeIndex = 1

      if (!scenarioIsEvaluation.value) {
        const { getScenarioLastVisitedNode } = useLogs()
        initialNodeIndex = (getScenarioLastVisitedNode(props.sequenceId, props.scenarioId) || 1)
      }

      // Set the first node of the scenario
      setNode((initialNodeIndex <= scenarioNodes.value.length && initialNodeIndex) || 1)

      const goNextNode = async () => {
        if(!canGoNext.value) return
        return nextNode(props.sequenceId, props.scenarioId, router)
      }

      const afterEnter = () => {
        window.scrollTo(0,0)
        transitionName.value = 'right-left'
      }
      const transitionName = ref('right-left')

      const onRestartScenarioClick = () => {
        transitionName.value = 'left-right'
        resetResults()
        setNode(1)
      }

      return {t, transitionName, afterEnter, scenarioNodes, currentNode, goNextNode, onRestartScenarioClick, canGoNext, currentNodeType, isLastNode, scenarioIsEvaluation}
  }
}
</script>