<template>
  <div>
      <svg
          :height="radius * 2"
          :width="radius * 2"
      >
        <circle
             stroke="currentColor"
            :stroke-dasharray="circumference + ' ' + circumference"
            :style="{ strokeDashoffset: strokeDashoffset }"
            :stroke-width="stroke"
            :fill="isFilled"
            :r="normalizedRadius"
            :cx="radius"
            :cy="radius"
            stroke-linecap="round"
        />

      </svg>
  </div>
</template>

<script>
import {computed, ref} from "vue";

export default {
  props: {
    stroke: {
      type: [Number, String],
      default: 3
    },
    radius: {
      type: [Number, String],
      default: 32
    },
    progress: {
      type: [Number, String],
      default: 0
    },
    fill: {
      type: [Boolean],
      default: false
    }
  },
  setup(props) {

    const normalizedRadius =  ref(props.radius - props.stroke * 2);
    const circumference = ref( normalizedRadius.value * 2 * Math.PI)

    const strokeDashoffset = computed( () => {
      return circumference.value - props.progress / 100 * circumference.value;
    })

    const isFilled = computed ( () => {
        return props.fill == true ? "currentColor" : "transparent"
    })

    return {normalizedRadius, circumference, strokeDashoffset, isFilled}
  }

}
</script>
<style scoped>
circle {
  /*transition: stroke-dashoffset 0.35s;*/
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  @apply transition-colors duration-200 ease-in;
}
</style>