<template>
  <div class="w-full">

    <div class="bg-green-light2">

        <EmbedContainer :src="node.url"/>

    </div>

  </div>

</template>


<script>

import {useScenario} from "@composables/scenario";
import EmbedContainer from "@components/Commons/EmbedContainer";

export default {

  props: {
    node: {
      type: Object,
      required: true
    }
  },

  components: {EmbedContainer},

  setup() {
    const { setCurrentNodeValidity, setCurrentNodeType} = useScenario()

    setCurrentNodeType('presentation_genialy')
    setCurrentNodeValidity(true)
    return {}

  }
}
</script>