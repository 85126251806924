<template>
  <div  @click="goScenario" class="px-6  py-7 flex items-start  cursor-pointer"
    :class="{'border-b border-green-light' : ! last}"
  >
    <div class="text-orange-primary relative  flex-shrink-0">
      <div class="text-sm text-green-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pt-1">
        {{ evaluation.sequence.score }}%</div>
      <ProgressRing radius="28" stroke="3" :progress="evaluation.sequence.score"></ProgressRing>
    </div>

    <div class="ml-4 mr-2 text-green-dark">
      <div class="text-sm ">Module {{ evaluation.module.order + 1 }}. {{ evaluation.module.title }}</div>
      <div class="font-title  font-bold text-lg">{{ evaluation.sequence.title }}</div>
    </div>

    <div class="flex-shrink-0 ml-auto bg-green-dark rounded px-2 py-1 text-white self-center">
      <div class="w-6">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </div>

  </div>
</template>

<script>
import ProgressRing from "./Commons/ProgressRing";
import {computed} from "vue";
import {useLogs} from "../composables/logs";
import {client as ApolloClient} from "@/config/clients";

export default {

  components: {ProgressRing},

  props: {

    evaluation: {
      type: Object,
      required: true
    },
    last: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const {logs} = useLogs()

    const scenarioId = computed( () => {
      let logsSequence = logs.value.filter(log => {
        if(log.data.sequence_id == props.evaluation.sequence.id) {
          return log
        }
      })
      let scenario =  logsSequence.find(log => {
        if(log.data.results.length != 0) {
          return log.data.results.find(r => r.type == "evaluation")
        }
      })
      if(scenario) return scenario.data_key
      return null
    })

    const getScenarioCorrection = async () => {
      let response = await  ApolloClient.mutate({
        mutation: require('@gql/scenarios/get_scenario_correction.gql'),
        variables: {
          scenarioId: scenarioId.value,
        }
      })

      if(response.data.scenario[0] && response.data.scenario[0].correction) {
          return response.data.scenario[0].correction
      }
      return false
    }

    const goScenario = async () => {
      //toggleMenu()
      let correction = await getScenarioCorrection()
      if(correction)  window.open(process.env.VUE_APP_ASSETS_URL + correction.path)
      // else {
      //   console.error("Aucune corrections trouvé pour ce scenario")
      // }

    }

    return {goScenario, getScenarioCorrection}
  }

}
</script>