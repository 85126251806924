import {computed, reactive} from "vue";
/**
 * State
 */
const state = reactive({
    quiz: null,
    showResponse: false
})

const useQuizQCM = () => {

    /**
     *
     * @type {Ref<UnwrapRef<boolean>>}
     * @return boolean
     */
    const showResponse = computed( () => state.showResponse)

    /**
     * @type {ComputedRef<*>}
     * @return state
     */
    const quiz = computed( () => state.quiz)

    /**
     * Set a new quiz
     *
     * @param quiz Object
     *    options Array
     *        label String
     *        value String
     *        error: default = false
     *    answer Array [option_value]
     *    answerDetail  String
     *    label String
     *    media Array
     *        path String
     *
     */
    const setQuizQCM = (quiz) => {
        state.quiz = quiz
        state.showResponse = false
    }

    /**
     * @type {ComputedRef<number>}
     * @return boolean
     */
    const canSubmit = computed( () => {
        return  ! quiz.value.some(question => question.responses.length == 0)
    })

    /**
     *
     */
    const submit = () => {
        if(canSubmit.value) {
            validateResponse()
            state.showResponse = true
        }
    }
    /**
     * Validate the response
     * Add a new key in option item:
     * hasError: <string> wrongAswer|missingAnswer
     */
    const validateResponse = () => {
        quiz.value.forEach(q => {

            q.options.forEach(option => {
                let hasReponded = false
                let answers = q.answer.map(answer => answer+"_"+q.question_id)

                // si il a repondu cette option
                if(q.responses.includes(option.value)) hasReponded = true

                // console.log(q.answer)
                // console.log(option)
                // si l'option est la bonne
                let isCorrectAnswer = false
                if(answers.includes(option.value)) isCorrectAnswer = true

                // si l'option est bonne  // et qu'il a pas repondu
                if(isCorrectAnswer && ! hasReponded) {
                    option.error = 'missingAnswer'
                }

                //si il a repondu et que c'est pas la bonne
                // error
                if(hasReponded && ! isCorrectAnswer) {
                    option.error = "wrongAnswer"
                }
            })
        })

    }

    return {
        quiz,
        setQuizQCM,
        canSubmit,
        submit,
        showResponse
    }
}

export {
    useQuizQCM
}