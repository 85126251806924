<template>
  <div class="bg-green-primary relative flex items-center justify-center" >
      <img :src="mediaPath"/>
  </div>

</template>


<script>
import {computed} from "vue";
import {makeId} from "@/helpers";

export default {
  props: {
    path: {
      type: String,
      default: "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
    },
    minPercent : {
      type: Number,
      default: 20
    },
    imgId : {
      type: [Number, String],
      default: () => {
        return makeId(10)
      }
    }
  },

  setup (props) {



    const mediaPath = computed( () => {
      return process.env.VUE_APP_ASSETS_URL + props.path
    })




    return { mediaPath, }
  }
}
</script>


<style lang="scss" scoped>

</style>