<template>
  <div>
    <ButtonConversation></ButtonConversation>

    <transition name="conversation">
      <div class="conversation h-full w-full bg-green-light1 fixed top-0 pt-14 z-40 flex justify-between flex-col" v-if="conversationIsOpen">
        <div class="bg-orange-primary px-4 py-3 text-white flex items-center">
          <div class="text-white w-6" @click="toggleConversation">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </div>
          <div class="ml-4">
            {{ t('conversation.titre') }}
          </div>
        </div>

        <ConversationMessageList :messages="messages" />

        <ConversationMessageForm></ConversationMessageForm>
      </div>
    </transition>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useApp } from "@composables/app";
import { useUser } from "@composables/user";
import { useConversation } from "@composables/conversation";
import ButtonConversation from "@components/Buttons/ButtonConversation";
import ConversationMessageList from "@components/Conversation/ConversationMessageList";
import ConversationMessageForm from "@components/Conversation/ConversationMessageForm";

export default {
  components: {
    ButtonConversation,
    ConversationMessageList,
    ConversationMessageForm,
  },

  setup() {
    const { t } = useI18n();
    const { currentPromotionId } = useUser();
    const { messages, initConversation } = useConversation();

    const { toggleConversation, conversationIsOpen } = useApp();

    onMounted(() => {
      // Init conversation = Load all approved messages from the conversation then subscribe for new ones.
      initConversation(currentPromotionId.value, "approved");
    });

    return {
      conversationIsOpen,
      messages,

      t,
      toggleConversation,
    };
  },
};
</script>

<style scoped>
.conversation-enter-from,
.conversation-leave-to {
  @apply translate-x-0;
}

.conversation-enter-active,
.conversation-leave-active {
  transition: all 0.5s ease;
}

.conversation-enter-from,
.conversation-leave-to {
  transform: translateX(100%);
}
</style>
