import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client/core'
import {onError} from "@apollo/client/link/error";
import {useAuth} from "../composables/auth";
import { split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";

const errorLink = onError(({ graphQLErrors, networkError, forward, operation}) => {
    if (graphQLErrors)

        graphQLErrors.map( async (error) => {

        if(error.extensions.code == 'invalid-jwt') {

            const {refreshToken, logout} = useAuth();
            let success = await refreshToken()
            if(success) {
                return forward(operation);
            }else {
                await logout();
            }

        }else {
            console.log(`[GraphQL error]: Message: ${error.message}, Code: ${error.extensions.code}, Path:  ${error.extensions.path}`,)
        }
    })

    if (networkError) console.log(`[Network error]: ${networkError}`)
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
let client

const getHeaders = () => {
    const headers = {}
    const token = localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
    if (token) {
        headers.Authorization = `Bearer ${token}`
    }
    return headers
};

const createClient = () => {
    const httpLink = createHttpLink({
        // You should use an absolute URL here
        uri: process.env.VUE_APP_HASURA_API,
        headers: getHeaders()
    });

    const wsLink = new WebSocketLink({
        uri: process.env.VUE_APP_HASURA_WS,
        options: {
            reconnect: true,
            connectionParams: () => {
                return { headers: getHeaders() }
            },
        },
    });

    const link = split(
        // split based on operation type
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        wsLink,
        httpLink
    )

    const additiveLink = from([
        errorLink,
        link,
    ])

    client = new ApolloClient({
        link: additiveLink,
        cache,
    })

}

export {client, createClient}
