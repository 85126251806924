<template>
  <div class="bg-green-light2 flex flex-col">
    <div class="bg-red-error text-xs p-2 text-white" v-if="error">
      <p>{{ error }}</p>
    </div>

    <div class="py-2">
      <ConversationReplyTo :replyTo="replyTo" v-if="replyTo" />

      <div class="flex">
        <TextareaAutoResize :placeholder="t('conversation.textbox_placeholder')" class="flex-grow" v-model="contentMessage" :maxSize="108" ref="form" @click="handleScrollTo" />
        <div class="text-white ml-2 mr-3 self-end" @click="handleSubmitMessage">
          <svg width="30" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.9793 0.499991C29.7208 0.238579 2.9462 10.6428 0.465149 11.6884C-1.13719 12.3681 1.70567 13.9365 2.11918 14.0934C3.89441 14.8724 5.74918 15.451 7.64985 15.8187C7.74298 15.8411 7.82931 15.8862 7.90136 15.95C7.97341 16.0137 8.02901 16.0943 8.06336 16.1847C8.52855 18.1714 9.76908 23.8179 10.286 25.125C11.0613 27.1117 14.6278 22.72 15.7133 21.3606C15.8 21.2562 15.9218 21.1877 16.0553 21.1684C16.1888 21.1492 16.3247 21.1804 16.4369 21.2561L22.1743 25.7524C24.7071 27.6345 25.5341 25.3864 25.6374 25.0727V25.0204C25.8442 24.0845 29.9793 0.557501 29.9793 0.499991ZM27.3432 3.4278L13.0771 18.0669C13.0456 18.0917 13.02 18.1234 13.0021 18.1596C12.9842 18.1958 12.9745 18.2355 12.9738 18.276L11.7849 23.2428C11.7791 23.3183 11.7469 23.3892 11.6941 23.4428C11.6413 23.4965 11.5714 23.5294 11.4968 23.5357C11.4222 23.542 11.3478 23.5212 11.287 23.4772C11.2261 23.4331 11.1828 23.3685 11.1647 23.2951L9.40726 15.2959C9.39231 15.2617 9.38447 15.2248 9.38425 15.1874C9.38404 15.1501 9.39143 15.1131 9.40598 15.0787C9.42053 15.0444 9.44193 15.0135 9.46883 14.9879C9.49572 14.9622 9.52755 14.9425 9.56232 14.9299C11.2164 13.7797 22.8463 5.67594 27.1881 3.16639C27.3122 3.06705 27.4672 3.32846 27.3638 3.43303L27.3432 3.4278Z" fill="#A74004"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from 'vue'
import { useI18n } from "vue-i18n";
import { useConversation } from "@composables/conversation"
import { scrollTo } from "@/helpers"
import TextareaAutoResize from "@components/Commons/TextareaAutoResize"
import ConversationReplyTo from "@components/Conversation/ConversationReplyTo"

export default {
  components: {
    TextareaAutoResize,
    ConversationReplyTo
  },

  setup() {
    const { t } = useI18n();
    const { loading, error, insertMessage, replyTo } = useConversation()

    const contentMessage = ref("")
    const form = ref()
    
    const handleSubmitMessage = async () => {
      if(!loading.value && contentMessage.value.length > 0) {
        const response = await insertMessage(contentMessage.value)

        if(response.success) {
          // If the message has been sent correctly, then reset the form.
          contentMessage.value = ""
          form.value.resetSize()

          setTimeout(function() {
             handleScrollTo()
          }, 800)
        }
      }
    }

    const handleScrollTo = () => {
      const element = document.querySelector('#conversationMessageList')
      scrollTo(element, element.scrollHeight, 300)
    }

    return {
      form,
      contentMessage,
      loading,
      error,
      replyTo,

      t,
      handleScrollTo,
      handleSubmitMessage,
    }
  }
};
</script>
