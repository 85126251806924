<template>
  <div class="w-full">

    <!--Top header-->
    <div class="bg-orange-primary px-4 py-3 text-white flex items-center">
      <router-link :to="{name: 'Home', params: {transition: 'left-right'}}">
        <div class="text-white w-6" >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </div>
      </router-link>
      <div class="ml-4">
        {{module.title}}
      </div>
    </div>

    <transition name="fade" mode="out-in">

      <div v-if="loading" class="flex justify-center py-20">
        <Loader />
      </div>

      <div v-else class="bg-green-light2">
        <div  class="flex items-center px-4 pt-6">

          <div  class="flex">
            <div class="text-orange-primary relative flex-shrink-0">
              <div class="text-sm  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pt-1"
                 :class="!sequence.score && !sequence.finished ? 'text-white' : 'text-orange-dark'">
                {{sequence.score ? sequence.score+'%' : (sequence.finished ? '100%' : '-') }}
              </div>
              <ProgressRing radius="32" stroke="4" :fill="!sequence.score && !sequence.finished" :progress="sequence.score ?? (sequence.finished ? 100 : 0)"></ProgressRing>
            </div>
          </div>
          <div class="text-red-primary text-lg pl-4">
            {{sequence.title}}
          </div>
        </div>

        <!-- Sequence intro text  -->
        <div class="text-green-dark px-4 mt-10">
          <span v-if="!sequence.score && !sequence.finished">{{ t('sequence.message')}}</span>
          <span v-else-if="sequence.score >= 80 || sequence.finished">{{ t('sequence.message_termine')}}</span>
          <span v-else>{{ t('sequence.message_en_cours')}}</span>

        </div>


        <div class="mt-10 px-4">

          <div v-for="(media, index) in medias" :key="index">
            <VideoContainer v-if="media.mediaType.slug == 'file_video'" :path="media.path" :videoId="media.id" @mediaUpdated="mediaUpdated" />

            <ImgContainer v-else-if="media.mediaType.slug == 'file_image'" :path="media.path" :imgId="media.id" />

<!--            Pour le moment c'est forcmeent un pdf, plus tard on aura un type calendly -->
            <ButtonDocument v-else
                  :current="media.current" :path="media.path" :mediaId="media.id" :disabled="! media.available" @mediaUpdated="mediaUpdated">
              {{media.title}}
            </ButtonDocument>
  <!--          <ButtonDocument :current="true" type="link">Présentation Genial.ly</ButtonDocument>-->
  <!--          <ButtonDocument :disabled="true">Autre document</ButtonDocument>-->
  <!--          <ButtonDocument :disabled="true">Dernier document à consulter</ButtonDocument>-->
          </div>
        </div>

        <div class="mt-10 px-4">
          <div v-for="(scenario, index) in scenarios" :key="index">
            <ButtonEvaluation
                v-if="scenario.scenarioType.slug == 'final_test'"
                :current="scenario.current"
                :disabled=" ! scenario.available"
                :progress="scenario.score ?? null"
                :url="router.resolve({name: 'Evaluation', params: {sequence_id: sequence.id, scenario_id: scenario.id}}).fullPath">
              {{ scenario.title }}
            </ButtonEvaluation>

            <ButtonQuiz
                v-else
                :current="scenario.current"
                :disabled=" ! scenario.available"
                :url="router.resolve({name: 'Scenario', params: {sequence_id: sequence.id, scenario_id: scenario.id }}).fullPath">
                <span v-if="scenario.current || !scenario.available" class="mr-4">{{ scenario.lastVisitedNode || 0 }}/{{ scenario.nodes_aggregate.aggregate.count }}</span>
                <span>{{ scenario.title }}</span>
            </ButtonQuiz>

          </div>
  <!--        <ButtonQuiz :sequence="sequence" :current="true">Quiz</ButtonQuiz>-->
  <!--        <ButtonQuiz :disabled="true" :sequence="sequence">Quiz</ButtonQuiz>-->
  <!--        <ButtonEvaluation :disabled="true" :sequence="sequence">Evaluation</ButtonEvaluation>-->
  <!--        <ButtonEvaluation :current="true" :sequence="sequence">Evaluation</ButtonEvaluation>-->
  <!--        <ButtonEvaluation :sequence="sequence">Evaluation</ButtonEvaluation>-->
        </div>

      </div>

    </transition>


  </div>



</template>

<script>
import ProgressRing from "@components/Commons/ProgressRing"

import {useModule} from  '@composables/module'
import {useRoute, useRouter} from "vue-router"
import Loader from "@components/Loader";
import VideoContainer from "@components/Commons/VideoContainer"
import ImgContainer from "@components/Commons/ImgContainer"
import ButtonDocument from "@components/Buttons/ButtonDocument";
import ButtonQuiz from "@components/Buttons/ButtonQuiz";
import ButtonEvaluation from "@components/Buttons/ButtonEvaluation";
import {computed, ref} from "vue";
import {useLogs} from "../composables/logs";
import {useI18n} from "vue-i18n";
export default {

  components: {ButtonDocument, VideoContainer, ButtonQuiz, ButtonEvaluation, ImgContainer, Loader, ProgressRing},

  setup(){
    const loading = ref(true)
    let route = useRoute()
    const router = useRouter()
    const {t} = useI18n()
    const {
      getSequence,
      getModuleBySequenceId,
      currentSequence: sequence,
      setCurrentSequenceId,
      setCurrentModuleId,
      markSequenceAsFinished,
    }= useModule();

    const module = getModuleBySequenceId(route.params.id)

    setCurrentModuleId(module.id)
    setCurrentSequenceId(route.params.id)

    const medias = computed( () =>  {
        return sequence?.value?.medias?.map( m => m.media) || []
    })
    const scenarios = computed( () =>  {
        return sequence?.value?.scenarios?.map( m => m.scenario) || []
    })
    const hasEvaluation = computed( () =>  {
        return !!scenarios?.value?.find(scenario => scenario.scenarioType.slug === 'final_test')
    })
    const hasFinishedAllScenarios = computed( () =>  {
        return !scenarios?.value?.find(scenario => !scenario.finished)
    })

    const initSequence =  async(sequence_id) => {
        await getSequence(sequence_id)
        
        // Mark sequence has finished if needed and reload his data
        if (sequence.value && !sequence.value.finished && !hasEvaluation.value && hasFinishedAllScenarios.value) {
          await markSequenceAsFinished(sequence.value.id)
          await getSequence(sequence_id)
        }

        loading.value = false
    }

    const {createLog} = useLogs()

    const mediaUpdated = async (media_id, mutation_type) => {
        // console.log(media_id)
        await createLog(media_id, sequence.value.id, mutation_type)
        await initSequence(sequence.value.id)
    }

    initSequence(sequence.value.id)

    return {t, loading, module, sequence, scenarios, medias, router, mediaUpdated, hasEvaluation, hasFinishedAllScenarios}

  }
}
</script>