<template>

  <div class="bg-orange-primary px-4 py-3 text-white flex items-center">
    <router-link :to="{name: 'Sequence', params: {id: sequence.id, transition: 'left-right'}}">
      <div class="text-white w-6" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </div>
    </router-link>
    <div class="ml-4">
      <div class="text-sm opacity-50">{{module.title}}</div>
      <div>{{sequence.title}}</div>
    </div>
  </div>
  <div class="bg-green-light2 py-10 text-green-dark text-center text-2xl px-4">
    <div>
      <span v-if="scenarioIsEvaluation"  class="font-title font-bold">{{ t('scenario.nom_quiz_evaluation') }}</span>
      <span v-else  class="font-title font-bold">{{ t('scenario.nom_quiz') }}</span>
      <span class="ml-1 font-title text-orange-primary">{{currentNode}}</span>/{{scenarioNodes.length}}
    </div>
    <div class="flex max-w-screen-xs mt-8 mx-auto">
      <span
        v-for="(node, index) in scenarioNodes"
        :key="node.id"
        class="flex-1 h-1 rounded-full mx-1"
        :class="(index < currentNode ? 'bg-orange-primary' : 'bg-grey-light')"
      ></span>
    </div>
    <div v-if="currentNodeType == 'file_video' ">
      <div class="mt-8 text-base">{{ t('scenario.message_video') }}</div>
    </div>
    <div v-if="currentNodeType == 'file_pdf' ">
      <div class="mt-8 text-base">{{ t('scenario.message_pdf') }}</div>
    </div>
    <div v-if="currentNodeType == 'true_false'">
      <div v-if="currentQuiz.length > 1" class="mt-8 text-base">{{ t('scenario.message_true_false') }}</div>
      <div v-else class="mt-8 text-base">{{ t('scenario.message_true_false_unique') }}</div>
    </div>
    <div v-if="currentNodeType == 'quiz_simple'">
      <div v-if="currentQuiz.length > 1" class="mt-8 text-base">{{ t('scenario.message_qcm') }}</div>
      <div v-else class="mt-8 text-base">{{ t('scenario.message_qcm_unique') }}</div>
      <div class=" text-base text-orange-primary">{{ t('scenario.message_qcm_orange') }}</div>
    </div>
    <div v-if="currentNodeType == 'drag_drop'">
      <div class="mt-8 text-base">{{ t('scenario.message_drag_drop') }}</div>
      <div class=" text-base text-orange-primary">{{ t('scenario.message_drag_drop_orange') }}</div>
    </div>
    <div v-if="currentNodeType == 'reorder'">
      <div class="mt-8 text-base">{{ t('scenario.message_reorder') }}</div>
    </div>
    <div v-if="currentNodeType == 'presentation_genialy'">
      <div class="mt-8 text-base">{{ t('scenario.message_genialy') }}</div>
    </div>
  </div>
</template>


<script>
import {useScenario} from '@composables/scenario'
import {computed} from "vue";
import {useRoute} from "vue-router";
import {useModule} from "@composables/module";
import {useI18n} from "vue-i18n";

export default {
  setup() {
    const {t} = useI18n()
    const route = useRoute()

    const {getModuleBySequenceId} = useModule()

    const module = getModuleBySequenceId(route.params.sequence_id)
    const sequence = computed( () => module.sequences.find(sequence => sequence.id == route.params.sequence_id))

    const {
      scenarioNodes,
      currentNode,
      currentNodeType,
      currentQuiz,
      scenarioIsEvaluation
    } = useScenario()

    return {t, scenarioIsEvaluation, scenarioNodes, currentNode, currentNodeType, sequence, module, currentQuiz}
  }
}
</script>