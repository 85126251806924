import {computed, reactive, ref} from "vue";

const state = reactive([{}])

const useQuizTrueFalse = () => {

    /**
     * @type {Ref<UnwrapRef<boolean>>}
     * @return boolean
     */
    const showResponse = ref(false)

    /**
     * @type {ComputedRef<*>}
     * @return state
     */
    const quiz = computed( () => state.value)

    /**
     * Set new quizTrueFalse
     * @param quiz
     */
    const setQuizTrueFalse = quiz => {
        state.value = quiz
        showResponse.value = false
    }

    /**
     * If the quiz can be submited
     * @type {ComputedRef<boolean>}
     * @return bool
     */
    const canSubmit = computed( () => {
        // si il y a des choix non répondu, on block
        if(quiz.value.find(q => !q.value)) {
            return false
        }
        return true
    })

    /**
     *
     * @returns {boolean}
     */
    const submit = () => {
        if(canSubmit.value === true) {
            showResponse.value = true
        }
        return false
    }


    return {
        quiz,
        setQuizTrueFalse,
        showResponse,
        canSubmit,
        submit
    }
}

export {useQuizTrueFalse}