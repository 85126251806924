import {reactive, computed} from "vue";

/**
 * State quiz
 *
 * @param quiz Array
 *      name String
 *      value String
 *      label String
 *      answer String
 *      answerDetail String
 *
 */
const state = reactive({
    quiz: null,
    showResponse: false
})

const useQuizOrder = () => {


    /**
     * @type {ComputedRef<*>}
     * @return state
     */
    const quiz = computed( () => state.quiz)

    const showResponse = computed( () => state.showResponse)

    /**
     * Set new quizTrueFalse
     * @param quiz
     */
    const setQuizOrder = quiz => {
        state.quiz = quiz
        state.showResponse = false
    }

    // the ordered response of the user
    const response = computed( () => {
        return quiz.value
    })

    const canPushUp =  (field) => {

        if(showResponse.value == true) return false
        if(quiz.value.response.indexOf(field) == 0) return false
        return true
    }
    const canPushDown = (field) => {
        if(showResponse.value == true) return false
        if(quiz.value.response.indexOf(field) == quiz.value.response.length - 1) return false
        return true
    }

    const swipe = (indexA, indexB) => {
        let temp = quiz.value.response[indexA];
        quiz.value.response[indexA] = quiz.value.response[indexB];
        quiz.value.response[indexB] = temp;
    }

    const pushDown = (item) => {
        if( ! canPushDown(item) ) return
        swipe(
            quiz.value.response.indexOf(item),
            quiz.value.response.indexOf(item) + 1
        )
    }

    const pushUp = (item) => {
        if( ! canPushUp(item)) return
        swipe(
            quiz.value.response.indexOf(item),
           quiz.value.response.indexOf(item) - 1
        )
    }

    const validateResponse = (isEvaluation) => {
        let response = quiz.value.response
        let answer = quiz.value.answer
        response.forEach((resp, index) => {
            if(resp.id != answer[index].id) {
                resp.error = true
            }
        })
        // on affiche les resultats
        if( ! isEvaluation) {
            state.showResponse = true
        }
    }

    const submit = (isEvaluation) => {
        validateResponse(isEvaluation)
    }

    return {
        quiz,
        setQuizOrder,
        showResponse,
        response,
        pushDown,
        pushUp,
        canPushUp,
        canPushDown,
        submit
    }
}

export {useQuizOrder}