<template>
  <div class="w-full bg-green-light2 min-height-app" >

    <!--Top header-->
    <div class="bg-orange-primary px-4 py-3 text-white flex items-center">
      <router-link :to="{name: 'Sequence', params: {id: currentSequence.id, transition: 'left-right'}}">
        <div class="text-white w-6" >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </div>
      </router-link>
      <div class="ml-4">
        <div class="text-sm opacity-50">{{module.title}}</div>
        <div>{{currentSequence.title}}</div>
      </div>
    </div>


      <div class=" py-10 text-green-dark text-center text-2xl">
        <div class="font-title font-bold">{{ t ('score.titre')}}</div>
     </div>

    <div class="my-0 mx-6 " v-if="scenarioResult">

      <div class="relative flex justify-center" :class="colorScore">
        <div
            class="text-7xl font-title font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-colors duration-200 ease-in pt-3 opacity-50">
            {{  Math.round(progressScore) }}%
        </div>

        <ProgressRing stroke="16" :progress="progressScore" :radius="200" :height="300" :width="300"></ProgressRing>
      </div>
      <transition name="fade">

      <div v-if="showResult">

        <div class="max-w-sm mx-auto text-center mt-6 mb-10">
          <div v-if="scenarioResult.score >= 80" class="text-green-dark">
              <div class="text-orange-primary  text-2xl">{{ t('score.titre_reussite') }}</div>
              <div class="mt-2">{{ t ('score.message_reussite')}}</div>
          </div>
          <div v-else >
            <div class="text-green-dark text-2xl">{{ t('score.titre_echec') }}</div>
            <div class="text-green-dark mt-2" v-html=" t('score.message_echec')"></div>
          </div>
        </div>

        <div v-if="canConsultCorrection"
            @click="consultCorrection"
            class="rounded-lg text-lg font-bold  px-5 py-4 my-4 min-h-20 flex w-full items-center bg-orange-primary text-white cursor-pointer" style="min-height: 75px">
          <div class="flex items-center w-full">
            <div class="mr-4 ">
              {{t('score.consulter_correction') }}
            </div>
            <div  class="flex-shrink-0 ml-auto border-2 border-white  rounded-full px-2 py-2 text-white">

              <div class="w-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </div>

            </div>
          </div>
        </div>

        <div @click="validateScore" class="text-orange-primary cursor-pointerflex flex justify-center mx-auto items-center py-8  cursor-pointer">

          <div v-if="scenarioResult.score < 80">{{ t('button.ressayer_evaluation') }}</div>
          <div v-else-if="hasFinishedModule && hasDoneAllModule">{{ t('button.retour_accueil') }}</div>
          <div v-else-if="hasFinishedModule">{{ t('button.module_suivant') }}</div>
          <div v-else-if="scenarioResult.score >= 80">{{ t('button.sequence_suivante') }}</div>
          <div v-else>{{ t('button.ressayer_evaluation') }}</div>

          <div  class="flex-shrink-0 ml-4 border-2  rounded-full px-2 py-2 border-orange-primary">
            <div class="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </div>
          </div>

        </div>

      </div>
      </transition>


    </div>


  </div>
</template>

<script>

import ProgressRing from "@components/Commons/ProgressRing";
import {useRoute, useRouter} from "vue-router";
import {useLogs} from "@composables/logs";
import {useModule} from "@composables/module";
import {computed, ref, watchEffect} from "vue";
import {useScenario} from "@composables/scenario";
import {useUser} from "@composables/user";
import {useI18n} from "vue-i18n";

export default {

  components: {ProgressRing},

  setup() {
    const {t} = useI18n()
    const route = useRoute()
    const router = useRouter()
    const {getLogsSequence, createLog, logs} = useLogs()
    const {getSequence, initModules, getModuleBySequenceId} = useModule()
    const {currentNode: scenario, scenarioCorrection} = useScenario()
    const {currentPromotionId, user} = useUser()
    const hasDoneAllModule = ref();

    const scenarioResult = ref()
    const sequence = ref()
    const hasFinishedModule = ref()
    const module = getModuleBySequenceId(route.params.sequence_id)

    getSequence(route.params.sequence_id)

    const currentSequence = computed( () => module.sequences.find(sequence => sequence.id == route.params.sequence_id))

    // todo, refactorer ce composant, c'est le bordel
    const validateScore = async () => {

      if(scenarioResult.value.score >= 80) {
        if(hasFinishedModule.value) {
            // on passe sur le prochain module
            // todo, si c'est le dernier module, attention
            const { currentModule, modules, setCurrentModuleId } = useModule()
            if(modules.value[modules.value.indexOf(currentModule.value) + 1] ) {
              let nextModule = modules.value.find(m => m.id == modules.value[modules.value.indexOf(currentModule.value) + 1].id )
              setCurrentModuleId(nextModule.id)
              hasDoneAllModule.value = true
            }
            return router.push({name: 'Home'})
        }else {
          // console.log('passer a la sequence suivante')
          // recuperer la sequence suivante
          // console.log(currentSequence.value)
          let nextSequence = module.sequences[module.sequences.indexOf(currentSequence.value) + 1]
          return router.push({name: 'Sequence', params: {id: nextSequence.id }})
        }
      } else {
          return router.push({name: 'Evaluation', params: {sequence_id: route.params.sequence_id, scenario_id: route.params.scenario_id}})
      }
      // on passe la sequence en terminé
    }


    const saveFinishedSequence = async () => {
      const {currentModule, modules} = useModule()

      if(scenarioResult.value.score >= 80) {
        await createLog(route.params.sequence_id, route.params.scenario_id, 'sequence_end')
      }

      // on recupére les id de toutes les sequences terminé
      let sequencesFinished = logs.value.map(l => {
        if (l.data_key && l.logType.slug == "sequence_end") {
          return l.data_key
        }
      }).filter(x => x)

      // if toutes les sequences sont terminé
      hasFinishedModule.value = true

      // si on a pour chaque sequence de module, un seuqence_end provenant des logs, alors on a tout terminé
      if(!currentModule.value) return
      currentModule.value.sequences.forEach(s => {
        let sequenceId = s.id
        if( ! sequencesFinished.includes(sequenceId)) {
          hasFinishedModule.value = false
        }
      })

      // et dans ce cas on passe le module en terminé
      if(hasFinishedModule.value) {
        await createLog(currentModule.value.id, null, 'module_end')
      }

      // comparer le nombre total de modules avec le nombre total de module_end en logs
      if(modules.value.length <= logs.value.filter(l => l.data_key && l.logType.slug == "module_end").length ) {
        hasDoneAllModule.value = true
      }


      await initModules( currentPromotionId.value, user.value.id)

    }

    const hasCorrection = computed( () => {
      return scenarioCorrection.value
    })

    const canConsultCorrection = computed( () => {
      return hasCorrection.value && scenarioResult.value.score >= 80
    })

    const consultCorrection = () => {
      if(canConsultCorrection.value) {
          window.open(process.env.VUE_APP_ASSETS_URL + scenarioCorrection.value.path)
      }
    }

    const initScoreScreen = async (scenario_id) => {

      await getSequence(route.params.sequence_id)
      sequence.value = getLogsSequence(route.params.sequence_id)

      let scenarioLogs = sequence.value.filter(sc => sc.data_key === scenario_id && sc.logType.slug == "scenario_end")
      // todo convert into computed inside logs compsables
      if(scenarioLogs[0]) {
        let maxDateLog = null
        scenarioLogs[0].data.results.forEach(log => {

          if( ! maxDateLog) {
            maxDateLog = log
          }else {
            if(new Date(log.date) > new Date(maxDateLog.date)) maxDateLog = log
          }
        })
        scenarioResult.value = maxDateLog
      }

      await saveFinishedSequence()

    }

    const scenario_id = route.params.scenario_id

    initScoreScreen(scenario_id)

    const progressScore = ref(0)
    const colorScore = ref('text-orange-primary')
    const showResult = ref(false)
    watchEffect( () => {
      if(scenarioResult.value) {
        const limit = scenarioResult.value.score
        if(!  scenarioResult.value.score == 0) {
          let progressInterval = setInterval( () => {
            progressScore.value += .5
            if( progressScore.value >= 80) {
              colorScore.value = 'text-green-primary'
            }
            if(progressScore.value == limit) {
              clearInterval(progressInterval)
              showResult.value = true
            }
          }, 15);
        }else {
          showResult.value = true
        }

      }
    })


    return{t, showResult, scenarioResult, hasDoneAllModule, colorScore, progressScore,  module, router, currentSequence, sequence, scenario, validateScore, consultCorrection,canConsultCorrection, hasFinishedModule}

  }
}
</script>