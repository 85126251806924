<template>
  <div class="px-10">
    <div class="text-green-dark text-center text-lg leading-relaxed" v-html="t('premiere_connexion.consignes_questionnaire')"></div>

    <div v-if="formErrors && formErrors.length" class="mt-4 bg-orange-dark border-t-4 border-red-primary rounded-b text-white px-4 py-3 shadow-md" role="alert">
      <div class="flex item-start flex-col">
        <div v-for="(error, index) in formErrors" :key="index">
          <p class="font-bold">{{error.message}}</p>
        </div>
      </div>
    </div>

    <div v-for="(field, index) in form" :key="index" class="input-field my-4">

      <!-- input type text-->
      <div v-if=" field.type == 'email' || ! field.type ">
        <label>{{index+1}}. {{ field.label }}</label>

        <input :disabled="isLoading" class="input w-full" :required="field.required" type="email" v-model="field.value">
      </div>

      <!-- input type radio -->
      <div v-if=" field.type == 'radio' ">

        <label class=" text-green-primary">{{index+1}}. {{ field.label }}</label>
        <div class="radio-group flex justify-around my-4" :class="field.responses.length == 2 ? 'flex-raw': 'flex-col' ">

          <div v-for="(response, index) in field.responses"  :key="index" class="radio-item" :class="{'mb-3' : field.responses.length > 2 }">
            <input :id="field.name + '_' + response.value" type="radio" :required="field.required" :name="field.name" :value="response.value" v-model="field.value" class="hidden" />
            <label :for="field.name + '_' + response.value" :class="{ 'inline' : field.responses.length > 2 }">
              {{ response.label }}
              <span class="circle-radio"></span>
            </label>
          </div>

        </div>

      </div>
    </div>
  </div>


</template>


<script>
import {useFirstConnexion} from "@composables/firstConnexion";
import {useI18n} from "vue-i18n";
import dataForm from "@/config/text"
import {ref} from "vue";

export default {

  setup() {
    const {t} = useI18n()
    let formData = dataForm.fr.premiere_connexion.form.fields.map(d => {d.value = ''; return d })
    const {
      formErrors,
      setForm,
      form
    } = useFirstConnexion()
    setForm(formData)

    const isLoading = ref(false)

    return {t, isLoading, form, formErrors}
  }

}
</script>