<template>
  <svg width="230px" height="230px" viewBox="0 0 230 230" xmlns="http://www.w3.org/2000/svg">
    <path data-id="1" class="wheel__segment segment__1" d="M33.6827 196.317C55.2494 217.884 84.5001 230 115 230C145.5 230 174.751 217.884 196.317 196.317L115 115L33.6827 196.317Z" fill="#E8560E"/>
    <path data-id="2" class="wheel__segment segment__2" d="M196.317 196.317C217.884 174.751 230 145.5 230 115C230 84.5001 217.884 55.2494 196.317 33.6827L115 115L196.317 196.317Z" fill="#A74004"/>
    <path data-id="3" class="wheel__segment segment__3" d="M196.317 33.6827C174.751 12.116 145.5 0 115 0C84.5001 0 55.2494 12.116 33.6827 33.6827L115 115L196.317 33.6827Z" fill="#9C3100"/>
    <path data-id="4" class="wheel__segment segment__4" d="M33.6827 33.6827C12.116 55.2494 0 84.5001 0 115C0 145.5 12.116 174.751 33.6827 196.317L115 115L33.6827 33.6827Z" fill="#3C0D06"/>

    <path class="wheel__text text__1" d="m115.36 212h1.728v-12.744h-1.53l-3.546 2.934 1.008 1.206 2.34-2.16v10.764z" fill="#fff"/>
    <path class="wheel__text text__2" d="m212.5 119.17v-8.352h-1.512v6.408l-4.05-4.194c-1.098-1.134-2.304-2.214-3.978-2.214-2.34 0-3.528 1.818-3.528 4.014s1.062 3.87 3.312 4.212l0.162-1.872c-1.206-0.216-1.962-1.08-1.962-2.322 0-1.206 0.774-2.196 2.034-2.196 1.044 0 1.926 0.702 2.61 1.404l5.076 5.112h1.836z" fill="#fff"/>
    <path class="wheel__text text__3" d="m119.67 20.556c-0.576-1.926-2.16-2.772-4.104-2.772-2.25 0-4.248 1.242-4.248 3.69 0 1.566 0.9 2.934 2.502 3.204v0.036c-1.296 0.378-2.178 1.548-2.178 2.88 0 2.358 1.818 3.474 3.906 3.474 1.692 0 3.186-0.774 3.852-2.394l-1.764-0.594c-0.378 0.882-1.188 1.476-2.124 1.476-1.224 0-2.142-0.72-2.142-2.07 0-1.26 0.63-1.998 3.258-1.998v-1.512c-1.62 0-3.582-0.252-3.582-2.376 0-1.368 1.134-2.304 2.466-2.304 1.35 0 1.926 0.594 2.34 1.818l1.818-0.558z" fill="#fff"/>
    <path class="wheel__text text__4" d="m18.5 116.05v1.728h2.808v1.8h1.512v-1.8h8.424v-2.088l-8.172-5.076h-1.764v5.436h-2.808zm4.32 0v-3.924l6.372 3.888v0.036h-6.372z" fill="#fff"/>
  </svg>
</template>

<script>
  export default {}
</script>