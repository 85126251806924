import {computed, reactive} from "vue";
import { useUser } from "./user";
import { client as ApolloClient } from "../config/clients";

const state = reactive({
    menuIsOpen: false,
    transitionName: 'right-left',
    avatarModalIsOpen: false,
    conversationIsOpen: false
})

const useApp = () => {

    const menuIsOpen = computed ( () => {
        return state.menuIsOpen
    })

    const toggleMenu = () => {
        state.menuIsOpen = ! state.menuIsOpen
    }

    const toggleAvatarModal = () => {
        // console.log('ndwkdfnk')
        state.avatarModalIsOpen = ! state.avatarModalIsOpen
    }

    const avatarModalIsOpen = computed( () => {
        return state.avatarModalIsOpen
    })

    const transitionName = computed( () => {
        return state.transitionName
    })

    const setTransition = (name) => {
        state.transitionName = name
    }

    const toggleConversation = async () => {
        const { setUser, user, userId } = useUser()

        // Update notifications
        let response = await ApolloClient.mutate({
          mutation: require("@gql/user/update_user_last_seen_conversation.gql"),
          variables: {
            userId: userId.value,
          },
        })

        if (response.data.update_users.returning[0].last_seen_conversation) {
            let data = {
                last_seen_conversation: response.data.update_users.returning[0].last_seen_conversation
            }
            setUser({ ...user.value, ...data });
        }

        state.conversationIsOpen = !state.conversationIsOpen

        const body = document.querySelector("body")
        body.style.overflowY = state.conversationIsOpen ? "hidden" : "scroll"
    }

    const conversationIsOpen = computed(() => {
      return state.conversationIsOpen
    })

    return {menuIsOpen, toggleMenu, transitionName, setTransition, toggleAvatarModal, avatarModalIsOpen, toggleConversation, conversationIsOpen}
}

export {useApp}