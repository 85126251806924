<template>
  <div
    class="bg-green-primary relative flex items-center justify-center cursor-pointer"
    @click="isPlaying ? pauseVideo() : playVideo()"
  >
<!--      <div v-show=" ! isPlaying" @click.prevent="playVideo" class=" h-10 w-10 bg-green-light">dsqdqsdqsdsqdsqds</div>-->
    <transition name="fade" mode="out-in">
        <div v-if="! canPlay" class="playButton z-10 z-99 cursor-pointer absolute block ">
            <Loader color="white" />
        </div>
        <div v-else-if="!isPlaying" @click.stop="playVideo" id="playButton"  class="playButton z-10 z-99 cursor-pointer absolute block ">
          <svg   width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25" cy="25" r="25" fill="white"/>
            <path d="M37.6258 24.1081L20.2691 13.0191C19.0709 12.2537 17.5 13.1142 17.5 14.536V25.625V36.714C17.5 38.1358 19.0709 38.9963 20.2691 38.2309L37.6258 27.1419C38.7337 26.434 38.7337 24.816 37.6258 24.1081Z" fill="#3D7F7F"/>
          </svg>
        </div>
    </transition>

    <div v-show="hasStarted" :id="'video-controls_'+idForVideo" class="px-3 controls absolute z-10 bottom-3 w-full flex items-center cursor-default" data-state="hidden" @click.stop>

      <div class="pauseButton cursor-pointer flex-shrink-0">
        <svg v-if="isPlaying" @click.stop="pauseVideo"  width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13" cy="13" r="13" fill="white"/>
          <rect x="8.89474" y="8.4502" width="3.07895" height="9.75" rx="1.53947" fill="#3D7F7F"/>
          <rect x="14.0263" y="8.4502" width="3.07895" height="9.75" rx="1.53947" fill="#3D7F7F"/>
        </svg>
        <svg v-else @click.stop="playVideo" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13" cy="13" r="13" fill="white"/>
          <path d="M18.4258 11.8082L11.8691 7.61925C10.6709 6.85375 9.10001 7.71428 9.10001 9.1361V13.3251V17.5141C9.10001 18.9359 10.6709 19.7964 11.8691 19.031L18.4258 14.842C19.5337 14.1341 19.5337 12.516 18.4258 11.8082Z" fill="#3D7F7F"/>
        </svg>
      </div>

      <div class="progress w-full mx-4 cursor-pointer">
        <progress :id="'progress_'+idForVideo" value="0" min="0" ></progress>
      </div>

      <div @click.stop="fullScreen" class="fullScreenButton cursor-pointer flex-shrink-0 ">
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.922974 4.56299C0.922974 2.35385 2.71383 0.562988 4.92297 0.562988H23C25.2092 0.562988 27 2.35385 27 4.56299V23.0001C27 25.2092 25.2092 27.0001 23 27.0001H4.92298C2.71384 27.0001 0.922974 25.2092 0.922974 23.0001V4.56299Z" fill="#3D7F7F"/>
          <path d="M11.9635 8.24316L8.60315 8.24316L8.60315 11.6035" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.60315 15.9595L8.60315 19.3198L11.9635 19.3198" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.9595 19.3198H19.3198V15.9595" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.3198 11.6035V8.24316H15.9595" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>

    <video  :id="idForVideo" playsinline preload="auto" class="w-full pointer-event-none z-0">
      <source :src="mediaPath"
              type="video/mp4">

      Sorry, your browser doesn't support embedded videos.
    </video>

  </div>

</template>


<script>
import {computed, nextTick, onBeforeUnmount, ref} from "vue";
import {makeId} from "@/helpers";
import Loader from "@components/Loader"

export default {
  emits: ['mediaUpdated'],
  props: {
    path: {
      type: String,
      default: ""
    },
    minPercent : {
      type: Number,
      default: 90
    },
    videoId : {
      type: [Number, String],
      default: () => {
        return makeId(10)
      }
    }
  },
  components: {Loader},

  setup (props, context) {

    // video element
    const video = ref()

    // if the video has loaded
    const canPlay = ref(false)

    // progress bar element
    const progress = ref()

    // video is playing
    const isPlaying = ref(false)

    // video has started
    const hasStarted = ref(false)

    const idForVideo = computed( () => {
        return "a"+props.videoId + makeId(5)
    })

    const mediaPath = computed( () => {
      return process.env.VUE_APP_ASSETS_URL + props.path
    })

    //
    const playVideo = () => {
      if(canPlay.value) {
        video.value.play()
        isPlaying.value = true
        hasStarted.value = true
      }
    }

    const pauseVideo = () => {
      if(isPlaying.value == true) {
        video.value.pause()
        isPlaying.value = false
      }
    }
    //
    const fullScreen = () => {
      if(video.value.requestFullscreen) {
        video.value.requestFullscreen()
        isPlaying.value = false
      }
    }

    onBeforeUnmount( () => {
      video.value.removeEventListener('loadedmetadata', initVideo, false);
      // console.log('video destroy')
    })

    // when the dom is ready
    nextTick(() => {
      video.value = document.querySelector('#'+idForVideo.value)
      //console.log(video.value)
      if (video.value.readyState >= video.value.HAVE_FUTURE_DATA) {
        initVideo()
      } else {
        video.value.addEventListener('loadedmetadata', initVideo );
      }
    })

    const initVideo = () => {
      {

        progress.value = document.querySelector('#progress_'+idForVideo.value)

        // render the controls
        document.querySelector("#video-controls_"+idForVideo.value).setAttribute('data-state', 'visible');

        // compatibility
        let supportsProgress = ( progress.value.max !== undefined);
        if (!supportsProgress) {
          progress.value.setAttribute('data-state', 'fake');
        } else {
          progress.value.max = 100
        }

        // update the progress bar on click
        progress.value.addEventListener('click', function(e) {
          e.stopPropagation()
          let pos = e.offsetX / this.offsetWidth
          progress.value.value = (pos * 100)
          video.value.currentTime = pos * video.value.duration;
        });

        // update the progress bar with current time video
        const progressBarUpdate = () => {
          let pos = video.value.currentTime / video.value.duration
          progress.value.value = (pos * 100)
        }

        //Event time update progress bar
        video.value.addEventListener('timeupdate', progressBarUpdate);

        // Event checl for emit when the video passed X%
        video.value.addEventListener('timeupdate', checkWatchedPercent);
        canPlay.value = true
      }
    }


    // When the props limit is reached, emit
    const checkWatchedPercent = () =>  {
      let position = Math.ceil((video.value.currentTime / video.value.duration) * 100)
      if(position >= props.minPercent) {
        // todo: clear timeupdate once
        video.value.removeEventListener('timeupdate', checkWatchedPercent, false);
        context.emit('mediaUpdated', props.videoId, 'video_end')
      }
    }

    return {idForVideo, isPlaying, mediaPath, canPlay, playVideo, hasStarted,  pauseVideo, fullScreen}
  }
}
</script>


<style lang="scss" scoped>
.controls progress {
  display:block;

  @apply rounded-lg bg-gray-100 border-0 w-full h-2 block relative overflow-hidden;
  -webkit-appearance: none;
}
.controls progress::-webkit-progress-bar {
  @apply bg-gray-100;
}

.controls progress::-webkit-progress-bar, .controls progress::-moz-progress-bar {
  @apply rounded-lg bg-green-primary border-0 w-full h-2 inline-block max-w-full;
}

.controls progress::-webkit-progress-value {
  @apply rounded-lg bg-green-primary border-0 h-2 inline-block absolute max-w-full;
}

</style>