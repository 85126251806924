<template>
  <div class="py-6 px-4">

    <div class="text-green-dark text-lg px-4">{{item.label}}</div>
<!--    <div v-if="item.media" class="my-4">-->
<!--    </div>-->
    <div class=" my-2 ">

       <div v-for="(field, index) in item.response" :key="index" class="flex items-center w-full my-3 text-green-dark">

         <div class="flex-shrink-0 pr-4">{{index + 1}}</div>

         <div class="bg-white rounded py-4 px-3 flex items-center w-full" :class="{ 'border border-red-primary' : field.error && showResponse}">

           <div v-if="item.showResponse" class="mr-3">
             <div v-if="field.error && showResponse" class="w-5 h-5  rounded-full flex-grow-0 text-white  bg-red-primary">
                 <div class="transform rotate-45">
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                   </svg>
                 </div>
             </div>
             <div v-else class="w-5 h-5  rounded flex-grow-0 text-green-primary  bg-transparent">
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
               </svg>
             </div>

           </div>

           <div class="mr-3">
             <div :class="{'text-red-primary text-sm line-through' : field.error && showResponse}">{{ field.label }}</div>
             <div v-if="field.error && showResponse">{{ item.answer[index].label }}</div>
           </div>

           <div class="text-white ml-auto flex-grow-1">

             <div @click="pushUp(field)"
                 class="w-8 h-8 mb-2 p-1 rounded " :class="canPushUp(field) ? 'bg-orange-primary' : 'bg-gray-300' ">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                 <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
               </svg>
             </div>

             <div
                 @click="pushDown(field)"
                 class="w-8 h-8 p-1 rounded" :class="canPushDown(field) ? 'bg-orange-primary' : 'bg-gray-300'" >
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                 <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
               </svg>
             </div>

           </div>

         </div>


       </div>
        <div v-if="item.showResponse" class="text-green-primary mt-6">
          {{item.answerDetail}}
        </div>
    </div>


  </div>

</template>

<script>
import {useQuizOrder} from "@composables/quizOrder";

  export default {

    props: ['item'],
    name: "QuizOrder",

    setup() {

      const {
        canPushUp,
        pushUp,
        canPushDown,
        pushDown,
        showResponse
      } = useQuizOrder()

      return {pushUp, canPushUp, canPushDown, pushDown,showResponse}
    }
  }

</script>