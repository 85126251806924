<template>
        <div class="py-6  bg-white my-2">
          <div class="text-green-dark text-lg px-4">{{item.label}}</div>
          
          <div v-if="item.media" class="my-4">
            <VideoContainer :path="item.media.path" :videoId="item.id" />
          </div>

          <div class="input-field true-false my-2 px-4">
            <div class="radio-group flex justify-around mx-auto" style="max-width: 150px">
              <div class="radio-item">
                <input :id="radio_true" type="radio" :name="item.name" value="true" class="hidden"  :disabled="showResponse" />
                <label :for="radio_true" :class="[{'inactive' : showResponse}]">
                  Vrai
                  <span class="circle-radio"  :class="{'has-error' : hasError}"></span>
                </label>
              </div>
              <div class=" radio-item ">
                <input :id="radio_false" type="radio" :name="item.name" value="false" class="hidden" :disabled="showResponse" >
                <label :for="radio_false" :class="[{'inactive' : showResponse}]">
                  Faux
                  <span class="circle-radio" :class="{'has-error' : hasError}"></span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="showResponse" class="px-4  " :class="hasError ? 'text-red-primary': 'text-green-primary ' ">
            <div class="flex">
              <div v-if="hasError" class="w-5 h-5  rounded flex-grow-0 text-white  bg-red-primary">
                <div class="transform rotate-45">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                </div>
              </div>
              <div v-else class="w-5 h-5  rounded flex-grow-0 text-green-primary  bg-transparent">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div v-if="hasError" class="ml-2">{{ t('scenario.message_mauvaise_reponse') }}</div>
              <div v-else class="ml-2">{{ t('scenario.message_bonne_reponse') }}</div>
            </div>
            <div class="mt-2">{{item.answerDetail}}</div>
          </div>
        </div>

</template>

<script>
import {computed, ref, watchEffect} from "vue";
import VideoContainer from "../Commons/VideoContainer";
import {useI18n} from "vue-i18n";

export default {
  components: {VideoContainer},
  props: ['item', 'showResponse'],

  setup(props) {
    const {t} = useI18n()
    const hasError = ref(false)
    const radio_true = computed(() => {
        return props.item.name + '_true'
    })

    const radio_false = computed(() => {
        return props.item.name + '_false'
    })

    const checkForError = () => {
      if(props.item.answer != props.item.value) {
        hasError.value = true
      }
    }

    watchEffect( () => {

      if(props.showResponse === true) {
        checkForError()
      }
    })

    return {t, radio_true, radio_false, hasError}

  }
}
</script>
