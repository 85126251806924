<template>
    <div class="menu overflow-y-auto" :class="{'menu-open' : menuIsOpen}">

      <div class="bg-green-primary py-6 pb-10 px-6 pt-20 flex items-start">

        <div class="bg-green-light rounded-full h-24 w-24 flex-shrink-0 overflow-hidden">
          <img class="w-full" :src="require(`@assets/${avatar}`)">
        </div>

        <div class="ml-4 text-white">
          <div class="font-title  text-2xl font-bold">{{ user.first_name }}</div>
          <div class="text-sm ">{{user.email}}</div>
          <div class="text-sm mt-4 underline">
            <a @click.prevent="toggleAvatarModal" href="#">{{ t('menu.changer_avatar') }}</a>
          </div>
          <div class="text-sm mt-4 underline text-orange-light">
            <a @click.prevent href="#">{{ t('menu.espace_sipad') }}</a>
          </div>

        </div>

      </div>

      <!--  Current module  -->
      <div v-if="currentSequence" @click="goToSequence" class="bg-white py-6 px-6 cursor-pointer">

        <div class="text-orange-dark text-sm">{{ t('menu.reprendre_parcours') }}</div>
        <div class="flex items-start mt-4">

          <div v-if="currentSequence.score" class="text-orange-primary relative  flex-shrink-0">
            <div class="text-sm text-green-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pt-1">
              {{ currentSequence.score }}%</div>
            <ProgressRing radius="28" stroke="3" :progress="currentSequence.score"></ProgressRing>
          </div>
          <div v-else class="bg-orange-primary rounded-full h-12 w-12 flex-shrink-0 flex items-center justify-center text-white"> - </div>
          <div class="ml-4 text-green-dark">
            <div class="text-sm ">Module {{currentModuleUser.order + 1}}. {{ currentModuleUser.title }}</div>
            <div class="font-title  font-bold text-lg">{{ currentSequence.title }}</div>
          </div>
        </div>
      </div>

      <!--  Derniéres evaluations  -->
      <div v-if="oldModulesCorrection.length" class="bg-green-light1 border-t border-green-light pt-6 ">
        <div class="px-6 text-orange-dark  text-sm ">{{ t('menu.corriges_evaluations') }}</div>
        <ButtonModuleCorrection v-for="(evaluation, index) in oldModulesCorrection" :last="index == oldModulesCorrection.length - 1" :key="index" :evaluation="evaluation" />
      </div>

      <div class="text-sm text-center my-10 underline text-green-primary">
        <a @click.prevent="logout" href="#">{{ t('menu.deconnexion') }}</a>
      </div>

      <AvatarModal />

    </div>

</template>

<script>
import {useApp} from "@composables/app"
import ButtonModuleCorrection from "@components/ButtonModuleCorrection"
import ProgressRing from "@components/Commons/ProgressRing"
import {useI18n} from "vue-i18n";
import {useLogs} from "@composables/logs";
import {computed} from "vue";
import {useRouter} from "vue-router";
// import {client as ApolloClient} from "@/config/clients";
import {useUser} from "@composables/user";
import AvatarModal from "@components/User/AvatarModal";
import {useModule} from "@composables/module";
import {useAuth} from "@composables/auth";


export default {
  components: {ButtonModuleCorrection, ProgressRing, AvatarModal},
  setup() {


    const { t } = useI18n()
    const {menuIsOpen, toggleMenu, toggleAvatarModal} = useApp()
    const {user, avatar} = useUser()
    const { currentModuleUser, oldModulesCorrection } = useLogs()
    const { hasModules } = useModule()
    const { logout } = useAuth()
    const router = useRouter()


    const currentSequence = computed( () => {
        if(! hasModules.value) return null
        let availableSequence = currentModuleUser.value.sequences.filter( s => s.available)
        return availableSequence[availableSequence.length -1]
    })

    const goToSequence = () => {
        toggleMenu()
        router.push({name: 'Sequence', params: {id: currentSequence.value.id, transition: "fade"}})
    }

    // const resetParcours = async () => {
    //   const {user, currentPromotionId} = useUser()
    //
    //   let response = await  ApolloClient.mutate({
    //     mutation: require('@gql/logs/delete_logs_user.gql'),
    //     variables: {
    //       userId: user.value.id,
    //       contain: {
    //         promotion_id: currentPromotionId.value
    //       }
    //     }
    //   })
    //
    //   if(response) {
    //     document.location.reload()
    //   }
    //
    // }

    return {
      t,
      user,
      avatar,
      menuIsOpen,
      oldModulesCorrection,
      currentModuleUser,
      currentSequence,
      goToSequence,
      // resetParcours,
      toggleAvatarModal,
      logout
    }
  }
}
</script>


<style scoped>

  .menu {
    @apply w-full fixed h-full bg-green-light1 top-0;
    z-index: -1;
  }

</style>