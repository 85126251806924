<template>
  <div class="max-w-full w-full overflow-hidden w-full overflow-x-hidden" id="transitionElementHook">
    <router-view v-slot="{ Component }">
      <transition @after-enter="afterEnter" :name="transitionName" >
        <component  :is="Component" />
      </transition>
    </router-view>
  </div>

</template>

<script>
import {useApp} from "@composables/app"
export default {
  setup() {
    const {transitionName} = useApp()
    const afterEnter = () => window.scrollTo(0, 0);

    return {transitionName, afterEnter}
  }
}
</script>

<style>

/**
* Route transition
**/
.right-left-enter-from {
  transform: translateX(100%);
}

.right-left-leave-to {
  /*position: absolute;*/
  transform: translateX(-100%);
}

.right-left-enter-active {
  transition: all .6s ease;
  position: absolute;
}
.right-left-leave-active {
  transition: all .6s ease;
  position: absolute;
}

.right-left-enter-to,
.right-left-leave-from {
  transform: translateX(0);
}

/**
* Back transition
**/

.left-right-enter-from {
  transform: translateX(-100%);
}

.left-right-leave-to {
  /*position: absolute;*/
  transform: translateX(100%);
}

.left-right-enter-active{
  transition: all 0.8s ease;
  position: absolute;
}

.left-right-leave-active {
  transition: all 0.8s ease;
  position: absolute;
}

.left-right-enter-to,
.left-right-leave-from {
  transform: translateX(0);
}


/**
* Fade transition
**/
.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}


</style>

