<template>
    <input
        @input="check()"
        type="radio"
        :checked="checked"
        :id="value"
        class="mr-2 hidden"
        v-bind="$attrs"
        :name="group"
    />
    <label
        :for="value"
        class="flex flex-row items-center w-full bg-white my-2 rounded-lg py-4 px-4  select-none"
        :class="[
            (hasError == 'wrongAnswer' ) ? 'border border-red-primary' : '',
            {'cursor-pointer' : ! disabled}
          ]"
    >
      <div class="mr-3 w-5 h-5 bg-green-light2 rounded-full flex items-center justify-center flex-shrink-0" >

        <div v-if="checked" class="w-3 h-3  rounded-full"
             :class="hasError ? 'bg-red-primary' : 'bg-green-primary'"></div>

        <div v-if="hasError == 'missingAnswer'" class="w-3 h-3  rounded-full bg-green-dark ring ring-green-dark ring-offset-2"></div>

      </div>
      <div class="" :class="hasError == 'wrongAnswer' ? 'text-red-primary line-through' : ''">{{ name }}</div>
<!--      <div v-if="hasError">{{ hasError }}</div>-->
    </label>

</template>

<script>
import {computed} from "vue";

export default {
  emits: ["update:names"],
  props: {
    group: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    names: {
      type: Array,
      required: true,
    },
    hasError: {
      type: [Boolean, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {

    const checked = computed(() => props.names.includes(props.value));

    const check = () =>  {
      if(props.disabled == true) return
      let updatedNames = [...props.names];
      if (checked.value) {
        updatedNames.splice(updatedNames.indexOf(props.value), 1);
      } else {
        updatedNames = [props.value];
      }
      context.emit("update:names", updatedNames);
    }

    return {check, checked}
  }
}
</script>