<template>
  <div class="px-8">
    <div class="max-w-xs mx-auto text-lg text-green-dark text-center leading-relaxed">
      <div v-html="t('premiere_connexion.choix_avatar')"></div>
    </div>

    <div class="max-w-sm mx-auto text-green-dark text-center flex flex-wrap justify-between my-10">

      <div v-for="(avatar, index) in avatarList" :key="index"
           @click.prevent="setAvatar(index+1)" class="w-1/2 xs:w-1/3 rounded-full overflow-hidden flex items-center justify-center mb-6">
        <div class="h-24 w-24 rounded-full overflow-hidden flex-shrink-0"
             :class="[{'border-4 border-orange-primary': current_avatar == index + 1}, 'bg-' + avatar.color ]">
          <img :src="require(`@assets/${avatar.path}`)">
        </div>
      </div>

    </div>

  </div>


</template>

<script>
import {useUser, useAvatarList} from "@composables/user";
import {useFirstConnexion} from "@composables/firstConnexion";
import {useI18n} from "vue-i18n";

export default {

  setup() {
    const {user } = useUser()
    const {setAvatar, current_avatar} = useFirstConnexion()
    const { t } = useI18n()
    const avatarList = useAvatarList()
    return {t, user, setAvatar, current_avatar, avatarList}
  }

}
</script>