<template>
  <div class="mt-10 w-full">
    <Conversation></Conversation>

    <div class="font-bold text-green-primary max-w-xs mx-auto text-center leading-7">

      <div v-if=" ! hasModules" v-html="t('accueil.aucuns_modules')"></div>
      <div v-else-if="! isFirstConnexion" v-html="t('accueil.premier_module')"></div>
      <div v-else-if="allModulesDone" v-html="t('accueil.parcours_termine')"></div>
      <div v-else-if="currentModule" v-html="t('accueil.index_module',  {name: user.first_name, index_module: currentModule.order + 1})"></div>

    </div>

    <AvatarWheel v-if="hasModules"></AvatarWheel>


    <!-- Slide selecteur de module  -->
    <div v-if="hasModules">

      <ModulesSelector />

      <!-- Séquences de module  -->
      <div :class="[ canAccessCurrentModule ? 'bg-red-primary' : 'bg-green-light1' ]">
        <div v-if="currentModule && ! canAccessCurrentModule" class="py-8  border-b border-white border-bottom">
          <div class="px-4 text-orange-dark max-w-sm mx-auto text-center text-lg">Complétez le module {{ currentModule.order }} pour débloquer les séquences de ce module.</div>
        </div>
        <ModuleSequence v-for="(sequence) in modulesSequences" :sequence="sequence" :key="sequence.id" />
      </div>

    </div>
    <div v-else class="text-sm text-center my-10 underline text-green-primary">
      <a @click.prevent="logout" href="#">{{ t('menu.deconnexion') }}</a>
    </div>
    
  </div>

</template>

<script>
  import {useUser} from "@composables/user"
  import ModulesSelector from "@components/Modules/ModulesSelector";
  import ModuleSequence from "@components/Modules/ModuleSequence";
  import AvatarWheel from "@components/User/AvatarWheel";
  import Conversation from "@components/Conversation/Conversation";
  import {useModule} from "@composables/module";
  import {useI18n} from "vue-i18n";
  import {computed} from "vue";
  import {useLogs} from "@composables/logs";
  import {useAuth} from "@composables/auth";

  export default {

    components: { ModulesSelector, ModuleSequence, AvatarWheel, Conversation},

    setup() {
      const { logout } = useAuth()
      const {user} = useUser()
      const {logs} = useLogs()
      const {modulesSequences, currentModule, canAccessCurrentModule, hasModules, modules} = useModule()
      const { t } = useI18n({ useScope: 'global' })

      const isFirstConnexion = computed( () => {
          return logs.value.length > 0
      })
      const allModulesDone = computed( () => {
         let modulesEnd = logs.value.filter(l => l.logType.slug == "module_end")
          return modulesEnd.length >= modules.value.length
      })


      return {t, user, modulesSequences, currentModule, canAccessCurrentModule, hasModules, isFirstConnexion, allModulesDone, logout}
    }

  }

</script>