import { createApp } from 'vue'
import App from './App.vue'
import Router from '@/config/routes'

import {ApolloClients} from '@vue/apollo-composable'
import {client} from "./config/clients";
import messages from "@/config/text"
import { createI18n } from 'vue-i18n'
import {useAuth} from "@composables/auth"

import '@assets/css/tailwind.scss'
import '@assets/css/app.scss'
import {redirectToAuth} from "./helpers";

import dayjs from "dayjs";
import "dayjs/locale/fr";
dayjs.locale('fr');

let {hasValidToken} = useAuth()

// localStorage.setItem(
//     process.env.VUE_APP_AUTH_TOKEN_NAME,
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImE0M2RhMjE2LTVmNGUtNDAzZC1hNDE3LTNlNjZmZDk2ZWE3ZCIsInBhc3N3b3JkIjoiJDJiJDEwJFUuQ3l0TTNFUlM2a01YVGJxRU1vU3VMYlhFTFQ0aUVrWXNoM2E2ZjNhNG1kc0FjQm1OU2x1IiwiZW1haWwiOiJjYW1pbGxlLmxAdGhld2Fsa2luZ25lcmRzLmNvbSIsIm5hbWUiOiJMaG9zdCIsInJvbGUiOiJzdXBlcmFkbWluIiwiaWF0IjoxNjEzMzg1MjU1LCJleHAiOjE2MTM0MTQwNTV9.7omOZkin8kULCKaMiDk7FSyER9zyizIEArHRrvPRsEg"
// )
const init = async () => {
    return await hasValidToken();
}



init().then(user => {
    if( ! user) {
        redirectToAuth()
        return
    }

    const i18n = createI18n({
        legacy: false, // you must set `false`, to use Compostion API
        locale: 'fr', // set locale
        fallbackLocale: 'fr', // set fallback locale
        useScope: 'global',
        messages
    })

    let app = createApp(App)
    app.use(Router)
    app.use(i18n)
    app.provide(ApolloClients, {default: client})
    app.mount('#app')
});




