<template>
    <div @click="openScenario"
        class="rounded-lg text-lg font-bold text-white  px-5 py-2 my-4 min-h-20 flex items-center" style="min-height: 75px"
      :class="[
          disabled ? '  bg-orange-light' : 'bg-orange-primary cursor-pointer',
      ]"
    >
      <div class="flex items-center w-full">

        <div v-if=" !disabled  && !current" class="text-orange-dark relative flex-shrink-0 ">
          <div class="text-sm text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">{{progress}}%</div>
          <ProgressRing  radius="32" stroke="3" :progress="progress"></ProgressRing>
        </div>

        <div v-else-if="current" class="text-white relative flex-shrink-0 ">
          <div class="text-xs text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold ">-</div>
          <ProgressRing  radius="32" stroke="3" :progress="100"></ProgressRing>
        </div>


        <div class="mr-4 " :class="{ 'ml-4' : ! disabled || current  }">
          <slot/>
        </div>

        <div v-if="!disabled" class="flex-shrink-0 ml-auto border-2 border-white  rounded-full px-2 py-2 text-white">
            <div class="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </div>
        </div>

      </div>

    </div>

</template>

<script>

import ProgressRing from "@components/Commons/ProgressRing";
import {useRouter} from "vue-router";

export default {
  props: {
    type: {
      type: String,
      default: 'document'
    },
    url: {
      type: [String],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Boolean,
      default: false
    },
    progress: {
      required: false,
      default: false
    }
  },

  components: {ProgressRing},

  setup(props) {
    const router = useRouter()

    const openScenario = () => {
      if( ! props.disabled ) {
        router.push(props.url)
      }
    }

    return {openScenario}
  }
}
</script>