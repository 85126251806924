import {computed, reactive, ref} from "vue";
import {client as ApolloClient} from "../config/clients";
import {useUser} from "@composables/user";

const state = reactive({
    loading: false,
    currentStep: 2,
    avatar_id: null,
    errors: [],
    form: [],
    videoWatched: false,
})

const useFirstConnexion = () => {
    const onResult =  ref()


    const hasWatchedVideo = () => {
        state.videoWatched = true
    }
    /**
     * If the user cans go to the next step
     * depends on the current step an the answer
     * @type {ComputedRef<boolean>}
     */
    const canGoNext = computed( () => {
        if(currentStep.value == 1) {
            if( state.videoWatched == true ) {
                return true
            }
        }else if (currentStep.value == 2) {
            if(current_avatar.value != null) return true
        }else if (currentStep.value == 3 && canSubmitForm.value) {
            return true
        }
        return false
    })

    const setForm = (form) => {
        state.form = form
    }

    /**
     * Action
     */
    const nextStep = () => {
        if(currentStep.value == 3) {
            if(canSubmitForm.value ) return submitForm()
        }
        else if(currentStep.value == 2 && hasInfoUser.value){
            if(canGoNext.value)  return saveAvatar()
        }
        else {
            if(canGoNext.value)  state.currentStep ++
        }
    }

    /**
     * Return the current step
     * @type {ComputedRef<number>}
     */
    const currentStep = computed( () => {
        return state.currentStep
    })

    /**
     * Return the current avatar
     * @type {ComputedRef<*>}
     */
    const current_avatar = computed( () => {
        return state.avatar_id
    })

    /**
     * Select an avatar in the list
     * @param avatar_id
     */
    const setAvatar =  (avatar_id) => {
        state.avatar_id = avatar_id
        // console.log('avatar selected', avatar_id)
    }

    const saveAvatar = async () => {
        state.loading = true
        const {setAvatarId, user} = useUser()

        let response = await  ApolloClient.mutate({
            mutation: require('@gql/user/update_avatar_user.gql'),
            variables: {
                userId: user.value.id,
                avatarId: current_avatar.value
            }
        })
        if(response) {
            setAvatarId( current_avatar.value)
            onResult.value = response
            // redirect
        }
    }

    /**
     * @type {ComputedRef<UnwrapRef<{gender: string, email: string, age: string}>>}
     * @return state form
     */
    const form = computed( () => {
        return state.form
    })

    /**
     *
     * @type {ComputedRef<[]>}
     * @return state error
     */
    const formErrors = computed( () => {
        return state.errors
    })

    /**
     * Verifier les champs
     * @type {ComputedRef<boolean>}
     */
    const canSubmitForm = computed( () => {
        let can = true

        form.value.forEach(field => {
            if(field.required && field.value === "") can = false
        })
       return can

    })

    const validateForm = () => {

        // verifier l'email uniquement, les autre champs ne requis sont valider en amont
        let isValid = true
        form.value.forEach( field =>  {
            if(field.required) {
                if(field.type == "email") {
                    let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                    if( ! field.value.match(mailformat)) {
                        formErrors.value.push({key: field.name, message: "L'adresse email est incorrecte"})
                        isValid = false
                    }
                }
            }
        })

        return isValid
    }

    /**
     * Show loader when persisting data
     * @type {ComputedRef<boolean|*>}
     */
    const loading = computed( () => {
        return state.loading
    })

    /**
     * Submit
     * @returns {Promise<ApolloQueryResult<any>>}
     */
    const submitForm = async () => {

        if(  ! validateForm()) {
            window.scrollTo(0, 0);
            return null
        }

        state.loading = true

        // map form data
        let {user, setUser} = useUser()
        let user_data = {}
        form.value.map( (data) => {
            user_data[data.name] = data.value
        })

        user_data.avatar_id = current_avatar.value

        // Prevent default type value
        if( ! user_data.has_distance_learning) user_data.has_distance_learning = null
        if(! user_data.rise_working_time) user_data.rise_working_time = null

        user_data = {...user.value, ...user_data}
        // console.log('toi be send', user_data)
        // remove unexpected fields
        delete user_data.__typename
        delete user_data.promotion_users

        let response = await ApolloClient.mutate({
            mutation: require('@gql/user/update_user_info.gql'),
            variables: {...user_data},
        })

        if(response.data.update_users.returning[0].id) {
            setUser(user_data)

            onResult.value = response
        }

        //state.loading = false
    }


    const hasInfoUser = computed(() => {
        let {user} = useUser()
        let ok = true
        if(
            (user.value.age === "" || user.value.age === null)
            || (user.value.level_studies === "" || user.value.level_studies === null)
            || (user.value.actual_position === "" ||  user.value.actual_position === null)
            || (user.value.actual_working_time === "" || user.value.actual_working_time === null)
            || (user.value.contract === "" || user.value.contract === null)
            || (user.value.experiences === "" || user.value.experiences === "")
        ) ok = false
        return ok
    })

    return {
        setAvatar,
        current_avatar,

        hasInfoUser,
        hasWatchedVideo,

        form,
        setForm,
        formErrors,
        canSubmitForm,
        submitForm,
        loading,

        onResult,


        currentStep,
        nextStep,
        canGoNext,
    }
}

export {useFirstConnexion}