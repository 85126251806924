<template>
  <div class="message__wrapper flex items-center" :class="listClassWrapper">
    <div class="message__container flex flex-col" :class="listClassContainer">
      <div v-if="!isMe && isFirstInSeries" class="self-end" :class="listClassUsername">
        {{ username }}
      </div>
      <div class="message__content">{{ message.content }}</div>

      <ConversationMessageParent :messageParent="message.parent" :childUserRole="message.user.role" v-if="message.parent" />

      <div class="text-xs text-green-primary self-end">
        {{ dayjs(message.created_at).format(dateFormatMessage) }}
      </div>
      <div class="message__triangle" :class="listClassTriangle" v-if="isLastInSeries"></div>
    </div>

    <div class="message__reply" v-if="!isMe" @click="setReplyTo(message)">
      <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.782496 14.5892L0.806869 3.58129C0.851835 3.53109 0.906574 3.49008 0.967916 3.46062C1.02926 3.43116 1.09599 3.41384 1.16424 3.40967C1.2325 3.4055 1.30092 3.41456 1.36556 3.43632C1.4302 3.45807 1.48978 3.4921 1.54083 3.53644L4.19229 5.77654C5.41754 4.11699 7.03631 2.77048 8.91076 1.85167C10.7852 0.932864 12.8598 0.468973 14.9579 0.499499C22.2325 0.499499 26.36 6.5681 26.36 6.5681L26.75 7.03776C26.75 7.03776 22.0131 3.5338 16.5423 5.39396C15.1657 5.85128 13.8989 6.57638 12.8188 7.52525C11.7387 8.47412 10.8678 9.62695 10.259 10.9137L12.8319 13.0879C12.9344 13.1785 12.9974 13.3039 13.0079 13.4383C13.0185 13.5728 12.9759 13.7061 12.8887 13.8108L1.66 15.4098C1.34312 15.4758 1.11833 15.5813 0.888121 15.394C0.657912 15.2066 0.774371 14.919 0.782496 14.5892Z" fill="#3D7F7F" />
      </svg>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useUser } from "@composables/user";
import { useConversation } from "@composables/conversation";
import dayjs from "dayjs";
import ConversationMessageParent from "@components/Conversation/ConversationMessageParent";

export default {
  components: {
    ConversationMessageParent
  },

  props: {
    message: {
      type: Object,
    },
    previousMessageUserId: {
      type: String,
      default: null,
    },
    nextMessageUserId: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const dateFormatMessage = "HH:mm";

    const { userId } = useUser();
    const { setReplyTo } = useConversation();


    // Manage CSS Classes
    const listClassWrapper = computed(() => {
      const flex = isMe.value ? "message__wrapper--flex-me" : "";

      return flex;
    });

    const listClassContainer = computed(() => {
      const color = isMe.value
        ? "message__container--color-me"
        : isAdmin.value
        ? "message__container--color-admin"
        : "message__container--color-user";

      const margin =
        props.message.user.id === props.previousMessageUserId
          ? "message__container--margin-me"
          : isAdmin.value
          ? "message__container--margin-admin"
          : "message__container--margin-user";

      return color + " " + margin;
    });

    const listClassUsername = computed(() => {
      const color = isAdmin.value
        ? "message__username--color-admin"
        : "message__username--color-user";

      return color;
    });

    const listClassTriangle = computed(() => {
      const color = isMe.value
        ? "message__triangle--color-me"
        : isAdmin.value
        ? "message__triangle--color-admin"
        : "message__triangle--color-user";

      const direction = isMe.value
        ? "message__triangle--right"
        : "message__triangle--left";

      return color + " " + direction;
    });


    // Helpers
    const isMe = computed(() => {
      return props.message.user.id === userId.value;
    });

    const isAdmin = computed(() => {
      return props.message.user.role === "superadmin";
    });

    const isLastInSeries = computed(() => {
      return props.message.user.id !== props.nextMessageUserId;
    });

    const isFirstInSeries = computed(() => {
      return props.message.user.id !== props.previousMessageUserId;
    });


    // Concat username and role
    const username = computed(() => {
      const name = props.message.user.name + " " + props.message.user.first_name;
      const role = "Organisateur -";

      return isAdmin.value ? role + " " + name : name;
    });

    return {
      dateFormatMessage,
      listClassWrapper,
      listClassContainer,
      listClassUsername,
      listClassTriangle,
      isMe,
      isFirstInSeries,
      isLastInSeries,
      username,

      dayjs,
      setReplyTo,
    };
  },
};
</script>

<style scoped>
.message__wrapper--flex-me {
  @apply justify-end;
}

.message__container {
  @apply rounded-lg text-sm p-2 relative;
}

.message__container--color-me {
  @apply bg-green-light text-green-dark1;
}

.message__container--color-admin {
  @apply bg-orange-light text-red-dark;
}

.message__container--color-user {
  @apply bg-white text-green-dark1;
}

.message__container--margin-me {
  @apply mt-0.5;
}

.message__container--margin-admin {
  @apply mt-3;
}

.message__container--margin-user {
  @apply mt-2;
}

.message__content {
  white-space: pre-line;
}

.message__username--color-admin {
  @apply text-red-dark;
}

.message__username--color-user {
  @apply text-green-primary;
}

.message__triangle {
  display: inline-block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: 10px;
}

.message__triangle--left {
  left: -8px;
  border-top: 5px solid transparent !important;
  border-right: 8px solid;
  border-bottom: 3px solid transparent !important;
}

.message__triangle--right {
  right: -8px;
  border-top: 3px solid transparent !important;
  border-bottom: 5px solid transparent !important;
  border-left: 8px solid;
}

.message__triangle--color-me {
  @apply border-green-light;
}

.message__triangle--color-admin {
  @apply border-orange-light;
}

.message__triangle--color-user {
  @apply border-white;
}

.message__reply {
  @apply ml-4 mr-1 opacity-40 transition-opacity duration-100 ease-in;
}

.message__reply:hover {
  @apply opacity-100;
}
</style>
