import {computed, reactive} from "vue";
import {client as ApolloClient} from "../config/clients";
import {useLogs} from "./logs";

const state = reactive({
    isEvaluation: false,
    scenarioNodes: [],
    scenarioCorrection: null,
    scenarioId: "",
    currentNode: 1,
    currentNodeIsValid: false,
    currentNodeType: '',
    nodeSubmited: false,
    currentQuiz: null,
    results: []
})

const useScenario = () => {
    const currentQuiz = computed( () => state.currentQuiz )
    const setCurrentQuiz =  (quiz) => state.currentQuiz = quiz

    const scenarioNodes = computed( () => state.scenarioNodes)
    const scenarioCorrection = computed( () => state.scenarioCorrection)

    const currentNode = computed( () => state.currentNode )

    const currentNodeType = computed( () => state.currentNodeType)


    const getScoreQuiz = computed( () => {
        let totalQuestion = 0
        let totalError = 0

        results.value.forEach(node => {

            if(node.type == 'true_false') {
                node.results.forEach( question => {
                    totalQuestion ++
                    if(question.answer != question.value ) {
                        totalError ++
                    }
                })
            }

            if(node.type == 'reorder') {
               node.results.answer.forEach((answer) => {
                   totalQuestion ++
                   if(answer.error) totalError ++
               })
            }

            if(node.type == 'drag_drop') {
                node.results.response.forEach((r) => {
                    totalQuestion ++
                    if(
                        node.results.options.find(o => o.id == r.option_id).value !=
                        node.results.sentences.find(s => s.id == r.sentence_id).answer
                    ){
                        totalError ++
                    }
                })
            }

            if(node.type == 'qcm') {
                node.results.forEach(question => {
                    totalQuestion ++
                    let hasError = false
                    question.answer.forEach(answer => {
                        if( ! question.responses.includes(answer+'_'+question.question_id)) hasError = true
                    })
                    if(hasError) totalError ++
                })
            }



        })
        // console.log("RESULTS: ", totalQuestion, totalError)
        let percent = Math.round(100 - ((100 / totalQuestion) * totalError))
        return percent
    })

    const setIsEvaluation = (is) => {
        state.isEvaluation = is
    }

    const scenarioIsEvaluation = computed( () => state.isEvaluation)
    /**
     *
     * @param scenario_id
     * @returns {Promise<[]>}
     */
    const getScenario = async(scenario_id) => {

        state.scenarioCorrection = null

        let nodesContent = await ApolloClient.query( {
            query: require('@gql/scenarios/get_scenario_nodes.gql'),
            variables: {scenarioId: scenario_id}
        })
        let result = nodesContent.data.scenario[0]

        if(! result) {
            // todo throw message
            return
        }

        let nodes = result.nodes
        let correction = result.correction
        // reorder les nodes
        let orderedNodes = []
        nodes.forEach( () => {
            // on push le node sans node suivant, donc le dernier
            if( orderedNodes.length === 0) {
                orderedNodes.push(nodes.find(n => n.triggers.length === 0))
            }else {
                // on recupere le node qui appelle le dernier du tableau, donc le dernier -1
                if( ! orderedNodes[orderedNodes.length - 1]) {
                    console.error('Certains node ne sont pas reliés dans le scenario.')
                    alert('Certains node ne sont pas reliés dans le scenario.')
                }
                let previousNode = nodes.find(n => n.triggers[0] && n.triggers[0].triggered_node_id == orderedNodes[orderedNodes.length - 1].id)
                orderedNodes.push(previousNode)
            }
        })
        // on inverse le tout
        orderedNodes.reverse()

        // réuperer les content des nodes (medias ou games)
        let scenarioContent = await ApolloClient.query( {
            query: require('@gql/scenarios/get_scenario_nodes_content.gql'),
            variables: {nodeIds: orderedNodes.map(n => n.node_content)}
        })


        // reorder nodes
        let content = []

        // on trie les nodes par medias et scenario
        orderedNodes.forEach( ({node_content}) => {
            let media = scenarioContent.data.media.find(m => m.id == node_content)
            let game = scenarioContent.data.game.find(g => g.id == node_content)
            let embed = scenarioContent.data.embed.find(g => g.id == node_content)
            if(media) {
                // let item = {...media}
                // item.type = 'media'
                content.push({type: 'media', ...media})
            }else if(game) {
                // let item = {...game}
                // item.type = 'game'
                content.push({type: 'game', ...game})
            }else if(embed) {
                // let item = {...game}
                // item.type = 'game'
                content.push({type: 'embed', ...embed})
            }
        })

        state.scenarioId = scenario_id
        state.scenarioNodes = content
        state.scenarioCorrection = correction
        return content
    }

    const saveNodeResults = (node_id, type, results) => {
        if( ! state.results.find(r => r.node_id == node_id)) {
            let node = {node_id: node_id, type: type, results: results}
            state.results.push(node)
        }else {
            state.results.find(r => r.node_id == node_id).results = results
        }
    }

    const resetResults = () => {
        state.results = []
    }

    const results = computed( () => {
        return state.results
    })

    const setScenarioNodes = (nodes) => {
        state.scenarioNodes = nodes
    }


    const setCurrentNodeType = (type) => {
        return state.currentNodeType = type
    }

    const totalNode = computed( () => state.scenarioNodes.length)

    const setCurrentNodeValidity = (validity) => {
        state.currentNodeIsValid = validity
    }

    const nextNode = async (sequence_id, scenario_id, router) => {

        const {createLog} = useLogs()
        // console.log(router)

        if( isLastNode.value) {

            if(scenarioIsEvaluation.value) {
                let score = getScoreQuiz.value
                // console.log(score)
                let today = new Date()
                let data = [{score: score, date: today.toISOString(), type:'evaluation' , results: results.value}]
                await createLog(scenario_id, sequence_id, 'scenario_end', data)

                return router.push({name: 'Score', params: {sequence_id: sequence_id, scenario_id: scenario_id}})

            }else {
                // Clear nodes progression log
                await createLog(scenario_id, sequence_id, 'scenario_nodes', [], true)

                await createLog(scenario_id, sequence_id, 'scenario_end')
                return router.push({name: 'Sequence', params: {id: sequence_id, transition: 'right-left'}})
            }

        }else {
            // Add nodes progression log for regular scenario
            if (!scenarioIsEvaluation.value) {
                await createLog(scenario_id, sequence_id, 'scenario_nodes', [state.currentNode + 1], true)
            }

            return state.currentNode ++
        }
    }

    const setNode = (node) => {
        state.currentNode = node
    }

    const isLastNode = computed( () => {
        return state.currentNode == scenarioNodes.value.length
    })

    const canGoNext = computed(() => {
        return state.currentNodeIsValid
    })

    const formatTrueFalseContent = computed( () => {
        // get current node
        let node  = scenarioNodes.value[currentNode.value - 1]

        let questions = []

        node.questions.forEach( (q, index) => {
            //console.log(q)
            questions.push({
                id: q.id,
                name: 'q_' + index,
                label: q.text,
                answer: q.answers[0].is_correct ? 'true' : 'false',
                answerDetail: q.correction,
                media: q.media,
            })
        })

        return questions
    })

    const formatQCMContent = computed( () => {
        let node  = scenarioNodes.value[currentNode.value - 1]
        let quiz = []
        let questions = node.questions
        // console.log(node)
        questions.forEach( (question, index) => {

            quiz.push({
                label : question.text,
                answerDetail : question.correction != "" ? question.correction : null,
                responses : [],
                answer: [],
                options : [],
                question_id: question.id,
                media: question.media,
                correctAnswers: 0,
            })

            question.answers.forEach(answer => {

                quiz[index].options.push({
                    label: answer.text,
                    value: answer.text+'_'+answer.question_id,
                    error: false,
                    group: answer.question_id,
                })

                if(answer.is_correct) {
                    quiz[index].answer.push(answer.text)
                    quiz[index].correctAnswers += 1
                }
            })

        })

        return quiz
    })

    const formatDragDropContent = computed( () => {
        let node  = scenarioNodes.value[currentNode.value - 1]
        let quiz = {}

        quiz.label = node.title
        quiz.options = []
        quiz.sentences = []
        node.questions.forEach( (question, index) => {
            quiz.options.push({
                label: question.answers[0].text,
                id: question.id,
                value: question.answers[0].text,
                isSelected: false
            })
            quiz.options =  quiz.options.sort(() => Math.random() - 0.5)
            quiz.sentences.push({
                label: node.headers[index].content,
                id: question.id,
                answer: question.answers[0].text,
            })

        })
        quiz.response = []
        // quiz.showResponse = false,
        quiz.correction = node.correction != "" ? node.correction : null

        return quiz
    })

    const formatOrderContent = computed(() => {
        let node  = scenarioNodes.value[currentNode.value - 1]
        let quiz = {}
        quiz.response = []
        quiz.answer = []
        quiz.answerDetail = null
        node.questions.forEach( question => {
            let option = {label: question.text, id: question.id }
            quiz.answer.push(option)
            quiz.response.push(option)
        })
        quiz.response =  quiz.response.sort(() => Math.random() - 0.5)
        return quiz
    })

    const hasSubmitNode = computed( () => {
        return state.nodeSubmited
    })

    const setHasSubmitNode = (is) => {
        // console.log('qdfdfdsfdf',  state.nodeSubmited)
        state.nodeSubmited = is
        state.nodeSubmited = is
    }

    return {
        getScenario,
        setHasSubmitNode,
        hasSubmitNode,
        setIsEvaluation,
        scenarioIsEvaluation,
        scenarioNodes,
        scenarioCorrection,
        saveNodeResults,
        resetResults,
        results,
        setCurrentNodeValidity,
        isLastNode,
        setNode,
        setScenarioNodes,
        currentNode,
        currentNodeType,
        setCurrentNodeType,
        totalNode,
        nextNode,
        canGoNext,
        formatTrueFalseContent,
        formatQCMContent,
        formatDragDropContent,
        formatOrderContent,
        getScoreQuiz,
        currentQuiz,
        setCurrentQuiz
    }
}

export {useScenario}