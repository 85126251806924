<template>
    <div class="px-4 pb-6">

      <div class="text-center text-green-dark text-lg px-4">{{item.label}}</div>

      <div class="flex flex-wrap my-2">
          <div v-for="(option, index) in item.options" :key="index"
               @click="selectOption(option)"
               class="mr-2 my-1 text-white text-sm font-bold  px-2 rounded pt-1" style="padding-bottom: 2px"
                :class="[getClassOption(option), {'cursor-pointer' :  ! showResponse}]">
              {{option.label}}
            <span v-if="optionInResponse(option)"></span>
          </div>
      </div>

      <div class="mt-10">

        <div v-for="(sentence, index) in item.sentences" :key="index"
              :class="{'border border-red-primary': sentence.error}"
             class="bg-white px-4 py-4 mb-3 rounded text-green-dark flex items-center justify-between">
          <div>
            {{sentence.label}}
          </div>
          <div clas="flex justify-center items-center">
            <div class="text-red-primary text-center text-sm line-through" v-if="sentence.error">{{ selectedResponse(sentence).label }}</div>
            <div @click="assignSelectedItem(sentence)"
                 class="w-20 h-8  rounded flex-shrink-0 flex flex-col items-center justify-center" style="padding-top: 4px"
                 :class="getClassSentence(sentence)">
              <div v-if="selectedResponse(sentence)" class="text-white font-bold ">
                <span v-if="sentence.error">{{sentence.answer}}</span>
                <span v-else>{{selectedResponse(sentence).label}}</span>
              </div>
            </div>
          </div>



        </div>

        <div v-if="showResponse" class="px-4 mt-6" :class="hasError ? 'text-red-primary': 'text-green-primary ' ">
          <div class="flex">

            <div v-if="hasError" class="w-5 h-5  rounded flex-grow-0 text-white  bg-red-primary">
              <div class="transform rotate-45">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </div>
            </div>

            <div v-else class="w-5 h-5  rounded flex-grow-0 text-green-primary  bg-transparent">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>

            <div v-if="hasError" class="ml-4">{{ item.correction ?? t('scenario.message_mauvaises_reponses')}}</div>
            <div v-else class="ml-4">{{ item.correction ?? t('scenario.message_bonnes_reponses')}}</div>

          </div>
        </div>

      </div>

    </div>



</template>

<script>
import {useQuizDragDrop} from "@composables/quizDragDrop";
import {computed} from "vue";
import {useI18n} from "vue-i18n";

export default {
  props: ['item'],
  setup(props) {
    const  {t} = useI18n();
    const {
      selectOption,
      selectedItem,
      assignSelectedItem,
      selectedResponse,
      optionInResponse,
      showResponse
    } = useQuizDragDrop()

    const getClassSentence = (sentence) => {
      let classItem = []
      if(selectedItem.value) classItem.push('cursor-pointer')
      classItem.push(selectedResponse(sentence) ? 'bg-green-primary' : 'bg-green-light')
      if(selectedResponse(sentence) && !showResponse.value) classItem.push('cursor-pointer')
      return classItem
    }

    const getClassOption = (option) => {
      let classOption = []
      if(optionInResponse(option)) {
        classOption.push('bg-gray-300')
      }else {
        classOption.push(option.isSelected ? 'bg-orange-primary' : 'bg-green-primary')
      }

      return classOption
    }

    const hasError = computed( () => {
      return props.item.sentences.some( s => s.error)
    })

    return {
      selectOption,
      getClassOption,
      selectedItem,
      assignSelectedItem,
      selectedResponse,
      getClassSentence,
      optionInResponse,
      showResponse,
      hasError,
      t
    }

  }
}
</script>